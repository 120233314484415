import {
  CSSProperties,
  memo
} from 'react';
import styles from './ContactSelectItem.module.scss'
import generalStyles from '@/styles/YcGeneral.module.scss'
import {
  Chat,
  GroupCategoriesMap
} from '@/store/chats/chats';
import { areEqual } from 'react-window';
import Avatar from '@/components/Avatar/Avatar';
import classNames from 'classnames';
import { getNameOrNick } from '@/utils/chats';

export interface ContactSelectData {
  contacts: Chat[],
  selected: Set<string>,
  onClick?: (jid: string) => void
}


interface ContactSelectItemProps {
  index: number,
  style: CSSProperties,
  data: ContactSelectData
}

const ContactSelectItem = memo(({ index, style, data }: ContactSelectItemProps) => {
  const { contacts, selected, onClick } = data
  const contact = contacts[index]

  const handleClick = () => {
    onClick?.(contact.$jid)
  }

  const contactSelected = selected.has(contact.$jid)

  return <div
    className={classNames(
      styles.contactSelect,
      contactSelected && styles.selected
    )}
    style={style}
    onClick={handleClick}
  >
    <Avatar src={contact.vcard?.thumbnail} name={getNameOrNick(contact)} />
    <div className={styles.context}>
      <p className={styles.name}>
        {contact.type === 'groupchat' && <i
          className={classNames('chat-group', styles.groupIcon)}
        />}
        {contact.type === 'chat' &&
          (contact.groups?.includes(GroupCategoriesMap.customer)
            || contact.groups?.includes(GroupCategoriesMap.consultant))
          && <span className={classNames(styles.teamIcon, generalStyles.teamIcon)}>L1</span>
        }
        {getNameOrNick(contact)}
      </p>
    </div>
    {contactSelected && <i className={classNames(styles.tick, 'chat-check')} />}
  </div>
}, areEqual)

export default ContactSelectItem
