import EventListener from '@/services/EventListener/EventListener';
import { ITabsInteractionRequest } from '@/interfaces/tabsInteraction';

export default abstract class TabsInteraction<T extends Record<keyof T, (...event: any[]) => any> = {}>
  extends EventListener<T> {
  constructor() {
    super()
    this._onMessage = this._onMessage.bind(this)
    window.addEventListener('message', this._onMessage)
  }

  protected abstract _onMessage(event: MessageEvent): void

  protected _sendEvent(message: ITabsInteractionRequest, event: MessageEvent) {
    try {
      event.source?.postMessage(message, {
        targetOrigin: event.origin
      })
    } catch (e) {
      return;
    }
  }

  destroy() {
    window.removeEventListener('message', this._onMessage)
  }
}
