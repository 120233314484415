import React, {MouseEventHandler} from 'react';
import classNames from 'classnames';

type ButtonView = 'main' | 'secondary' | 'link' | 'dark' | 'attention'

interface ButtonProps extends React.PropsWithChildren,
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  view?: ButtonView
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
}

const getViewClasses = (view: ButtonView) => {
  switch (view) {
    case 'main':
      return 'mainButton'
    case 'secondary':
      return 'secondaryButton'
    case 'dark':
      return 'darkButton'
    case 'attention':
      return 'attentionButton'
    case 'link':
      return 'linkButton'
  }
}

const Button = (
  {
    view = 'main',
    children,
    onClick,
    disabled,
    ...props
  }: ButtonProps) => {

  const onClickHandler: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (disabled) {
      return
    }
    onClick?.(e)
  }

  return <button
    {...props}
    className={classNames(
      'button',
      getViewClasses(view),
      disabled && 'disabled',
      props?.className
    )}
    onClick={onClickHandler}
  >{children}</button>
}

export default Button
