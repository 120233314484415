import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {useCallback, useEffect, useRef} from 'react';
import Presence from '@/services/Presence';
import {
  addMUChat,
  Affiliation,
  Chat,
  deleteChats,
  getActiveChatId,
  getMUChats,
  getPrivateChats,
  GroupCategoriesMap,
  isTypePeriod,
  removeFile,
  removeMembers,
  setMUChats,
  updateChat,
  updateMembers,
} from '@/store/chats/chats';
import {getUser} from '@/store/user/user';
import {AnyObject} from '@/index';
import JXON from 'jxon';
import Roster from '@/services/Roster';
import {usePrivateChatList} from '@/hooks/usePrivateChatList';
import {v4 as uuid} from 'uuid'
import useVCard from '@/hooks/useVCard';
import {getSender, SenderMessage} from '@/utils/messages';
import {
  clear as clearMessages,
  getAllMessages,
  isMessage,
  messagesWasFullLoaded,
  messagesWasLoaded,
  remove,
} from '@/store/messages/messages';
import useChatItemRendered from '@/hooks/useChatItemRendered';
import connection from '@/services/Connection/Connection';
import {ConnectionEventNames} from '@/services/Connection/IConnection';
import SetSettings from '@/services/SetSettings'
import useActiveService from '@/hooks/useActiveService'

interface ISubscribeMessage {
  $from: string,
  $to: string,
  $type?: 'subscribe' | 'unsubscribe' | 'subscribed',
}

interface HandleMuteProps {
  $from: string,
  muteMuc: 'true' | 'false',
  jid?: string
}

interface handleInviteMucProps {
  $from: string,
  inviteMuc: string
}

interface handleKickMucProps {
  $from: string,
  kickMuc: string
}

interface handleAdminMucProps {
  $from: string,
  adminMuc: string
}

interface handleUnAdminMucProps {
  $from: string,
  unAdminMuc: string
}

interface handlePublicMucProps {
  $from: string,
  publicMuc: string
}

interface handleMessageDeleteProps {
  messageDelete: string,
  jid: string
}

interface handlePinMessageProps {
  jid: string,
  uid: string,
  text: string,
  type: string,
  timestamp: number,
  action: string,
}

interface handleAutoDeleteMessagesProps {
  jid: string,
  periodType: string,
  period: string
}

interface MembersUpdated {
  $jid: string,
  $role: 'none' | 'visitor' | 'moderator'
  $affiliation: Affiliation
}

const getChatJid = (jid: string) => {
  const index = jid.lastIndexOf('/')
  if (index >= 0) {
    return jid.slice(0, index)
  } else {
    return jid
  }
}

const usePresence = ({watch = false} = {}) => {
  const user = useAppSelector(getUser)
  const currentJid = useAppSelector(getActiveChatId)
  const privateChats = useAppSelector(getPrivateChats)
  const rooms = useAppSelector(getMUChats)
  const messages = useAppSelector(getAllMessages)
  const presenceSent = useRef(false)
  const messagesRef = useRef(messages)
  const {addContact} = usePrivateChatList()
  const dispatch = useAppDispatch()
  const {getVCard, getVCardWithQueue} = useVCard()
  const {loadMessagesIfNotLoaded} = useChatItemRendered()
  const {setActiveChatId} = useActiveService()

  const addChat = useCallback((subscribe: ISubscribeMessage) => {
    const chat = privateChats[subscribe.$from]
    if (chat?.groups) {
      return
    }
    const msg = Roster.Messages.updateItem({
      from: user?.$jid || '',
      to: subscribe.$from,
      groups: [GroupCategoriesMap.pending],
    })
    connection.sendStrophe(msg)
  }, [user?.$jid, privateChats])

  const removeChat = useCallback((unsubscribe: ISubscribeMessage) => {
    const msg = Roster.Messages.removeItem({
      from: user?.$jid || '',
      to: unsubscribe.$from,
    })
    connection.sendStrophe(msg)
    if (currentJid === unsubscribe.$from) {
      setActiveChatId(null)
    }
  }, [user, currentJid, setActiveChatId])

  const addToAccepted = useCallback((subscribed: ISubscribeMessage) => {
    const chat = privateChats[subscribed.$from]
    if (!chat) {
      return
    }

    if (chat.groups?.includes(GroupCategoriesMap.pending)) {
      addContact(chat.$jid, chat.name || '', [GroupCategoriesMap.accepted])
    }
  }, [privateChats, addContact])

  const handleMuteUser = useCallback(({$from, muteMuc, jid}: HandleMuteProps) => {
    if (user?.$jid === jid) {
      dispatch(updateChat({chatJid: $from, options: {muted: muteMuc === 'true'}}))
    } else {
      const member = rooms[$from].members?.find(member => member.jid === jid)
      if (!member) {
        return
      }
      dispatch(updateMembers({
        chatJid: $from || '', members: [{
          ...member,
          jid: jid || '',
          muted: muteMuc === 'true',
        }],
      }))
    }
  }, [dispatch, rooms, user?.$jid])

  const presence = useCallback(() => {
    const msg = Presence.Messages.presence()
    connection.sendStrophe(msg)
  }, [])

  const enableCarbonMode = useCallback(() => {
    const msg =SetSettings.MESSAGES.enableCarbon()
    connection.sendStrophe(msg)
  }, [])

  const handleInviteMuc = useCallback(({$from, inviteMuc}: handleInviteMucProps) => {
    if (user?.$jid === inviteMuc) {
      const chat: Chat = {
        $jid: $from,
        $subscription: 'both',
        type: 'groupchat',
      }
      dispatch(addMUChat(chat))
      getVCardWithQueue({
        jid: $from,
        immediately: true
      })
      loadMessagesIfNotLoaded(chat)
    } else {
      dispatch(updateMembers({
        chatJid: $from || '', members: [{
          affiliation: 'member',
          jid: inviteMuc,
          muted: false,
        }],
      }))
    }
  }, [user?.$jid, dispatch, loadMessagesIfNotLoaded, getVCardWithQueue])

  const handleKickMuc = useCallback(({$from, kickMuc}: handleKickMucProps) => {
    if (user?.$jid === kickMuc) {
      dispatch(deleteChats($from))
      dispatch(clearMessages($from))
      dispatch(messagesWasLoaded({jid: $from, value: false}))
      dispatch(messagesWasFullLoaded({jid: $from, value: false}))
      return
    }
    dispatch(removeMembers({chatJid: $from || '', memberJids: [kickMuc]}))
  }, [dispatch, user?.$jid])

  const handleAdminMuc = useCallback(({$from, adminMuc}: handleAdminMucProps) => {
    dispatch(updateMembers({
      chatJid: $from || '', members: [{
        affiliation: 'admin',
        jid: adminMuc,
      }],
    }))
  }, [dispatch])

  const handleUnAdminMuc = useCallback(({$from, unAdminMuc}: handleUnAdminMucProps) => {
    dispatch(updateMembers({
      chatJid: $from || '', members: [{
        affiliation: 'member',
        jid: unAdminMuc,
      }],
    }))
  }, [dispatch])

  const handlePublicMuc = useCallback(({$from, publicMuc}: handlePublicMucProps) => {
    dispatch(updateChat({chatJid: $from, options: {groupType: publicMuc === 'true' ? 'public' : 'private'}}))
  }, [dispatch])

  const handleMessageDelete = useCallback(({messageDelete, jid}: handleMessageDeleteProps) => {
    const messageDeleteFind = messages[jid].messages.find(message => message.id === messageDelete)
    if (!!messageDeleteFind && isMessage(messageDeleteFind)) {
      dispatch(remove({jid: jid, message: messageDeleteFind}))
    } else {
      dispatch(removeFile({chatJid: jid, uid: messageDelete}))
    }
  }, [messages, dispatch])

  const handlePinMessage = useCallback(({jid, uid, text, type, timestamp, action}: handlePinMessageProps) => {
    if (action === '1') {
      dispatch(updateChat({
        chatJid: jid,
        options: {
          pin: {
            uid: uid,
            text: text,
            type: type,
            timestamp: timestamp,
            visible: true,
          },
        },
      }))
    } else {
      dispatch(updateChat({
        chatJid: jid,
        options: {
          pin: null,
        },
      }))
    }
  }, [dispatch])

  const handleAutoDeleteMessages = useCallback(({jid, periodType, period}: handleAutoDeleteMessagesProps) => {
    if (isTypePeriod(periodType)) {
      dispatch(updateChat({
        chatJid: jid || '',
        options: {
          autoDeleteMessages: {
            period: Number(period),
            typePeriod: periodType,
            mine: false,
            visible: true,
          },
        },
      }))
    }
  }, [dispatch])

  const notSubscribePresenceHandler = useCallback((msg: Element) => {
    const {presence}: AnyObject = JXON.stringToJs(msg.outerHTML)
    if (presence.x?.$xmlns === 'vcard-temp:x:update') {
      getVCardWithQueue({jid: presence.$from, timeout: 10000})
        .catch((e) => {
          console.error(e) // eslint-disable-line no-console
        })
    } else if (presence.vcard_update === 'true') {
      const {from: jid} = getSender(presence)
      getVCardWithQueue({jid, immediately: true})
        .catch((e) => {
          console.error(e) // eslint-disable-line no-console
        })
    } else if ('inviteMuc' in presence) {
      handleInviteMuc(presence)
    } else if ('kickMuc' in presence) {
      handleKickMuc(presence)
    } else if ('adminMuc' in presence) {
      handleAdminMuc(presence)
    } else if ('unAdminMuc' in presence) {
      handleUnAdminMuc(presence)
    } else if ('publicMuc' in presence) {
      handlePublicMuc(presence)
    } else if ('muteMuc' in presence) {
      handleMuteUser(presence)
    } else if ('messageDelete' in presence) {
      handleMessageDelete(presence)
    } else if ('pinMessage' in presence) {
      handlePinMessage(presence)
    } else if ('autoDeleteMessages' in presence) {
      handleAutoDeleteMessages(presence)
    } else if (presence.priority) {
      dispatch(updateChat({chatJid: presence.$from.split('/')[0], options: {lastActive: true}}))
    }
  }, [dispatch, getVCardWithQueue, handleMuteUser, handleInviteMuc, handleAutoDeleteMessages,
    handleKickMuc, handleAdminMuc, handleUnAdminMuc, handlePublicMuc, handleMessageDelete, handlePinMessage])

  const offlineUser = useCallback((presence: AnyObject) => {
    const chatJid = getChatJid(presence.$from)
    dispatch(updateChat({chatJid: chatJid, options: {lastActive: new Date()}}))
  }, [dispatch])

  const deleteMembers = useCallback((presence: AnyObject & SenderMessage) => {
    const chatJid = getChatJid(presence.$from)
    const members: MembersUpdated[] = Array.isArray(presence.x.item) ? presence.x.item : [presence.x.item]
    const deletedMembers = members
      .filter(member => member.$affiliation === 'none' && getChatJid(member.$jid) !== user?.$jid)
    const updatedMembers = members
      .filter(member => member.$affiliation !== 'none' && getChatJid(member.$jid) !== user?.$jid)
    dispatch(removeMembers({
      chatJid,
      memberJids: deletedMembers.map(member => getChatJid(member.$jid)),
    }))
    dispatch(updateMembers({
      chatJid,
      members: updatedMembers.map(member => ({
        jid: getChatJid(member.$jid),
        affiliation: member.$affiliation,
      })),
    }))
    if (members.find(member => getChatJid(member.$jid) === user?.$jid)) {
      dispatch(deleteChats([chatJid]))
      if (currentJid === chatJid) {
        setActiveChatId(null)
      }
    }
  }, [dispatch, user?.$jid, currentJid, setActiveChatId])

  const presenceHandler = useCallback((msg: Element) => {
    const {presence}: AnyObject = JXON.stringToJs(msg.outerHTML)
    console.log('presence message: ', presence) // eslint-disable-line no-console
    switch (presence.$type) {
      case 'subscribe':
        addChat(presence);
        break
      case 'subscribed':
        addToAccepted(presence)
        break;
      case 'unsubscribe':
        removeChat(presence)
        break
      case 'unavailable':
        if (presence.x?.item) {
          deleteMembers(presence)
        } else {
          offlineUser(presence)
        }
        break
      default:
        notSubscribePresenceHandler(msg)
        break
    }
    return true
  }, [
    addChat,
    removeChat,
    addToAccepted,
    notSubscribePresenceHandler,
    deleteMembers,
    offlineUser,
  ])

  const eventHandler = useCallback((msg: Element) => {
    try {
      const {message}: AnyObject = JXON.stringToJs(msg.outerHTML)
      if (message?.event?.items?.item?.message) {
        const event = message.event.items.item.message
        if (event.x?.status?.$code === '104' && event.$from) {
          getVCardWithQueue( {
            jid: event.$from,
            immediately: true
          })
        }
      }
    } catch (e) {
      console.error(e) // eslint-disable-line no-console
    }

    return true
  }, [getVCardWithQueue]);

  const chatCreatedHandler = useCallback((msg: Element) => {
    const msgObj: AnyObject = JXON.stringToJs(msg.outerHTML)
    const jid = msgObj.message?.x?.$jid
    if (!jid || rooms[jid]) {
      return true
    }
    dispatch(setMUChats({
      ...rooms,
      [jid]: {
        $jid: jid,
        $subscription: 'both',
        type: 'groupchat',
        thread: uuid(),
      },
    }))
    getVCard(jid)
    return true
  }, [dispatch, rooms, getVCard])

  useEffect(() => {
    if (!watch) {
      return
    }
    const refHandler = connection.addHandler(presenceHandler, '', 'presence')
    return () => {
      connection?.deleteHandler(refHandler)
    }
  }, [presenceHandler, watch])

  useEffect(() => {
    if (!watch) {
      return
    }
    const refHandler = connection.addHandler(chatCreatedHandler, 'jabber:x:conference', 'message')
    return () => {
      connection.deleteHandler(refHandler)
    }
  }, [chatCreatedHandler, watch])

  useEffect(() => {
    if (!watch || !connection) {
      return
    }
    const refHandler = connection.addHandler(eventHandler, 'http://jabber.org/protocol/pubsub#event', 'message')
    return () => {
      connection?.deleteHandler(refHandler)
    }
  }, [watch, eventHandler]);

  useEffect(() => {
    if (!watch) {
      return
    }
    const sentPresence = () => {
      if (presenceSent.current) {
        return
      }
      presenceSent.current = true
      enableCarbonMode()
      presence()
    }
    if (connection.isConnected) {
      sentPresence()
    }
    connection.addEventListener(ConnectionEventNames.Connected, sentPresence)
    return () => {
      connection.removeEventListener(ConnectionEventNames.Connected, sentPresence)
    }
  }, [presence, enableCarbonMode, watch]);

  useEffect(() => {
    if (!watch) {
      return
    }
    const clearPresence = () => {
      presenceSent.current = false
    }
    connection.addEventListener(ConnectionEventNames.Disconnected, clearPresence)

    return () => {
      connection.removeEventListener(ConnectionEventNames.Disconnected, clearPresence)
    }
  }, [watch]);

  useEffect(() => {
    messagesRef.current = messages
  }, [messages]);

  const subscribe = useCallback((jid: string) => {
    const msg = Presence.Messages.subscribe(jid)
    connection.sendStrophe(msg)
  }, [])

  const unsubscribe = useCallback((jid: string) => {
    const msg = Presence.Messages.unsubscribe(jid)
    connection.sendStrophe(msg)
  }, [])

  const subscribed = useCallback((jid: string) => {
    const msg = Presence.Messages.subscribed(jid)
    connection.sendStrophe(msg)
  }, [])

  const unsubscribed = useCallback((jid: string) => {
    const msg = Presence.Messages.unsubscribed(jid)
    connection.sendStrophe(msg)
  }, [])

  return {subscribe, subscribed, unsubscribe, unsubscribed}
}

export default usePresence
