import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import {
  IMessage,
  ISystemMessage
} from '@/store/messages/messages';
import { INews } from '@/store/news/news';
import useGetNames from '@/hooks/useGetNames';
import { IMessageComponent } from '../ChatMessage';
import { createFormatArr } from './utils/utils';
import { FormatText } from './ISystemMessage';
import styles from './SystemMessage.module.scss'
import { getTranslationByType } from '@/components/Chat/ChatMessage/SystemMessage/utils/translation';
import { formatTime } from '@/utils/dateutils';

export const isSystemMessage = (msg: IMessage | INews): msg is ISystemMessage => {
  return msg.type === 'system' && 'systemInfo' in msg
}

const SystemMessage = ({ msg }: IMessageComponent) => {
  const systemMsg = isSystemMessage(msg) ? msg : null
  const { formatMessage } = useIntl()

  const { nameByJid: fromJid } = useGetNames({ jid: systemMsg?.systemInfo.from || '' })
  const { nameByJid: toJid } = useGetNames({ jid: systemMsg?.systemInfo.to || '' })

  const textId = useMemo(() => {
    return getTranslationByType(systemMsg?.systemInfo.type)
  }, [systemMsg]);

  const text = useMemo<FormatText[]>(() => {
    if (!textId) {
      return []
    }
    const text = formatMessage({
      id: textId
    })
    switch (textId) {
      case 'MESSAGE.ENDED_GROUP_CALL':
        const time = formatTime(systemMsg?.systemInfo.duration || 0)
        return createFormatArr(text, fromJid, toJid, time)
      default:
        return createFormatArr(text, fromJid, toJid)
    }

  }, [textId, formatMessage, fromJid, toJid, systemMsg]);

  if (!text) {
    return null
  }

  return <div
    className={styles.box}
  >
    {text.map((str, index) => <span
      key={index}
      className={classNames(str.bold && styles.bold)}
    >{str.text}</span>)}
  </div>
}

export default SystemMessage
