import MarkdownIt, { Token } from 'markdown-it'
import TabsInteraction from '@/services/TabsInteraction/TabsInteraction';
import {
  AlogixImage,
  AlogixMessage,
  AlogixMessageEvent,
  ITabsInteractionRequest,
  TTabsInteraction
} from '@/interfaces/tabsInteraction';
import { IRequestItem } from './AlogixInteraction.d'
import { TabsInteractionType } from '@/constants/tabsInteraction';
import { isAlogixMessage } from '@/predicates/tabsInteraction';
import { formatPath } from '@/utils/path';
import { alogixDomains } from '@/constants/general';

const TIMEOUT_REQUEST = 500

interface AlogixFormatMessage {
  text?: string
  image?: AlogixImage
}

export class AlogixInteraction extends TabsInteraction {
  private _requestByName = new Map<TTabsInteraction, IRequestItem<any>>()
  private _markdown: MarkdownIt
  constructor() {
    super();
    this._markdown = new MarkdownIt()
  }

  private _formatImageToken(image: Token): string {
    const src = image.attrs?.find(attr => attr[0] === 'src')
    return src?.[1] || ''
  }

  private _formatTextToken(text: Token): string {
    return text.content
  }

  private _formatLinkToken(link: Token): string {
    const href = link.attrs?.find(attr => attr[0] === 'href')
    return href?.[1] || ''
  }

  private _formatListToken(list: Token): string {
    let result = ''
    const space = Math.max((list.level || 0) - 1, 0)
    for (let i = 0; i < space; i++) {
      result += ' '
    }
    if (list.markup === '.') {
      result += list.info + '. '
    } else {
      result += '- '
    }
    return result
  }

  private _clearMarkdown(text: string): string {
    const texts = this._markdown.parse(text, {})
    let result = ''
    texts.forEach((el, index) => {
      if (el.type === 'inline') {
        el.children?.forEach(child => {
          switch (child.type) {
            case 'text':
            case 'code_inline':
              result += this._formatTextToken(child)
              break
            case 'image':
              result += this._formatImageToken(child)
              break
            case 'link_open':
              result += this._formatLinkToken(child)
              break
          }
        })
      } if (el.type === 'list_item_open') {
        result += this._formatListToken(el)
      } if (el.type === 'td_close' || el.type === 'th_close') {
        result += ' | '
      } else if (el.type.endsWith('_close') &&
        (!texts[index-1]?.type.endsWith('_close') ||
          texts[index-1]?.type === 'td_close' ||
          texts[index-1]?.type === 'th_close')) {
        result += '\n'
      }
    })
    return result
  }

  private _filterImages(images: AlogixImage[]): AlogixImage[] {
    if (!images.length) {
      return []
    }
    const filter = [
      (image: AlogixImage) => /^jpe?g$/i.test(image.extension.trim()),
      (image: AlogixImage) => image.extension.trim().toLowerCase() === 'png',
      (image: AlogixImage) => image.extension.trim().toLowerCase() === 'webp',
      (image: AlogixImage) => image.extension.trim().toLowerCase() === images[0].extension.trim().toLowerCase()
    ]
    for (const element of filter) {
      const formatImages = images.filter(element)
      if (formatImages.length) {
        return formatImages
      }
    }
    return []
  }

  private _createFormatMessage(message: AlogixMessage): AlogixFormatMessage {
    const images = this._filterImages(message.images || [])
    const formatMessage: AlogixFormatMessage = {}
    if (message.text) {
      formatMessage.text = this._clearMarkdown(message.text)
    }
    if (images.length) {
      formatMessage.image = images.reduce(
        (max, image) => max.extension < image.extension ? image : max,
        images[0]
      )
    }
    return formatMessage
  }

  private _handleAlogixMessage(event: AlogixMessageEvent) {
    const request: IRequestItem<AlogixFormatMessage> | undefined =
      this._requestByName.get(TabsInteractionType.AlogixMessage)
    if (!request) {
      return
    }
    clearTimeout(request.timer)
    request.resolve(this._createFormatMessage(event.data))
    this._requestByName.delete(TabsInteractionType.AlogixMessage)
  }

  protected _onMessage(event: MessageEvent) {
    if (!alogixDomains.reduce(
      (acc, current) => acc || event.origin.startsWith(formatPath(current)),
      false
    )) {
      return
    }
    const data: ITabsInteractionRequest = event.data
    if (isAlogixMessage(data)) {
      this._handleAlogixMessage(data)
    }
  }

  async getMessage(): Promise<AlogixFormatMessage> {
    const source = window.opener
    if (!source) {
      throw new Error('alogix window not found')
    }
    const oldPromise = this._requestByName.get(TabsInteractionType.AlogixMessage)?.promise
    if (oldPromise) {
      return oldPromise
    }
    source.postMessage('loaded', '*')
    const self = this
    let request: Omit<IRequestItem<AlogixFormatMessage>, 'promise'>
    const promise = new Promise<AlogixFormatMessage>((resolve, reject) => {
      const timer = setTimeout(() => {
        reject('timeout')
        self._requestByName.delete(TabsInteractionType.AlogixMessage)
      }, TIMEOUT_REQUEST)
      request = {
        resolve,
        reject,
        timer
      }
    })
    this._requestByName.set(TabsInteractionType.AlogixMessage, {
      promise,
      ...request!
    })
    return promise
  }
}
