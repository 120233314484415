import {
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent
} from 'react';
import { limit } from '@/utils/math';
import {
  IPoint,
  ISize
} from '@/interfaces/shapes';

type UseResizeProp = {
  minHeight: number
  maxHeight: number
  minWidth: number
  maxWidth: number
  initWidth?: number
  initHeight?: number
  reset?: boolean
}
const useResize = (
  {
    minHeight,
    maxHeight,
    minWidth,
    maxWidth,
    initWidth,
    initHeight,
    reset
  }: UseResizeProp
) => {
  const [size, setSize] = useState({ width: initWidth || minWidth, height: initHeight ||minHeight })
  const [resizing, setResizing] = useState(false)
  const startCoords = useRef<IPoint & ISize>()

  const onResize = useCallback((e: MouseEvent | TouchEvent) => {
    const begin = startCoords.current
    if (!begin) {
      return
    }
    e.preventDefault()
    const x = 'pageX' in e ? e.pageX : e.changedTouches[0].pageX
    const y = 'pageY' in e ? e.pageY : e.changedTouches[0].pageY
    const width = limit({
      value: begin.width + x - begin.x,
      min: minWidth,
      max: maxWidth
    })
    const height = limit({
      value: begin.height + y - begin.y,
      min: minHeight,
      max: maxHeight
    })
    setSize({
      width,
      height
    })
  }, [maxWidth, minWidth, maxHeight, minHeight])


  useEffect(() => {
    if (reset) {
      setSize({ width: initWidth || minWidth, height: initHeight ||minHeight })
    }
  }, [initWidth, initHeight, minWidth, minHeight, reset]);

  useEffect(() => {
    setSize(prevState => {
      const width = limit({
        value: prevState.width,
        min: minWidth,
        max: maxWidth
      })
      const height = limit({
        value: prevState.height,
        min: minHeight,
        max: maxHeight
      })
      return {
        width,
        height
      }
    })
  }, [minHeight, maxHeight, minWidth, maxWidth]);

  const onResizeStart = useCallback((e: ReactMouseEvent | ReactTouchEvent) => {
    const x = 'pageX' in e ? e.pageX : e.changedTouches[0].pageX
    const y = 'pageY' in e ? e.pageY : e.changedTouches[0].pageY
    startCoords.current = {
      x,
      y,
      width: size.width,
      height: size.height
    }
    setResizing(true)
  }, [size])

  const onResizeStop = useCallback(() => {
    setResizing(false)
    startCoords.current = undefined
  }, [])

  useEffect(() => {
    if (!resizing) {
      return
    }
    window.addEventListener('mousemove', onResize)
    window.addEventListener('touchmove', onResize)
    window.addEventListener('mouseup', onResizeStop)
    window.addEventListener('touchend', onResizeStop)
    return () => {
      window.removeEventListener('mousemove', onResize)
      window.removeEventListener('touchmove', onResize)
      window.removeEventListener('mouseup', onResizeStop)
      window.removeEventListener('touchend', onResizeStop)
    }
  }, [onResize, onResizeStop, resizing]);

  return {
    resizing,
    size,
    onResizeStart
  }
}

export default useResize
