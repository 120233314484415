import styles from './Chip.module.scss'
import {IClass} from '@/interfaces/general';
import classNames from 'classnames';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';

interface ChipProps extends IClass {
  text: string
  tagName?: 'div' | 'li'
  closable?: boolean
  onClose?: () => void
}

const Chip = (
  {
    text,
    tagName: TagName = 'div',
    className,
    closable = true,
    onClose
  }: ChipProps) => {
  return <TagName
    className={classNames(
      styles.box,
      className,
      closable && styles.closable,
    )}
  >
    {text}
    {closable && <IconButton
      className={styles.btn}
      onClick={onClose}
    >
      <i className="chat-cross"/>
    </IconButton>}
  </TagName>
}

export default Chip
