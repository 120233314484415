import React, {
  ComponentProps,
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  useAppDispatch,
  useAppSelector
} from '@/hooks/appHook';
import { getUser } from '@/store/user/user';
import { v4 as uuid } from 'uuid'
import ChatList from '@/components/Chat/ChatList/ChatList';
import 'strophe.js';
import styles from './YouChat.module.scss'
import ChatMessages, { IChatMessages } from '@/components/Chat/ChatMessages/ChatMessages';
import InputBox, { sendFiles } from '@/components/InputBox/InputBox';
import usePresence from '@/hooks/usePresence';
import useMessage from '@/hooks/useMessage';
import PrivateChat from '@/services/PrivateChat';
import useVCard from '@/hooks/useVCard';
import useGetChats from '@/hooks/useGetChats';
import NavTabs from '@/components/NavTabs/NavTabs';
import {
  getCurrentTab,
  setTab,
  Tabs
} from '@/store/navTab/navTab';
import ContactList from '@/components/ContactList/ContactList';
import Services from '@/components/Services/List/Services';
import ServiceInfo from '@/components/Services/Details/ServiceInfo';
import Logo from '@/components/Logo/Logo';
import { ReactComponent as LogoShortImage } from '@/images/icons/logo-short.svg';
import {
  addMUChat,
  Chat,
  getActiveChat,
  getActiveChatId,
  getAllChats,
  GroupCategoriesMap,
  isMuChatsLoaded,
  isPrivateChatsLoaded,
} from '@/store/chats/chats';
import AddContactPanel from '@/components/AddContactPanel/AddContactPanel';
import ChatActionPanel from '@/components/ChatActionPanel/ChatActionPanel';
import classNames from 'classnames';
import {
  getScreenHeight,
  isMobile
} from '@/store/screenSize/screenSize';
import Header from '@/components/Header/Header';
import useChatItemRendered from '../../hooks/useChatItemRendered';
import UserNotify from '@/components/Modal/UserNotify/UserNotify';
import FileViewer from '@/components/Modal/FileViewer/FileViewer';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import usePageState from '@/hooks/usePageState';
import useAfterOffline from '@/hooks/useAfterOffline';
import { isInternetOnline } from '@/store/internetStatus/internetStatus';
import useOnline from '@/hooks/useOnline';
import ConnectPanel from '@/components/ConnectPanel/ConnectPanel';
import { getActiveService } from '@/store/services/services';
import ImageViewer from '@/components/Modal/ImageViewer/ImageViewer';
import { useDropzone } from 'react-dropzone';
import {
  FormattedMessage,
  MessageDescriptor,
  useIntl
} from 'react-intl';
import {
  getAllMessages,
  getMessages,
  isMessage,
  ShowedMessage
} from '@/store/messages/messages';
import { useMUChatList } from '@/hooks/useMUChatList';
import {
  CustomEvents,
  CustomWindow,
  ICallers,
  IShareFileEvent
} from '@/interfaces/general';
import {
  changeSearchParams,
  getQueryParams
} from '@/utils/url';
import { createPubSub } from '@inficen/react-events';
import useUtils from '@/hooks/useUtils';
import useModalSimple from '@/hooks/useModalSimple';
import useGroupType from '@/hooks/useGroupType';
import { checkMuc } from '@/api/chats';
import Confirm from '@/components/Modal/Confirm/Confirm';
import Profiler from '@/components/Modal/Profiler/Profiler';
import ShareFile from '@/components/Modal/ShareFile/ShareFile';
import useChatCache from '@/hooks/useChatCache';
import {
  contactListToArray,
  filterAcceptedChats,
  getChatId,
  getNameOrNick,
  isTeamChannel
} from '@/utils/chats';
import {
  FormatXMLElementFn,
  PrimitiveType
} from 'intl-messageformat';
import { Options as IntlMessageFormatOptions } from 'intl-messageformat/src/core';
import DevelopmentNotify, { show as showDevelopNotify } from '@/components/DevelopmentNotify/DevelopmentNotify';
import Pin from '@/components/Pin/Pin';
import { createStropheFromObject } from '@/services/utils';
import { queries } from '@/constants/general';
import ChatInfo from '@/components/Modal/ChatInfo/ChatInfo';
import { ITabModal } from '@/components/Modal/TabModal/TabModal';
import TeamList from '@/components/TeamList/TeamList';
import useCachedChat from '@/hooks/useCachedChat';
import useTeamChannelsNameObserver from '@/hooks/useTeamChannelsNameObserver';
import connection from '@/services/Connection/Connection';
import { ConnectionEventNames } from '@/services/Connection/IConnection';
import CallDialog, { RoomProps } from '@/components/Modal/CallDialog/CallDialog';
import useModal from '@/components/Modal/BaseModal/hooks/useModal';
import CallModal, {
  ICallModal,
  isCallModalProps
} from '@/components/Modal/CallModal/CallModal';
import useLastMessageCache from '@/hooks/useLastMessageCache';
import useJabberConnect from '@/hooks/useJabberConnect';
import useIncomingCallHandler from '@/hooks/useIncomingCallHandler';
import useCallSocketHandler from '@/hooks/useCallSocketHandler';
import {
  callToApp,
  isApplication
} from '@/utils/app'
import { isActiveNews } from '@/store/news/news'
import useActiveService from '@/hooks/useActiveService'
import { auth } from '@/utils/auth';
import Conference from '@/components/Conference/Conference';

type LogoutType = {
  name: 'authError'
}
const { publish, useSubscribe } = createPubSub<LogoutType>()
export const logoutEvent = () => {
  publish('authError')
}

interface YouChatProps extends React.PropsWithChildren {
  lang: any
}

interface GetBrowserTitleProps {
  flash?: boolean,
  newMessage?: boolean
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: IntlMessageFormatOptions) => string;
}

declare let window: CustomWindow

const WAIT_CHAT_TIMEOUT = 10000
const FLASH_TITLE_TIMEOUT = 1000
const CONNECT_DOMAIN = process.env.REACT_APP_CONNECT_DOMAIN

if (!CONNECT_DOMAIN) {
  console.error('Connect domain not defined') // eslint-disable-line no-console
}

const APP_NAME = 'YouChat'

const getBrowserTitle = ({ flash, newMessage, formatMessage }: GetBrowserTitleProps) => {
  if (!flash) {
    return APP_NAME
  } else if (newMessage) {
    return formatMessage({ id: 'new_msg_title_bar' })
  }

  return APP_NAME
}

const YouChat = ({ children, lang }: YouChatProps) => {
  const dispatch = useAppDispatch()
  const screenHeight = useAppSelector(getScreenHeight)
  const user = useAppSelector(getUser)
  const online = useAppSelector(isInternetOnline)
  const allChats = useAppSelector(getAllChats)
  const activeChatId = useAppSelector(getActiveChatId)
  const activeNews = useAppSelector(isActiveNews)
  const currentChat = useAppSelector(getActiveChat)
  const allMessages = useAppSelector(getAllMessages)
  const messages = useAppSelector(getMessages(activeChatId || ''))
  const activeService = useAppSelector(getActiveService)
  const mobileView = useAppSelector(isMobile)
  const activeTab = useAppSelector(getCurrentTab)
  const privateChatsLoaded = useAppSelector(isPrivateChatsLoaded)
  const muChatsLoaded = useAppSelector(isMuChatsLoaded)
  const [connectionStatus, setConnectionStatus] = useState(connection.Status)
  const [selectedTab, setSelectedTab] = useState(Tabs.Chats)
  const [chatFilter, setChatFilter] = useState('')
  const [hasNewMessage, setHasNewMessage] = useState(false)
  const [shareFile, setShareFile] = useState<File>()
  const [confirmProps, setConfirmProps] = useState<ComponentProps<typeof Confirm>>({})
  const [shareFileLoading, setShareFileLoading] = useState(false)
  const [conferenceProps, setConferenceProps] = useState<ComponentProps<typeof Conference>>({})
  const [minimizeConference, setMinimizeConference] = useState(false)
  const flashBrowserTimer = useRef<NodeJS.Timer>()
  const currentChatRef = useRef<Chat | null>(null)
  const currentLogoutRef = useRef<Function>()
  const prevTab = useRef<Tabs>()
  const showProfilerRef = useRef<Function>()
  const hideProfilerRef = useRef<Function>()
  const waitChatTimer = useRef<NodeJS.Timer>()
  const openChatFromJidUrl = useRef(false)
  const chatInfoRef = useRef<ITabModal>(null)
  const callModalRef = useRef<ICallModal>(null)
  const conferenceStarting = useRef(false);
  const { langParam } = useParams()
  const navigate = useNavigate()
  const { logout, authenticate } = useUtils()
  const { cachedChat } = useCachedChat({ jid: currentChat?.$jid || '' })
  const { formatMessage } = useIntl()
  const replyClickRef = useRef<IChatMessages>(null)
  const { setActiveChatId } = useActiveService()
  useTeamChannelsNameObserver()
  useJabberConnect()

  const {
    show: showConfirmationDialog,
    hide: hideConfirmationDialog,
    visible: visibleConfirmationDialog,
  } = useModalSimple()

  const {
    show: showConference,
    hide: hideConference,
    visible: conferenceVisible
  } = useModalSimple({
    onHide: useCallback(
      () => {
        setMinimizeConference(false)
      },
      []
    ),
  })

  const {
    show: showCallDialog,
    visible: visibleCallDialog,
    props: callDialogProps,
  } = useModal<ComponentProps<typeof CallDialog>>({
    props: {}
  })

  const {
    visible: visibleCallModal,
    show: showCallModal,
    props: callModalProps,
  } = useModal<Partial<ComponentProps<typeof CallModal>>>({
    props: {},
  })

  const openCallInApplication = useCallback((callers: ICallers) => {
    callToApp(callers)
  }, [])

  const onConferenceStart = useCallback(async() => {
    if (conferenceStarting.current || !user || !activeChatId) {
      return
    }
    const uid = getChatId(activeChatId)
    conferenceStarting.current = true
    try {
      const { token, memberId } = await authenticate({
        memberId: user.memberId,
        accessToken: user.accessToken
      })
      const url = new URL(`/join`, CONNECT_DOMAIN)
      const params = url.searchParams
      params.append('uid', uid)
      params.append('token', token)
      params.append('userUid', memberId)
      params.append('domain', 'youchat')
      params.append('userName', user.nickname || '')
      setConferenceProps({
        src: url.toString()
      })
      showConference()
    } finally {
      conferenceStarting.current = false
    }

  }, [showConference, activeChatId, user, authenticate])

  const openCallDialog = useCallback(({ chat, incoming = false, token }: {
    chat?: Chat,
    incoming?: boolean,
    token?: string,
  } = {}) => {
    const isApp = isApplication()
    if (isApp && incoming) {
      return
    } else if (isApp) {
      const caller = chat || currentChat
      if (!caller) {
        return;
      }
      openCallInApplication({
        from: user?.$jid || '',
        to: caller.$jid,
        nick: getNameOrNick(caller) || getChatId(caller.$jid),
        url: caller.vcard?.thumbnail
      })
      return
    }
    showCallDialog({
      jid: chat?.$jid || currentChat?.$jid,
      incoming,
      token
    })
  }, [showCallDialog, currentChat, openCallInApplication, user?.$jid])

  useIncomingCallHandler({
    isCallNow: visibleCallModal,
    openCallDialog
  })
  useCallSocketHandler()

  const handleHideConfirm = () => {
    hideConfirmationDialog()
    setConfirmProps({})
  }

  useEffect(() => {
    if (!user || user.token) {
      return
    }
    auth()
  }, [user]);

  useEffect(() => {
    const onStatusChanged = (status: Strophe.Status) => {
      setConnectionStatus(status)
    }
    connection.addEventListener(ConnectionEventNames.StatusChanged, onStatusChanged)

    return () => {
      connection.removeEventListener(ConnectionEventNames.StatusChanged, onStatusChanged)
    }
  }, []);

  useEffect(() => {
    if (!muChatsLoaded) {
      return
    }
    const params = new URLSearchParams(window.location.search)
    const ref = params.get(queries.ref)
    const jid = params.get(queries.join)
    params.delete(queries.ref)
    params.delete(queries.join)
    changeSearchParams(params)
    if (ref && jid) {
      checkMuc(jid || '').then(({ name }) => {
          const chat: Chat = {
            $jid: `${jid}@conference.${process.env.REACT_APP_EJ_HOST}`,
            type: 'groupchat',
            name,
            ask: ref,
            $subscription: 'none',
            members: [],
          }
          dispatch(addMUChat(chat))
          setActiveChatId(chat.$jid)
        },
      ).catch(e => {
        setConfirmProps({
          title: formatMessage({ id: 'you_cannot_join_this_group' }),
          description: formatMessage({ id: 'this_group_is_private_so_only' }),
          onlyOneButton: true,
          confirmTitle: formatMessage({ id: 'CONFIRM.OK' }),
        })
        showConfirmationDialog()
      })
    }
  }, [muChatsLoaded, showConfirmationDialog, dispatch, formatMessage, setActiveChatId])

  useOnline()
  useMUChatList({ watch: true })
  useGetChats()
  usePresence({ watch: true })
  useMessage({ watch: true })
  useVCard({ watch: true })
  useChatItemRendered({ watch: true })
  usePageState()
  useAfterOffline()
  useGroupType()
  useChatCache({ watch: true })
  useLastMessageCache({ watch: true })

  const onCloseProfiler = useCallback(() => {
    if (prevTab.current === Tabs.Profile) {
      return
    }
    const tab = prevTab.current || Tabs.Chats
    dispatch(setTab(tab))
  }, [dispatch])

  const { show: showProfiler, hide: hideProfiler, visible: visibleProfiler } = useModalSimple({
    onHide: onCloseProfiler,
  })

  showProfilerRef.current = showProfiler
  hideProfilerRef.current = hideProfiler

  useEffect(() => {
    const tab = activeTab
    if (!prevTab.current) {
      prevTab.current = tab
    }
    if (tab === Tabs.Profile) {
      showProfiler()
    } else if (prevTab.current === Tabs.Profile) {
      hideProfiler()
    }
    return () => {
      prevTab.current = tab
    }
  }, [activeTab, showProfiler, hideProfiler])

  const { show: showShareFile, hide: hideShareFile, visible: visibleShareFile } = useModalSimple()

  useEffect(() => {
    let flash = hasNewMessage
    const updateTitle = () => {
      flash = !flash
      document.title = getBrowserTitle({
        formatMessage,
        flash,
        newMessage: hasNewMessage,
      })
    }
    if (flash && !flashBrowserTimer.current) {
      updateTitle()
      flashBrowserTimer.current = setInterval(updateTitle, FLASH_TITLE_TIMEOUT)
      return
    }
    clearInterval(flashBrowserTimer.current)
    flashBrowserTimer.current = undefined
    updateTitle()
  }, [hasNewMessage, formatMessage])

  useEffect(() => {
    return () => {
      clearInterval(flashBrowserTimer.current)
      flashBrowserTimer.current = undefined
      document.title = getBrowserTitle({
        formatMessage,
        flash: false,
        newMessage: false,
      })
    }
  }, [formatMessage])

  useEffect(() => {
    const acceptedChats = contactListToArray(allChats).filter(filterAcceptedChats)
    const amount = acceptedChats.reduce((sum, chat) => {
      const chatMessages: ShowedMessage[] = allMessages[chat.$jid]?.messages
      const message = chatMessages?.find(message =>
        isMessage(message) && message.status !== 'displayed' && message.from !== user?.$jid)
      return sum + (!!message ? 1 : 0)
    }, 0)
    setHasNewMessage(amount > 0)
  }, [allChats, allMessages, user?.$jid])

  useEffect(() => {
    currentChatRef.current = currentChat
  }, [currentChat])

  const onDropFiles = useCallback((acceptedFiles: File[]) => {
    if (!currentChatRef.current) {
      return
    }
    sendFiles(acceptedFiles, currentChatRef.current)
  }, [])

  useEffect(() => {
    currentLogoutRef.current = logout
  }, [logout])

  useSubscribe('authError', () => {
    currentLogoutRef.current?.()
  })

  const openChat = useCallback(() => {
    const params = new URLSearchParams(window.location.search)
    const jid = params.get(queries.jid)
    if (!jid) {
      return;
    }

    const clearParams = () => {
      clearTimeout(waitChatTimer.current)
      waitChatTimer.current = undefined
      const params = new URLSearchParams(window.location.search)
      params.delete(queries.jid)
      changeSearchParams(params)
    }

    if (!allChats[jid]) {
      if (!waitChatTimer.current) {
        waitChatTimer.current = setTimeout(clearParams, WAIT_CHAT_TIMEOUT)
      }
      return
    }
    clearParams()
    setActiveChatId(jid)
  }, [allChats, setActiveChatId])

  useEffect(() => {
    if (!waitChatTimer.current) {
      return
    }
    openChat()
  }, [openChat])

  useEffect(() => {
    if (!openChatFromJidUrl.current) {
      openChat()
    }
    openChatFromJidUrl.current = true
  }, [openChat, openChatFromJidUrl])

  useEffect(() => {
    window.history.onpushstate = openChat
  }, [openChat])

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop: onDropFiles,
    maxFiles: 1,
    disabled: !activeChatId,
    noClick: true,
  })

  useEffect(() => {
    if (![Tabs.Profile].includes(activeTab)) {
      setSelectedTab(activeTab)
    }
  }, [activeTab])

  useEffect(() => {
    if (shareFile) {
      showShareFile()
    } else {
      hideShareFile()
    }
  }, [shareFile, showShareFile, hideShareFile])

  useEffect(() => {
    const shareFile = async(ev: CustomEvent<IShareFileEvent>) => {
      try {
        setShareFileLoading(true)
        const { path, name, file } = ev.detail
        if (path != null) {
          const response = await fetch(path)
          if (response.ok) {
            const blob = await response.blob()
            const file = new File([blob], name, {
              type: blob.type
            })
            setShareFile(file)
          }
        } else {
          setShareFile(file)
        }
      } finally {
        setShareFileLoading(false)
      }
    }
    window.addEventListener(CustomEvents.SHARE_FILE, shareFile)

    return () => {
      window.removeEventListener(CustomEvents.SHARE_FILE, shareFile)
    }
  }, [])

  const clearShareFile = () => {
    setShareFile(undefined)
  }

  useEffect(() => {
    if (!langParam) {
      navigate('/' + lang + getQueryParams())
    }
  }, [navigate, langParam, lang])

  const onReadyCall = ({ name, token }: RoomProps) => {
    showCallModal({
      token,
      name,
      invisible: true
    })
  }

  const onCancelCall = useCallback(() => {
    callModalRef.current?.close()
  }, [])

  const onStartCall = ({ name, token }: RoomProps) => {
    if (!(name && token)) {
      return
    }
    showCallModal({
      invisible: false
    })
  }

  const conferenceStyle = useMemo<CSSProperties>(() => {
    return {
      top: minimizeConference ? undefined : 0,
      left: minimizeConference ? undefined : 0,
      right: minimizeConference ? 20 : undefined,
      bottom: minimizeConference ? 20 : undefined
    }
  }, [minimizeConference]);

  // develop functions

  const getMUCService = () => {
    const msg = $iq({
      from: user?.$jid || '',
      to: process.env.REACT_APP_EJ_HOST || '',
      id: uuid(),
      type: 'get',
    })
      .c('query', {
        xmlns: 'http://jabber.org/protocol/disco#items',
      }).tree()
    console.log(msg)  // eslint-disable-line no-console
    connection.sendStrophe(msg)
  }

  const getRooms = () => {
    const msg = $iq({
      from: user?.$jid || '',
      to: 'conference.' + process.env.REACT_APP_EJ_HOST || '',
      id: uuid(),
      type: 'get',
    })
      .c('query', {
        xmlns: 'http://jabber.org/protocol/disco#items',
      }).tree()
    console.log(msg)  // eslint-disable-line no-console
    connection.sendStrophe(msg)
  }

  const getVcard = () => {
    const msg = $iq({
      from: user?.$jid || '',
      to: '15521@' + process.env.REACT_APP_EJ_HOST,
      id: uuid(),
      type: 'get',
    })
      .c('vCard', {
        xmlns: 'vcard-temp',
      }).tree()

    console.log('send: ', msg) // eslint-disable-line no-console
    connection.send(msg)
  }

  const getRoster = () => {
    const msg = PrivateChat.Messages.getList(user?.$jid || '')
    connection.sendStrophe(msg)
  }

  const info = () => {
    const msg = $iq({
      from: user?.$jid || '',
      to: '' + process.env.REACT_APP_EJ_HOST,
      type: 'get',
      id: uuid(),
    })
      .c('query', {
        xmlns: 'http://jabber.org/protocol/disco#info',
      }).tree()
    console.log(msg)  // eslint-disable-line no-console
    connection.sendStrophe(msg)
  }

  const conferenceInfo = () => {
    const msg = $iq({
      from: user?.$jid || '',
      to: 'conference.' + process.env.REACT_APP_EJ_HOST,
      type: 'get',
      id: uuid(),
    })
      .c('query', {
        xmlns: 'http://jabber.org/protocol/disco#info',
      }).tree()
    console.log(msg)  // eslint-disable-line no-console
    connection.sendStrophe(msg)
  }

  const showDeveloperNotify = () => {
    showDevelopNotify({ message: 'develop notify' })
  }

  const getSubscribedRooms = () => {
    const msg = createStropheFromObject('iq', {
      $from: user?.$jid,
      $to: 'conference.' + process.env.REACT_APP_EJ_HOST,
      $type: 'get',
      $id: uuid(),
      subscriptions: {
        $xmlns: 'urn:xmpp:mucsub:0',
      },
    }).tree()
    console.log(msg) // eslint-disable-line no-console
    connection.sendStrophe(msg)
  }

  const getSubscribers = () => {
    const msg = createStropheFromObject('iq', {
      $from: user?.$jid,
      $to: activeChatId,
      $type: 'get',
      $id: uuid(),
      subscriptions: {
        $xmlns: 'urn:xmpp:mucsub:0',
      },
    }).tree()
    console.log(msg) // eslint-disable-line no-console
    connection.sendStrophe(msg)
  }

  const showMobileLogoLoading = mobileView && (!(privateChatsLoaded && muChatsLoaded) || shareFileLoading)
  const showDesktopLogoLoading = !(privateChatsLoaded && muChatsLoaded) || shareFileLoading

  return (
    <div className={styles.youchat}>
      <div
        className={classNames(styles.main, mobileView && styles.mobile)}
        style={{ height: screenHeight - (mobileView ? 0 : 32) || '100vh' }}
      >
        {(!mobileView || (!activeChatId && !activeService && !activeNews)) && <div className={styles.chatPanel}>
          <Logo className={styles.logo} />
          <div className={classNames(styles.chatList, styles.chatListBorder)}>
            {showMobileLogoLoading && <div className={styles.spinner}>
              <LogoShortImage />
            </div>}
            <ChatActionPanel onChange={setChatFilter} />
            {chatFilter
              ? <ChatList className={styles.chatList} filter={chatFilter} />
              : <>
                {selectedTab === Tabs.Chats && <ChatList className={styles.chatList} />}
                {selectedTab === Tabs.Contacts &&
                  <ContactList className={styles.chatList} />}
                {selectedTab === Tabs.Team && <TeamList className={styles.chatList} />}
                {selectedTab === Tabs.Service && <Services />}
              </>
            }
          </div>
          {mobileView
            && (!online || connectionStatus !== Strophe.Status.CONNECTED)
            && <ConnectPanel />
          }
          <NavTabs className={styles.navTabs} />
        </div>}
        {(!mobileView || activeChatId || activeService || activeNews) && <div
          className={styles.messagesPanel}
          {...getRootProps()}
        >
          <ChatInfo ref={chatInfoRef} className={styles.tabModal} />
          {showDesktopLogoLoading && <div className={styles.spinner}>
            <LogoShortImage />
          </div>}
          {(!!activeChatId || activeNews) && <>
            <input {...getInputProps()} />
            {isDragActive && <div className={classNames(styles.dropZone, isDragReject && styles.warning)}>
              <FormattedMessage id={'dropFilesHere'}>
                {txt => <p className={styles.dropText}>{txt}</p>}
              </FormattedMessage>
              <span className={styles.dropImage}><i className={'chat-download'} /></span>
            </div>}
            <Header
              className={styles.header}
              canCall={!visibleCallModal && !conferenceVisible}
              onShowChatInfo={() => chatInfoRef.current?.show()}
              onCallDialogShow={openCallDialog}
              onConferenceStart={onConferenceStart}
            />
            <div className={styles.messages}>
              <div className={styles.wrapper}>
                <Pin onClickReply={replyClickRef.current?.replyClick} />
                <ChatMessages ref={replyClickRef} />
              </div>
              {((currentChat?.$subscription !== 'both' && currentChat?.type === 'chat')
                  || (currentChat?.type === 'groupchat' && currentChat?.$subscription === 'none'))
                && <AddContactPanel />}
              {(currentChat?.$subscription === 'both' ||
                  (currentChat?.groups?.includes(GroupCategoriesMap.pending)
                    && !!currentChat.ask
                    && messages?.messages?.length === 0) ||
                  (currentChat?.type === 'groupchat' && currentChat?.$subscription !== 'none'))
                && connectionStatus === Strophe.Status.CONNECTED
                && online
                && !(isTeamChannel(currentChat)
                  && !cachedChat.members?.find(member => member.affiliation === 'owner'))
                && <InputBox className={styles.inputBox} />}
              {(connectionStatus !== Strophe.Status.CONNECTED || !online)
                && <ConnectPanel className={styles.connectPanel} />
              }
              {!connection}
            </div>
          </>}
          {!!activeService && <ServiceInfo />}
        </div>}
      </div>
      <UserNotify />
      <FileViewer />
      <ImageViewer />
      {conferenceVisible && <Conference
        {...conferenceProps}
        className={styles.conferenceFrame}
        style={conferenceStyle}
        hide={hideConference}
        onMinimize={setMinimizeConference}
      />}
      {visibleConfirmationDialog && <Confirm hide={handleHideConfirm} {...confirmProps} />}
      {visibleProfiler && <Profiler hide={hideProfiler} />}
      {visibleShareFile && <ShareFile file={shareFile} hide={clearShareFile} />}
      {visibleCallDialog && <CallDialog
        {...callDialogProps}
        onCall={onStartCall}
        onRoomReady={onReadyCall}
        onCancel={onCancelCall}
      />}
      {visibleCallModal && isCallModalProps(callModalProps) &&
        <CallModal
          {...callModalProps}
          ref={callModalRef}
        />}

      {process.env.NODE_ENV === 'development' && <>
        <DevelopmentNotify />
        <div style={{ display: 'none' }}>
          <button onClick={getMUCService}>Get MUC Service</button>
          <button onClick={getRooms}>Get Rooms</button>
          <button onClick={getVcard}>get vcard</button>
          <button onClick={getRoster}>get roster</button>
          <button onClick={info}>info</button>
          <button onClick={conferenceInfo}>conference info</button>
          <button onClick={showDeveloperNotify}>show develop notify</button>
          <button onClick={getSubscribedRooms}>subcribed rooms</button>
          <button onClick={getSubscribers}>get subscriptions</button>
        </div>
      </>}
    </div>
  )
}

export default YouChat;
