import {
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { IPoint } from '@/interfaces/shapes';

const INIT_COORDS = {x: 0, y: 0}

interface UseMovingProps {
  disabled?: boolean
  initCoords?: IPoint
  onStart?: () => void
  limitCoords?: (offset: IPoint) => IPoint
  reset?: boolean
}

const useMoving = (
  {
    onStart,
    disabled,
    limitCoords,
    initCoords = INIT_COORDS,
    reset
  }: UseMovingProps = {}
) => {
  const [startMoving, setStartMoving] = useState(false)
  const [offset, setOffset] = useState<IPoint>(initCoords)
  const startMovingCoords = useRef<IPoint | undefined>();
  const startOffset = useRef(offset)
  const onStartRef = useRef<typeof onStart>();
  const limitCoordsRef = useRef<typeof limitCoords>()
  const offsetRef = useRef<typeof offset>(offset)
  onStartRef.current = onStart
  limitCoordsRef.current = limitCoords
  offsetRef.current = offset

  useEffect(() => {
    if (reset) {
      setOffset(initCoords)
    }
  }, [initCoords, reset]);

  const onStartMoving = useCallback((e: ReactTouchEvent | ReactMouseEvent | MouseEvent | TouchEvent) => {
    if (disabled) {
      return
    }
    e.preventDefault()
    const event = e instanceof Event ? e : e.nativeEvent
    startMovingCoords.current = {
      x: event instanceof MouseEvent ? event.pageX : event.targetTouches[0].pageX,
      y: event instanceof MouseEvent ? event.pageY : event.targetTouches[0].pageY
    }
    startOffset.current = offset
    setStartMoving(true)
    onStartRef.current?.()
  }, [disabled, offset]);

  const changeOffsetCoords = useCallback((event: MouseEvent | TouchEvent) => {
    if (startMovingCoords.current == null) {
      return
    }
    const nativeCoords = event instanceof MouseEvent ? event : event.targetTouches[0]
    const newCoords: IPoint = {
      x: startOffset.current.x + nativeCoords.pageX - startMovingCoords.current.x,
      y: startOffset.current.y + nativeCoords.pageY - startMovingCoords.current.y
    }
    setOffset(limitCoordsRef.current?.(newCoords) || newCoords)
  }, [])

  const recalculate = useCallback((limit?: typeof limitCoords) => {
    if (limit) {
      setOffset(limit(offset))
      return;
    }
    if (!limitCoordsRef.current) {
      return
    }
    setOffset(limitCoordsRef.current(offset))
  }, [offset])

  useEffect(() => {
    if (!startMoving) {
      return
    }
    document.addEventListener('mousemove', changeOffsetCoords)
    document.addEventListener('touchmove', changeOffsetCoords)
    return () => {
      document.removeEventListener('mousemove', changeOffsetCoords)
      document.removeEventListener('touchmove', changeOffsetCoords)
    }
  }, [startMoving, changeOffsetCoords]);

  useEffect(() => {
    if (!startMoving) {
      return
    }
    const stopMoving = () => {
      setStartMoving(false)
    }
    document.addEventListener('mouseleave', stopMoving)
    document.addEventListener('mouseup', stopMoving)
    document.addEventListener('touchend',stopMoving)
    return () => {
      document.removeEventListener('mouseleave', stopMoving)
      document.removeEventListener('mouseup', stopMoving)
      document.removeEventListener('touchend',stopMoving)
    }
  }, [startMoving]);

  return {
    moving: startMoving,
    offset,
    onStartMoving,
    recalculate
  }
}

export default useMoving
