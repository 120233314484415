import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'
import { RootState } from "@/store";
import dayjs from "dayjs";
import { sendLanguageToApp } from '@/utils/app';
import { localStorageFields } from '@/constants/localStorage';

const FIELD_NAME = localStorageFields.language

export enum langTypes {
  en, cn, tc, kr, jp
}

export const keyValLang = {
  en: 'en',
  jp: 'ja',
  kr: 'ko',
  cn: 'zh-cn',
  tc: 'zh-cn'
};

export type TypeLang = keyof typeof langTypes

export interface ILanguage {
  lang: TypeLang
}

let initLang: TypeLang
const langUrl = window.location.pathname.trim().split("/")[1]

if ((localStorage.getItem(FIELD_NAME)) as TypeLang) {
  initLang = localStorage.getItem(FIELD_NAME) as TypeLang
} else if (langUrl in langTypes) {
  initLang = langUrl as TypeLang
  localStorage.setItem(FIELD_NAME, initLang)
} else {
  initLang = 'en'
}
dayjs.locale(keyValLang[initLang])
sendLanguageToApp(initLang)

const initialState: ILanguage = {
  lang: initLang
}

const language = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLang: (state, { payload }: PayloadAction<TypeLang>) => {
      state.lang = payload
      sendLanguageToApp(payload)
      localStorage.setItem(FIELD_NAME, payload)
      dayjs.locale(keyValLang[state.lang])
    }
  }
})

export const { setLang } = language.actions
export const getLang = (state: RootState) => state.language.lang

export default language.reducer
