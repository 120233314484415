import {
  AliasRequest,
  AliasResponse,
  AlogixMessageEvent,
  GroupDataRequest,
  GroupDataResponse,
  InteractionError,
  ITabsInteractionRequest,
  LanguageRequest,
  LanguageResponse,
  PrePopupClosedEvent,
  RoomClosedRequest,
  RoomOverflowedEvent
} from '@/interfaces/tabsInteraction';
import { TabsInteractionType } from '@/constants/tabsInteraction';

export const isRoomClosedRequest = (event: ITabsInteractionRequest): event is RoomClosedRequest => {
  return event.type === TabsInteractionType.RoomClosed
}

export const isAliasRequest = (event: ITabsInteractionRequest): event is AliasRequest => {
  return event.type === TabsInteractionType.AliasRequest
}

export const isAliasResponse = (event: ITabsInteractionRequest): event is AliasResponse => {
  return event.type === TabsInteractionType.AliasResponse
}

export const isPrePopupClosedEvent = (event: ITabsInteractionRequest): event is PrePopupClosedEvent => {
  return event.type === TabsInteractionType.PrePopupClosed
}

export const isRoomOverflowedEvent = (event: ITabsInteractionRequest): event is RoomOverflowedEvent => {
  return event.type === TabsInteractionType.RoomOverflowed
}

export const isGroupDataRequest = (event: ITabsInteractionRequest): event is GroupDataRequest => {
  return event.type === TabsInteractionType.GroupDataRequest
}

export const isGroupDataResponse = (event: ITabsInteractionRequest): event is GroupDataResponse => {
  return event.type === TabsInteractionType.GroupDataResponse
}

export const isLanguageRequest = (event: ITabsInteractionRequest): event is LanguageRequest => {
  return event.type === TabsInteractionType.LanguageRequest
}

export const isLanguageResponse = (event: ITabsInteractionRequest): event is LanguageResponse => {
  return event.type === TabsInteractionType.LanguageResponse
}

export const isInteractionError = (event: ITabsInteractionRequest): event is InteractionError => {
  return event.type === TabsInteractionType.Error
}

export const isAlogixMessage = (event: ITabsInteractionRequest): event is AlogixMessageEvent => {
  return event.type === TabsInteractionType.AlogixMessage
}
