import { SocketEventMap, SocketEvents } from './ISocket';
import EventListener from '@/services/EventListener/EventListener'

class Socket extends EventListener<SocketEventMap>{
  private _isOpened = false
  protected _socket: WebSocket | null = null
  private _closingPromise?: Promise<void>

  constructor(protected url: string) {
    super()
  }

  get isOpened() {
    return this._isOpened
  }

  private onStateChanged() {
    this.emit(SocketEvents.onStateChanged, this._isOpened)
  }

  protected onClose(event: CloseEvent) {
    this._socket = null
    this._isOpened = false
    this.onStateChanged()
    this.emit(SocketEvents.onClose, event)
  }

  protected onMessage(event: MessageEvent) {
    this.emit(SocketEvents.onMessage, event.data)
  }

  connect(): Promise<void> {
    if (this._socket) {
      return Promise.reject('Socket is already connected')
    }
    const socket = new WebSocket(this.url)
    this._socket = socket
    const self = this
    return new Promise((resolve, reject) => {
      socket.addEventListener('open', () => {
        self._isOpened = true
        self.onStateChanged()
        socket.addEventListener('close', self.onClose.bind(self), {once: true})
        socket.addEventListener('message', self.onMessage.bind(self))
        resolve()
        self.emit(SocketEvents.onOpen)
      }, {
        once: true
      })
      socket.onerror = () => {
        self._isOpened = false
        self.onStateChanged()
        self._socket = null
        reject('It\'s impossible to connect to the socket')
      }
    })
  }

  close(): Promise<void> {
    if (this._closingPromise) {
      return this._closingPromise
    }
    const socket = this._socket
    if (!socket) {
      return Promise.resolve()
    }
    this._closingPromise = new Promise(resolve => {
      socket.addEventListener('close', () => {
        resolve()
      }, {once: true})
      socket.close(1000)
    })
    this._closingPromise.finally(() => {
      this._closingPromise = undefined
    })
    return this._closingPromise
  }

  send(data: string | ArrayBufferLike | Blob | ArrayBufferView) {
    this._socket?.send(data)
    return this.isOpened
  }
}

export default Socket
