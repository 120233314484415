import { configureStore } from '@reduxjs/toolkit'
import user from './user/user'
import chats from './chats/chats';
import messages from "./messages/messages";
import screenSize from './screenSize/screenSize';
import navTabs from './navTab/navTab';
import blockList from './blockList/blockList';
import language from './language/language';
import pageState from './pageState/pageState';
import internetStatus from './internetStatus/internetStatus';
import services from "./services/services";
import stickers from './stickers/stickers';
import scroll from './scroll/scroll'
import draft from './draft/draft'
import chatsCache from './cache/chats'
import lastMessageCache from './cache/lastMessage'
import settings from './settings/settings'
import news from './news/news'
import supportTickets from './supportTickets/supportTickets'
import hints from './hints/hints'
import popupPositions from './popupPositions/popupPositions';

const store = configureStore({
  reducer: {
    user,
    chats,
    messages,
    screenSize,
    navTabs,
    blockList,
    language,
    pageState,
    internetStatus,
    services,
    stickers,
    scroll,
    draft,
    chatsCache,
    lastMessageCache,
    settings,
    news,
    supportTickets,
    hints,
    popupPositions
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false
  })
});

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

