import { FormatText } from '../ISystemMessage';

export const createFormatArr = (text: string, ...args: string[]): FormatText[] => {
  const arr: FormatText[] = []
  const regStr = args.reduce((str, _, index) => index === 0 ? str + '(.*){.+?}' : str + '(.+){.+?}','') + '(.*)'
  // const regStr = '(.*){.+?}(.+){.+?}(.*)'
  const reg = new RegExp(regStr)
  const matches = text.match(reg)
  if (!matches) {
    return []
  }
  if (matches[1]) {
    arr.push({ text: matches[1] })
  }
  for (let i = 0; i < args.length; i++) {
    arr.push({ text: args[i], bold: true })
    if (matches[i + 2]) {
      arr.push({ text: matches[i + 2] })
    }
  }
  return arr
}
