import { IHistoryMessage } from '@/api/chats';
import {
  IMessage,
  isMessage,
  ShowedMessage
} from '@/store/messages/messages';
import JXON from 'jxon';
import { AnyObject } from '@/index';
import {
  ISentMessageWithReply,
  MediaType,
  MessageType
} from '@/interfaces/YC';
import Messages from '@/services/Messages';
import { IChatType } from '@/store/chats/chats';

export interface SenderMessage {
  $from: string
  $type: IChatType
}

export const getSender = (msg: SenderMessage) => {
  const index = msg.$from?.lastIndexOf('/')
  let from = ''
  let sender = ''
  if (index >= 0) {
    from = msg.$from.slice(0, index)
    sender = msg.$type === 'groupchat' ? msg.$from.slice(index + 1, msg.$from.length) : from
  } else {
    from = msg.$from
    sender = msg.$from
  }
  const checkReg = new RegExp(`@${process.env.REACT_APP_EJ_HOST}`)
  if (!checkReg.test(sender)) {
    sender = from
  }
  return { from, sender }
}

export const fromHistoryToMessage = (msg: IHistoryMessage): IMessage => {
  const { timestamp, xml, seen } = msg
  const xmlObj: AnyObject = JXON.stringToJs(xml)
  const sentMessage = xmlObj.message as ISentMessageWithReply
  sentMessage.archived = { $id: '' + timestamp }
  const status = seen ? 'displayed' : 'received'
  return Messages.Messages.parseMessage(sentMessage, { status })
}

export const getMessageType = (msg: ISentMessageWithReply): MessageType => {
  if (msg.yc?.media?.item) {
    switch (+msg.yc.media.item.$type) {
      case MediaType.image:
        return 'image'
      case MediaType.audio:
        return 'audio'
      case MediaType.video:
        return 'video'
    }
  } else if (msg.yc.file) {
    return 'file'
  } else if (msg.yc.sticker) {
    return 'sticker'
  } else if (msg.yc.call) {
    return 'call'
  } else if (msg.yc.systemInfo) {
    return 'system'
  }

  return 'text'
}


export const getTypeTranslation = (type: MessageType) => {
  switch (type) {
    case 'audio':
      return 'CHAT.TYPE_MESSAGE_AUDIO'
    case 'file':
      return 'CHAT.TYPE_MESSAGE_FILE'
    case 'video':
      return 'CHAT.TYPE_MESSAGE_VIDEO'
    case 'image':
      return 'CHAT.TYPE_MESSAGE_IMAGE'
    case 'media':
      return 'CHAT.TYPE_MESSAGE_MEDIA'
    case 'sticker':
      return 'CHAT.TYPE_MESSAGE_STICKER'
    case "call":
      return 'CHAT.TYPE_MESSAGE_CALL'
    case 'text':
    default:
      return null
  }
}

export const findLastChatMessage = (messages: ShowedMessage[]): IMessage | undefined => {
  for (let i = messages.length - 1; i >= 0; i--) {
    const message = messages[i]
    if (isMessage(message)) {
      return message
    }
  }
}
