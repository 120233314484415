import {
  useAppDispatch,
  useAppSelector
} from "@/hooks/appHook";
import {
  getUser,
  IInitUser,
  setAdditionalFields,
  setUser
} from "@/store/user/user";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import {
  Chat,
  clear as clearChats,
  GroupCategories,
  GroupCategoriesMap,
  updateChat
} from '@/store/chats/chats';
import { clear as clearScroll } from '@/store/scroll/scroll'
import {
  setTab,
  Tabs
} from "@/store/navTab/navTab";
import { set as setMessages } from "@/store/messages/messages";
import { isMUChat } from "@/utils/chats";
import {
  addRoomToFavorites,
  removeRoomFromFavorites
} from "@/api/chats";
import Roster from "@/services/Roster";
import useChatCache from '@/hooks/useChatCache';
import connection from '@/services/Connection/Connection';
import useLastMessageCache from '@/hooks/useLastMessageCache';
import { delay } from '@/utils/timer';
import {
  authenticate as authenticateApi,
  IAuthData
} from '@/api/auth';

const useUtils = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector(getUser)
  const { clearCache: clearChatCache } = useChatCache()
  const { clearCache: clearMessageCache } = useLastMessageCache()

  const authenticate = useCallback(async(authData: IAuthData) => {
    const { data } = await authenticateApi(authData)

    dispatch((user ? setAdditionalFields : setUser)({
      ...authData,
      ...data
    }))
    return data
  }, [dispatch, user])

  const logout = useCallback((clearQuery = true) => {
    clearChatCache()
    clearMessageCache()
    dispatch(setUser(null))
    let path = '/login'
    if (!clearQuery) {
      const params = new URLSearchParams(window.location.search)
      path += '?' + params.toString()
    }
    navigate(path)
    delay(50)
      .then(() => {
        dispatch(clearChats())
        dispatch(clearScroll())
        dispatch(setMessages({ messages: {} }))
        dispatch(setTab(Tabs.Chats))
      })
  }, [dispatch, navigate, clearChatCache, clearMessageCache])

  const toggleFavoriteUtils = useCallback(async(chat: Chat, user: IInitUser, isFavorite: boolean) => {
    let groups: GroupCategories[]
    if (isFavorite) {
      groups = (chat.groups || []).filter(group => group !== GroupCategoriesMap.favorite)
      if (!groups.includes(GroupCategoriesMap.accepted)) {
        groups.push(GroupCategoriesMap.accepted)
      }
    } else {
      groups = [...(chat.groups || [])]
      groups.push(GroupCategoriesMap.favorite)
      if (!groups.includes(GroupCategoriesMap.accepted)) {
        groups.push(GroupCategoriesMap.accepted)
      }
    }
    if (isMUChat(chat)) {
      const api = isFavorite ? removeRoomFromFavorites : addRoomToFavorites
      await api(chat.$jid)
      dispatch(updateChat({ chatJid: chat.$jid, options: { groups } }))
    } else {
      const msg = Roster.Messages.updateItem({
        from: user.$jid + '',
        to: chat.$jid,
        name: chat.nick || chat.name,
        groups
      })
      await connection.send(msg)
    }
  }, [dispatch])

  return { logout, toggleFavoriteUtils, authenticate }
}

export default useUtils
