import Search from '@/components/Primitive/Inputs/Search/Search';
import styles from './ChatActionPanel.module.scss'
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import useContextMenuList from '@/components/ContextMenu/ContextMenuList/useContextMenuList';
import ContextMenuList, { ContextMenuProps } from '@/components/ContextMenu/ContextMenuList/ContextMenuList';
import {
  ComponentProps,
  MouseEventHandler,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useIntl } from 'react-intl';
import { useModal } from '@/hooks/useModal';
import AddContact from '@/components/Modal/AddContact/AddContact';
import CreateGroupChat from '@/components/Modal/CreateGroupChat/CreateGroupChat';
import LoginAsMember from '@/components/Modal/LoginAsMember/LoginAsMember';
import Statistics from '@/components/Modal/Statistics/Statistics';
import { getUser } from "@/store/user/user";
import { useAppSelector } from "@/hooks/appHook";
import useModalSimple from '@/hooks/useModalSimple';
import Broadcast from "@/components/Modal/Broadcast/Broadcast";
import { ErrorFactory } from '@/services/errorFactory';
import {
  alogixDomains,
  broadcastServiceSet,
  queries
} from '@/constants/general';
import { changeSearchParams } from '@/utils/url';
import { AlogixInteraction } from '@/services/AlogixInteraction/AlogixInteraction';

const ACTIONS = {
  ADD_CONTACT: 'add-contact',
  CREATE_GROUP_CHAT: 'create-group-chat',
  LOGIN_AS_MEMBER: 'login-as-member',
  STATISTICS: 'statistics',
  BROADCAST: 'broadcast',
}

const contextMenuList = {
  list: [
    { text: 'ACTION.ADD_CONTACT', action: ACTIONS.ADD_CONTACT, icon: 'chat-add-contact' },
    { text: 'GROUP_CHAT.CREATE_NEW', action: ACTIONS.CREATE_GROUP_CHAT, icon: 'chat-add-group' },
  ]
}

interface ChatActionProps {
  onChange?: (filter: string) => void
}

const ChatActionPanel = ({ onChange }: ChatActionProps = {}) => {
  const user = useAppSelector(getUser)
  const { formatMessage } = useIntl()

  const { show: showAddContact, hide: hideAddContact, visible: visibleAddContact } = useModalSimple()
  const { show: showCreateGroup, Modal: CreateGroupModal } =
    useModal<ComponentProps<typeof CreateGroupChat>>(CreateGroupChat)
  const { show: showLoginAsMember, Modal: LoginAsMemberModal } =
    useModal<ComponentProps<typeof LoginAsMember>>(LoginAsMember)
  const { show: showStatistics, Modal: StatisticsModal } =
    useModal<ComponentProps<typeof Statistics>>(Statistics)
  const [brodcastProps, setBrodcastProps] =
    useState<Pick<ComponentProps<typeof Broadcast>, 'image' | 'initMessage' | 'title'>>()
  const { visible: broadcastVisible, hide: hideBroadcast, show: showBroadcast } = useModalSimple({
    onHide: useCallback(
      () => {
        setBrodcastProps(undefined)
      },
      []
    )
  })

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const broadcastFrom = params.get(queries.broadcastFrom)
    params.delete(queries.broadcastFrom)
    changeSearchParams(params)
    if (!broadcastServiceSet.has(broadcastFrom + '')) {
      return
    }
    const alogixInteraction = new AlogixInteraction()
    alogixInteraction.getMessage()
      .then((message) => {
        const { text, image } = message
        const textWithSign = text
          ? text + '\n' + formatMessage(
          { id: 'shared_from_alogix' },
          { link: alogixDomains[0] }
        )
          : undefined
          setBrodcastProps({
            initMessage: textWithSign,
            ...(image && {
              image: {
                name: image.name,
                url: image.url,
                ext: image.extension
              }
            })
          })
        showBroadcast()
      })
  }, [showBroadcast, formatMessage]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const broadcastMessage = params.get(queries.broadcast)
    const title = params.get(queries.broadcastTitle)
    params.delete(queries.broadcast)
    params.delete(queries.broadcastTitle)
    changeSearchParams(params)
    if (!broadcastMessage) {
      return
    }
    setBrodcastProps({
      initMessage: broadcastMessage,
      title: title || undefined
    })
    showBroadcast()
  }, [showBroadcast]);

  const onClickContextMenu = (action: string) => {
    switch (action) {
      case ACTIONS.ADD_CONTACT:
        showAddContact()
        break
      case ACTIONS.CREATE_GROUP_CHAT:
        showCreateGroup()
        break
      case ACTIONS.LOGIN_AS_MEMBER:
        showLoginAsMember()
        break
      case ACTIONS.STATISTICS:
        showStatistics()
        break
      case ACTIONS.BROADCAST:
        showBroadcast()
        break
      default:
        throw ErrorFactory.createActionNotImplemented(action)
    }
  }

  const { show: showContextMenu, ContextMenu } = useContextMenuList<ContextMenuProps>({
    Component: ContextMenuList,
    componentProps: {
      list: contextMenuList.list.map(item => ({
        ...item,
        text: formatMessage({ id: item.text })
      })),
    }
  })

  const handleShowMenu: MouseEventHandler<HTMLButtonElement> = (e) => {
    const list = contextMenuList.list.map(item => ({
      ...item,
      text: formatMessage({ id: item.text })
    }))
    if (user?.isAdmin) {
      list.push({
        text: formatMessage({ id: 'LOGIN.LOGIN_AS_MEMBER' }),
        action: ACTIONS.LOGIN_AS_MEMBER, icon: 'chat-members'
      })
      list.push({
        text: formatMessage({ id: 'STATISTICS' }),
        action: ACTIONS.STATISTICS, icon: 'chat-statistics'
      })
    }
    if (user?.canBroadcast) {
      list.push({
        text: formatMessage({ id: 'ACTION.BROADCAST' }),
        action: ACTIONS.BROADCAST, icon: 'chat-horn'
      })
    }
    const position = {
      x: e.pageX,
      y: e.pageY
    }
    showContextMenu({
      position,
      componentProps: {
        list,
        onClick: onClickContextMenu
      }
    })
  }

  return <div className={styles.panel}>
    <Search wrapperClassName={styles.search} onChange={onChange} size={5} />
    <IconButton
      className={styles.button}
      onClick={handleShowMenu}
    ><i className={'chat-plus'}></i>
    </IconButton>
    <ContextMenu />
    {visibleAddContact && <AddContact hide={hideAddContact} />}
    <CreateGroupModal />
    <LoginAsMemberModal />
    <StatisticsModal />
    {broadcastVisible ? <Broadcast
      {...brodcastProps}
      hide={hideBroadcast}
    /> : null}
  </div>
}

export default ChatActionPanel
