import styles from './SearchedList.module.scss'
import {
  IUsersFound,
  ISearchGroup
} from '@/api/users';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualList from '@/components/VirtualList/VirtualList';
import SearchedItem, { SearchedData } from '@/components/SearchedItem/SearchedItem';
import memoize from 'memoize-one';
import {
  useEffect,
  useState
} from 'react';
import {
  Chat,
  GroupCategoriesMap
} from '@/store/chats/chats';

interface UserListProps {
  users: IUsersFound[],
  groups: ISearchGroup[]
  maxSelected?: number
  selected?: string[]
  onSelected?: (contacts: (IUsersFound | ISearchGroup)[]) => void
  onOpenChat?: (jid: string) => void
  reselect?: boolean
  contacts?: Chat[]
  allowSelectContact?: boolean
}

const createUsersData = memoize(({ users, groups, ...props }: SearchedData) => ({
  ...props,
  users,
  groups,
  length: users.length + groups.length,
}))

const ITEM_HEIGHT = 64
const MAX_ELEMENT_VISIBLE = 5

const SearchedList = (
  {
    users,
    groups,
    maxSelected = 1,
    selected,
    onSelected,
    onOpenChat,
    reselect = true,
    contacts,
    allowSelectContact = false
  }: UserListProps) => {
  const [length, setLength] = useState(users.length + groups.length)
  const [contactsSelected, setContactsSelected] = useState<Set<string>>(new Set(selected || []))
  const [contactsSet, setContactsSet] = useState<Set<string>>(new Set([]))
  const [teamContacts, setTeamContacts] = useState<Set<string>>(new Set([]))

  useEffect(() => {
    setContactsSet(new Set((contacts || [])
      .filter(contact => {
        const groups = new Set(contact.groups || [])
        return groups.has(GroupCategoriesMap.pending) ||
          groups.has(GroupCategoriesMap.accepted)
      })
      .map(contact => contact.$jid)))

    setTeamContacts(new Set((contacts || [])
      .filter(contact => {
        const groups = new Set(contact.groups || [])
        return groups.has(GroupCategoriesMap.consultant) ||
          groups.has(GroupCategoriesMap.sponsor) ||
          groups.has(GroupCategoriesMap.customer)
      })
      .map(contact => contact.$jid)))
  }, [contacts])

  useEffect(() => {
    setLength(users.length + groups.length)
  }, [users.length, groups.length])

  useEffect(() => {
    const newUsers = new Set(users.map(user => user.jid))
    const newGroups = new Set(groups.map(group => group.uid))
    let changed = false
    contactsSelected.forEach(contact => {
      if (!newUsers.has(contact) && !newGroups.has(contact)) {
        contactsSelected.delete(contact)
        changed = true
      }
    })
    if (changed) {
      setContactsSelected(new Set(contactsSelected))
    }
  }, [users, groups, contactsSelected])

  useEffect(() => {
    if (onSelected) {
      const usersChecked = users.filter(user => contactsSelected.has(user.jid))
      const groupsChecked = groups.filter(group => contactsSelected.has(group.uid))
      onSelected([...usersChecked, ...groupsChecked])
    }
  }, [contactsSelected, onSelected, users, groups])

  const handleOpenChat = (jid: string) => {
    onOpenChat?.(jid)
  }

  const handleSelect = (jid: string) => {
    if (contactsSet?.has(jid) && !allowSelectContact) {
      return;
    }
    if (contactsSelected.has(jid)) {
      contactsSelected.delete(jid)
    } else {
      if (contactsSelected.size >= maxSelected) {
        if (maxSelected === 1 && reselect) {
          contactsSelected.clear()
        } else {
          return
        }
      }
      contactsSelected.add(jid)
    }
    setContactsSelected(new Set(contactsSelected))
  }

  return <div
    className={styles.wrapper}
    style={{
      height: length > MAX_ELEMENT_VISIBLE ? MAX_ELEMENT_VISIBLE * ITEM_HEIGHT : length * ITEM_HEIGHT,
    }}
  >
    <AutoSizer>
      {({ width, height }) => (
        <VirtualList<SearchedData>
          width={width || '100%'}
          height={height || '100%'}
          itemCount={length}
          data={{
            users,
            groups,
            onClick: handleSelect,
            onOpen: handleOpenChat,
            selected: contactsSelected,
            contacts: contactsSet,
            teamContacts
          }}
          render={SearchedItem}
          createItemData={createUsersData}
          itemSize={() => ITEM_HEIGHT}
        />
      )}
    </AutoSizer>
  </div>
}

export default SearchedList

