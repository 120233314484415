import {useAppSelector} from '@/hooks/appHook';
import {Chat, getAllChats} from '@/store/chats/chats';
import {useCallback, useEffect, useRef} from 'react';
import {CallRequest, IncomingCallEvent, isCancelCallEvent, isIncomingCall} from '@/services/CallSocket/ICallEvents';
import callSocket from '@/services/CallSocket/CallSocket';
import {SocketEvents} from '@/services/Socket/ISocket';

interface OpenCallDialogProps {
  chat?: Chat,
  incoming?: boolean,
  token?: string,
}

interface IncomingCallHandlerProps {
  isCallNow: boolean
  openCallDialog?: (props: OpenCallDialogProps) => void
}

const MAX_WAIT_CHAT_TIMEOUT = 10000

const useIncomingCallHandler = ({isCallNow, openCallDialog}: IncomingCallHandlerProps) => {
  const allChats = useAppSelector(getAllChats)
  const lastEvent = useRef<IncomingCallEvent>()
  const eventTimer = useRef<NodeJS.Timer>()

  const openDialogWithCheck = useCallback((request: IncomingCallEvent) => {
    if (isCallNow) {
      const msg = callSocket.Messages.cancelIncoming(request.target)
      callSocket.sendMessage(msg)
      return
    }
    openCallDialog?.({
      chat: allChats[request.target],
      incoming: true,
      token: request.token,
    })
  }, [openCallDialog, allChats, isCallNow])

  useEffect(() => {
    if (!lastEvent.current) {
      return
    }

    if (allChats[lastEvent.current.target]) {
      openDialogWithCheck(lastEvent.current)
      lastEvent.current = undefined
    }
  }, [allChats, openDialogWithCheck]);


  const startIncomingCall = useCallback((data: CallRequest) => {
    if (lastEvent.current && isCancelCallEvent(data) && data.target === lastEvent.current.target) {
      clearTimeout(eventTimer.current)
      lastEvent.current = undefined
      return;
    }
    if (!isIncomingCall(data)) {
      return;
    }
    if (!allChats[data.target]) {
      lastEvent.current = data
      clearTimeout(eventTimer.current)
      eventTimer.current = setTimeout(() => {
        lastEvent.current = undefined
      }, MAX_WAIT_CHAT_TIMEOUT)
      return;
    }
    openDialogWithCheck(data)
  }, [allChats, openDialogWithCheck])

  useEffect(() => {
    callSocket.addEventListener(SocketEvents.onMessage, startIncomingCall)
    return () => {
      callSocket.removeEventListener(SocketEvents.onMessage, startIncomingCall)
    }
  }, [startIncomingCall]);

  return {}
}

export default useIncomingCallHandler
