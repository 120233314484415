import {
  AliasRequest,
  AliasResponse,
  GroupDataRequest,
  GroupDataResponse,
  ITabsInteractionRequest,
  LanguageRequest,
  LanguageResponse
} from '@/interfaces/tabsInteraction';
import { TabsInteractionType } from '@/constants/tabsInteraction';
import { ConnectIntaractionEventMap } from './ConnectInteraction.d'
import { formatPath } from '@/utils/path';
import store from '@/store';
import {
  isAliasRequest,
  isGroupDataRequest,
  isInteractionError,
  isLanguageRequest,
  isPrePopupClosedEvent,
  isRoomClosedRequest,
  isRoomOverflowedEvent
} from '@/predicates/tabsInteraction';
import TabsInteraction from '@/services/TabsInteraction/TabsInteraction';

const connectDomain = formatPath('' + process.env.REACT_APP_CONNECT_DOMAIN)
export default class ConnectInteraction extends TabsInteraction<ConnectIntaractionEventMap> {

  private _aliasRequestHandler(event: MessageEvent<AliasRequest>) {
    const state = store.getState()
    const { data: { data } } = event
    const { uid } = data
    const user = state.chats.privateChats[`${uid}@${process.env.REACT_APP_EJ_HOST}`]
    const nickname = user?.nick || user?.name
    if (!nickname) {
      return
    }
    const response: AliasResponse = {
      type: TabsInteractionType.AliasResponse,
      data: {
        uid,
        name: nickname
      }
    }
    this._sendEvent(response, event)
  }

  private _groupDataRequestHandler(event: MessageEvent<GroupDataRequest>) {
    const state = store.getState()
    const { data: { data } } = event
    const { uid } = data
    const group = state.chats.muChats[`${uid}@conference.${process.env.REACT_APP_EJ_HOST}`]
    const response: GroupDataResponse = {
      type: TabsInteractionType.GroupDataResponse,
      data: {
        uid,
        name: group.name,
        image: group.vcard?.thumbnail
      }
    }
    this._sendEvent(response, event)
  }

  private _languageRequestHandler(event: MessageEvent<LanguageRequest>) {
    const state = store.getState()
    const language = state.language.lang
    const response: LanguageResponse = {
      type: TabsInteractionType.LanguageResponse,
      data: language
    }
    this._sendEvent(response, event)
  }

  protected _onMessage(event: MessageEvent) {
    if (!event.origin.startsWith(connectDomain)) {
      return
    }
    const data: ITabsInteractionRequest = event.data
    if (isRoomClosedRequest(data) ||
      isPrePopupClosedEvent(data) ||
      isRoomOverflowedEvent(data)
    ) {
      this.emit(data.type)
    } else if (isAliasRequest(data)) {
      this._aliasRequestHandler(event)
    } else if (isGroupDataRequest(data)) {
      this._groupDataRequestHandler(event)
    } else if (isLanguageRequest(data)) {
      this._languageRequestHandler(event)
    } else if (isInteractionError(data)) {
      this.emit(TabsInteractionType.Error, data.data)
    }
  }
}
