import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {isVisible} from '@/store/pageState/pageState';
import {useCallback, useEffect, useRef, useState} from 'react';
import {
  ChatList,
  getMUChats,
  isMuChatsLoaded,
  isPrivateChatsLoaded,
  isThreadsLoaded,
  updateChat
} from '@/store/chats/chats';
import Messages from '@/services/Messages';
import {getAllMessages, getLastMessage, setLastMessageBeforeOffline} from '@/store/messages/messages';
import dayjs from 'dayjs';
import {useMUChatList} from '@/hooks/useMUChatList';
import {getUser} from '@/store/user/user';
import {usePrivateChatList} from '@/hooks/usePrivateChatList';
import Presence from '@/services/Presence';
import Connection from '@/services/Connection/Connection';
import connection from '@/services/Connection/Connection';
import useQueueSender from '@/hooks/useQueueSender';
import JXON from 'jxon';
import {getSender} from '@/utils/messages';
import {AnyObject} from '@/index';
import {sortChats} from '@/utils/chats';
import {ConnectionEventNames} from '@/services/Connection/IConnection';
import {
  getGroupCallsStatuses,
} from '@/api/chats';

const MAX_HISTORY_TIMEOUT = [10000]
const MAX_REQUEST = 30
const PING_TIMEOUT = [3000, 6000, 15000]
const QUEUE_NAME = 'historyRequestQueue'

let errorRequestAmount = 0

type AppState = 'started' | 'collapsed' | 'expanded'

const useAfterOffline = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const visible = useAppSelector(isVisible)
  const privateChatsLoaded = useAppSelector(isPrivateChatsLoaded)
  const muChatsLoaded = useAppSelector(isMuChatsLoaded)
  const threadsLoaded = useAppSelector(isThreadsLoaded)
  const rooms = useAppSelector(getMUChats)
  const messages = useAppSelector(getAllMessages)
  const lastMessage = useAppSelector(getLastMessage)
  const [connected, setConnected] = useState(connection.isConnected)
  const needUpdateHistory = useRef(false)
  const firstOffline = useRef(true)
  const lastTimestamp = useRef(dayjs().subtract(10, 'seconds').toDate().getTime())
  const lastMessageTimestamp = useRef(0)
  const historyUpdating = useRef(false)
  const appState = useRef<AppState>('started')
  const chatRequested = useRef<Set<string>>(new Set())
  const roomsTimestamp = useRef<Map<string, number>>(new Map())
  const updateGroupCallsStatuses = useRef<() => void>();
  const isFirstUpdateGroupCallsStatuses = useRef(true);
  const {update: updateRoomList} = useMUChatList()
  const {update: updateContactList} = usePrivateChatList()
  const {send: sendToQueue, clear: clearQueue} = useQueueSender({
    queueName: QUEUE_NAME,
    timeout: MAX_HISTORY_TIMEOUT[errorRequestAmount < MAX_HISTORY_TIMEOUT.length ?
      errorRequestAmount : MAX_HISTORY_TIMEOUT.length - 1],
    minInterval: 40,
    limit: MAX_REQUEST,
  })

  useEffect(() => {
    setConnected(connection.isConnected)
    connection.addEventListener(ConnectionEventNames.ConnectedChanged, setConnected)
    return () => {
      connection.removeEventListener(ConnectionEventNames.ConnectedChanged, setConnected)
    }
  }, []);

  const ping = useCallback((): Element => {
    return Connection.Messages.ping({
      from: user?.$jid || '',
    })
  }, [user?.$jid])

  const getSortedKeyRoom = useCallback((rooms: ChatList) => {
    const keys = Object.keys(rooms)
    const sort = sortChats(messages)
    keys.sort(sort)
    return keys
  }, [messages])

  const getHistoryFromRoom = useCallback((jid: string) => {
    if (chatRequested.current.has(jid)) {
      return
    }
    const timestamp = lastMessageTimestamp.current || lastTimestamp.current
    const mapTimestamp = roomsTimestamp.current.get(jid)
    if (mapTimestamp == null) {
      roomsTimestamp.current.set(jid, timestamp)
    }
    const msg = Messages.Messages.createHistoryMessage({
      timestampStart: mapTimestamp || timestamp,
      to: jid,
    })
    chatRequested.current.add(jid)
    sendToQueue({message: msg, watchBy: jid})
      .then(() => {
        roomsTimestamp.current.delete(jid)
      })
      .finally(() => {
        chatRequested.current.delete(jid)
      })
  },[sendToQueue])

  const updateHistory = useCallback(async () => {
    if (historyUpdating.current || !user?.memberId) {
      return
    }
    historyUpdating.current = true
    clearQueue()
    const jid = `${user.memberId}@${process.env.REACT_APP_EJ_HOST}`
    const timestamp = lastMessageTimestamp.current || lastTimestamp.current
    try {
      await sendToQueue({
        message: ping(),
        timeout: PING_TIMEOUT[errorRequestAmount < PING_TIMEOUT.length ?
          errorRequestAmount : PING_TIMEOUT.length - 1],
        immediately: true,
      })
      updateRoomList(jid, false)
      updateContactList(jid, false)
      const msg = Messages.Messages.createHistoryMessage({
        timestampStart: timestamp,
      })
      sendToQueue({message: msg})
      for (const roomKey of getSortedKeyRoom(rooms)) {
        getHistoryFromRoom(roomKey)
      }
      needUpdateHistory.current = false
      errorRequestAmount = 0
    } catch (e) {
      clearQueue()
      errorRequestAmount++
      connection.disconnect()
    } finally {
      historyUpdating.current = false
    }
  }, [
    sendToQueue,
    clearQueue,
    getHistoryFromRoom,
    user?.memberId,
    updateRoomList,
    ping,
    updateContactList,
    rooms,
    getSortedKeyRoom,
  ])

  const updateTimestamp = useCallback(() => {
    lastTimestamp.current = dayjs().subtract(10, 'seconds').valueOf() * 1000
    lastMessageTimestamp.current = lastMessage?.timestamp || 0
    dispatch(setLastMessageBeforeOffline(lastMessage))
  }, [dispatch, lastMessage])

  const onAfterExpanded = useCallback(() => {
    const msg = Presence.Messages.available()
    connection.sendStrophe(msg)
    updateHistory()
  }, [updateHistory])

  const onCollapse = useCallback(() => {
    const msg = Presence.Messages.unavailable()
    connection.sendStrophe(msg)
    appState.current = 'collapsed'
    if (!needUpdateHistory.current) {
      updateTimestamp()
    }
    needUpdateHistory.current = true
  }, [updateTimestamp])

  const onExpand = useCallback(() => {
    if (appState.current === 'collapsed') {
      if (connection.isConnected) {
        onAfterExpanded()
        appState.current = 'started'
      } else {
        appState.current = 'expanded'
        needUpdateHistory.current = true
      }

    }
  }, [onAfterExpanded])

  const presenceHandler = useCallback((stanza: Element) => {
    if (firstOffline.current) {
      return true
    }
    const {presence} = JXON.stringToJs(stanza.outerHTML) as AnyObject
    const {from} = getSender({$from: presence.$from || '', $type: 'groupchat'})
    getHistoryFromRoom(from)
    return true
  }, [getHistoryFromRoom])

  updateGroupCallsStatuses.current = useCallback(async() => {
    const statuses = await getGroupCallsStatuses()
    for (const status of statuses) {
      dispatch(updateChat({
        chatJid: status.roomId,
        options: {
          groupCallStart: status.groupCallStart
        }
      }))
    }
  }, [dispatch])

  useEffect(() => {
    const handler = connection.addHandler(presenceHandler, 'http://jabber.org/protocol/muc#user', 'presence')
    return () => {
      connection.deleteHandler(handler)
    }
  }, [presenceHandler]);

  useEffect(() => {
    if (connected && appState.current === 'expanded') {
      appState.current = 'started'
      onAfterExpanded()
    }
  }, [connected, onAfterExpanded])

  useEffect(() => {
    window.addEventListener('collapse', onCollapse)
    return () => {
      window.removeEventListener('collapse', onCollapse)
    }
  }, [onCollapse])

  useEffect(() => {
    window.addEventListener('expand', onExpand)
    return () => {
      window.removeEventListener('expand', onExpand)
    }
  }, [onExpand])

  useEffect(() => {
    window.addEventListener('app-online', updateHistory)
    return () => {
      window.removeEventListener('app-online', updateHistory)
    }
  }, [updateHistory]);

  useEffect(() => {
    if ((!connected || !visible)
      && !needUpdateHistory.current
      && threadsLoaded
    ) {
      needUpdateHistory.current = true
      updateTimestamp()
    }
  }, [connected, threadsLoaded, visible, updateTimestamp])

  useEffect(() => {
    if (!connected && privateChatsLoaded && muChatsLoaded && threadsLoaded) {
      firstOffline.current = false
    }
  }, [connected, privateChatsLoaded, muChatsLoaded, threadsLoaded])

  useEffect(() => {
    if (visible && connected && needUpdateHistory.current) {
      updateHistory()
    }
  }, [visible, connected, updateHistory])

  useEffect(() => {
    if (visible && connected && threadsLoaded) {
      if (isFirstUpdateGroupCallsStatuses.current) {
        isFirstUpdateGroupCallsStatuses.current = false
        return
      }
      updateGroupCallsStatuses.current?.()
    }
  }, [visible, connected, threadsLoaded]);
}

export default useAfterOffline
