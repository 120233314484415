import { TSystemMessageType } from '@/interfaces/YC';

export const getTranslationByType = (type?: TSystemMessageType): string | null => {
  switch (type) {
    case 'group-created':
      return 'MESSAGE.CREATED_GROUP'
    case 'invited':
      return 'MESSAGE.INVITED_USER'
    case 'group-call-started':
      return 'MESSAGE.STARTED_GROUP_CALL'
    case 'group-call-ended':
      return 'MESSAGE.ENDED_GROUP_CALL'
    case 'user-removed':
      return 'MESSAGE.USER_REMOVED'
    default:
      return null
  }
}

export const getSimpleTranslationByType = (type?: TSystemMessageType): string | null => {
  switch (type) {
    case 'group-created':
      return 'MESSAGE.CREATED_GROUP2'
    case 'invited':
      return 'MESSAGE.PARTICIPANT_ADDED'
    case 'group-call-started':
      return 'MESSAGE.CALL_STARTED'
    case 'group-call-ended':
      return 'MESSAGE.CALL_ENDED'
    case 'user-removed':
      return 'MESSAGE.PARTICIPANT_REMOVED'
    default:
      return null
  }
}
