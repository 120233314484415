import {Chat} from '@/store/chats/chats';
import memoize from 'memoize-one';
import ContactSelectItem, {ContactSelectData} from '@/components/ContactSelectItem/ContactSelectItem';
import styles from './ContactSelect.module.scss';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualList from '@/components/VirtualList/VirtualList';
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {filterChats} from '@/utils/chats';
import classNames from 'classnames';
import Button from '@/components/Primitive/Buttons/Button/Button';
import {FormattedMessage} from 'react-intl';
import {ReactComponent as ContactsImage} from '@/images/icons/contacts.svg';

interface ContactSelectProps {
  contacts: Chat[],
  maxHeight?: number
  maxVisibleElement?: number
  maxSelected?: number
  canSelectAll?: boolean
  selected?: string[]
  reselect?: boolean,
  filter?: string,
  className?: string
  onSelected?: (contact: Chat[]) => void
}

const createContactsData = memoize(({contacts, selected, onClick}: ContactSelectData) => ({
  contacts,
  selected,
  onClick,
  length: contacts.length,
}))

const ITEM_HEIGHT = 64
const MAX_ELEMENT_VISIBLE = 5

export interface ContactSelectHandle {
  clear: () => void
  remove: (id: string) => void
}

const ContactSelect = forwardRef<ContactSelectHandle, ContactSelectProps>((
  {
    contacts,
    maxSelected = 1,
    maxHeight,
    maxVisibleElement = MAX_ELEMENT_VISIBLE,
    canSelectAll,
    selected,
    reselect = true,
    filter,
    className,
    onSelected,
  }: ContactSelectProps, ref) => {
  const [contactsFiltered, setContactsFiltered] = useState(filterChats(contacts, filter))
  const [length, setLength] = useState(contactsFiltered.length)
  const [contactsSelected, setContactsSelected] = useState<Set<string>>(new Set(selected || []))

  useImperativeHandle(ref, () => {
    return {
      clear() {
        setContactsSelected(new Set())
      },
      remove(id: string) {
        setContactsSelected(prevState => {
          if (prevState.has(id)) {
            prevState.delete(id)
            return new Set<string>(prevState)
          }
          return prevState
        })
      }
    }
  }, [])

  useEffect(() => {
    setContactsFiltered(filterChats(contacts, filter))
  }, [contacts, filter, contactsSelected])

  useEffect(() => {
    setLength(contactsFiltered.length)
  }, [contactsFiltered.length])

  useEffect(() => {
    if (onSelected) {
      const contactsChecked = contacts.filter(contact => contactsSelected.has(contact.$jid))
      onSelected(contactsChecked)
    }
  }, [contactsSelected, onSelected, contacts])

  const handleSelect = (jid: string) => {
    if (contactsSelected.has(jid)) {
      contactsSelected.delete(jid)
    } else {
      if (contactsSelected.size >= maxSelected) {
        if (maxSelected === 1 && reselect) {
          contactsSelected.clear()
        } else {
          return
        }
      }
      contactsSelected.add(jid)
    }
    setContactsSelected(new Set(contactsSelected))
  }

  const handleSelectAll = () => {
    if (contactsSelected.size === contacts.length) {
      setContactsSelected(new Set())
    } else {
      setContactsSelected(new Set(contacts.map(contact => contact.$jid)))
    }
  }

  const getBoxHeight = useMemo(() => {
    let height = length * ITEM_HEIGHT
    if (maxHeight) {
      if (height > maxHeight) {
        height = maxHeight
      }
    } else if (length > maxVisibleElement) {
      height = maxVisibleElement * ITEM_HEIGHT
    }
    return height
  }, [length, maxHeight, maxVisibleElement])

  return <div
    className={classNames(styles.wrapper, className)}
    style={{
      height: getBoxHeight,
    }}
  >
    {canSelectAll && <FormattedMessage
      id={contactsSelected.size === contacts.length ? 'ACTION.CANCEL' : 'ACTION.SELECT_ALL'}>
      {txt => <Button
        className={styles.selectAllBtn}
        view="link"
        onClick={handleSelectAll}
      >
        {txt}
      </Button>}
    </FormattedMessage>}
    <div className={styles.sizerBox}>
      <div className={styles.sizerWrapper}>
        {contactsFiltered.length ? <AutoSizer>
          {({width, height}) => (
            <VirtualList<ContactSelectData>
              width={width || '100%'}
              height={height || '100%'}
              itemCount={length}
              data={{
                contacts: contactsFiltered,
                onClick: handleSelect,
                selected: contactsSelected,
              }}
              render={ContactSelectItem}
              createItemData={createContactsData}
              itemSize={() => ITEM_HEIGHT}
            />
          )}
        </AutoSizer> : <div className={styles.emptyBox}>
          <ContactsImage className={styles.emptyImg}/>
          <FormattedMessage id={'no_contacts_to_search'}>
            {txt => <p className={styles.emptyTxt}>{txt}</p>}
          </FormattedMessage>
        </div>}
      </div>
    </div>
  </div>
})

export default ContactSelect
