import {BaseModalProps} from '@/components/Modal/BaseModal/BaseModal';
import {FormattedMessage, useIntl} from 'react-intl';
import styles from './AddContact.module.scss'
import BaseModalWithTitle from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle';
import Search from '@/components/Primitive/Inputs/Search/Search';
import SearchedList from '@/components/SearchedList/SearchedList';
import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { ISearchGroup, IUsersFound, search } from '@/api/users';
import Button from '@/components/Primitive/Buttons/Button/Button';
import {usePrivateChatList} from '@/hooks/usePrivateChatList';
import usePresence from '@/hooks/usePresence';
import useVCard from '@/hooks/useVCard';
import Textarea from '@/components/Primitive/Textarea/Textarea';
import Messages from '@/services/Messages';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {getUser} from '@/store/user/user';
import useMessage from '@/hooks/useMessage';
import { getAllChats, GroupCategoriesMap, updateChat } from '@/store/chats/chats';
import { contactListToArray, getChatId } from '@/utils/chats';
import {pushApp} from "@/api/push-app";
import { isUsersFound } from '@/components/SearchedList/utils'
import { addToRoom } from '@/api/chats'
import { useMUChatList } from '@/hooks/useMUChatList'
import {show as showNotify} from '@/components/Modal/UserNotify/UserNotify';
import useActiveService from '@/hooks/useActiveService'

interface AddContactProps extends BaseModalProps {
}

const DELAY_FILTER_TIME = 700
const WELCOME_MESSAGE_MAX_LENGTH = 100

const AddContact = ({hide}: AddContactProps) => {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(getUser)
  const {formatMessage} = useIntl()
  const contacts = useAppSelector(getAllChats)
  const [users, setUsers] = useState<IUsersFound[]>([])
  const [groups, setGroups] = useState<ISearchGroup[]>([])
  const [contactsSelected, setContactsSelected] = useState<(IUsersFound | ISearchGroup)[]>([])
  const [noResults, setNoResults] = useState(false)
  const [filter, setFilter] = useState('')
  const [welcomeMessage, setWelcomeMessage] = useState('')
  const searchRef = useRef<HTMLInputElement>(null)
  const {subscribe} = usePresence()
  const {getVCard} = useVCard()
  const {addContact} = usePrivateChatList()
  const {sendMessage} = useMessage()
  const {getMembers} = useMUChatList()
  const {setActiveChatId} = useActiveService()

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus()
    }
  }, [])

  useEffect(() => {
    if (!filter.length) {
      return
    }
    const delayFilterTimer = setTimeout(() => {
      setNoResults(false)
      search({
        searchString: filter,
      })
        .then(data => {
          const users = data.users
            .filter(user => user.jid !== currentUser?.$jid)
          setUsers(users)
          const groups = data.groups || []
          setGroups(groups)
          if (!(users.length + groups.length) ) {
            setNoResults(true)
          }
        })
    }, DELAY_FILTER_TIME)
    return () => {
      clearTimeout(delayFilterTimer)
    }
  }, [filter, currentUser, contacts])

  const subscribeHandler = () => {
    if (!currentUser) {
      return
    }
    contactsSelected.forEach(contact => {
      if (isUsersFound(contact)) {
        addContact(contact.jid, contact.nick, [GroupCategoriesMap.pending])
        pushApp(contact.jid, 'friend').then()
        subscribe(contact.jid)
        getVCard(contact.jid)
        if (welcomeMessage.length > 0) {
          const msg = Messages.Messages.createTextMessage({
            from: currentUser.$jid + '',
            to: contact.jid,
            text: welcomeMessage,
          })
          sendMessage(msg, {
            sendPush: false
          })
        }
      } else {
        addToRoom({
          participantJids: [currentUser.memberId],
          roomJid: getChatId(contact.uid),
          referrer: '',
        }).then(() => {
          dispatch(updateChat({
            chatJid: contact.uid, options: {
              $subscription: 'both',
            },
          }))
          setActiveChatId(contact.uid)
          getVCard(contact.uid)
          getMembers(contact.uid)
        })
          .catch(() => {
            showNotify({
              message: formatMessage({id: 'you_cannot_join_this_group'}),
            })
          })
      }
    })
    hide?.()
  }

  const handleWelcomeMessage: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const text = e.target.value.length > WELCOME_MESSAGE_MAX_LENGTH ?
      e.target.value.substring(0, WELCOME_MESSAGE_MAX_LENGTH) : e.target.value
    setWelcomeMessage(text)
  }

  const handleOpenChat = (jid: string) => {
    setActiveChatId(jid)
    hide?.()
  }

  return <BaseModalWithTitle
    hide={hide}
    title={formatMessage({id: 'ACTION.ADD_CONTACT'})}
    className={styles.addContactClassName}
  >
    <div className={styles.contextWrapper}>
      <FormattedMessage id={'CONTACTS_ADD.SEARCH_PLACEHOLDER'}>
        {text => <p className={styles.description}>{text}</p>}
      </FormattedMessage>
      <Search ref={searchRef} light={true} big={true} onChange={setFilter}/>
      {noResults && <FormattedMessage id={'search_no_results'}>
        {txt => <span className={styles.noResults}>{txt}</span>}
      </FormattedMessage>}
      <SearchedList
        users={users}
        groups={groups}
        maxSelected={1}
        onSelected={setContactsSelected}
        onOpenChat={handleOpenChat}
        contacts={contactListToArray(contacts).filter(contact => contact?.$subscription !== 'none')}
      />
      {
        !!contactsSelected.length
        && isUsersFound(contactsSelected[0])
        && contactsSelected[0].countMessage === 0 && <>
          <FormattedMessage id={'CONTACTS_ADD.ENTER_WELCOME_MESSAGE'}>
            {txt => <p className={styles.description}>
              {txt} {`(${welcomeMessage.length}/${WELCOME_MESSAGE_MAX_LENGTH})`}
            </p>}
          </FormattedMessage>
          <Textarea
            maxRows={4}
            value={welcomeMessage}
            onChange={handleWelcomeMessage}
          />
        </>
      }
      <div className={styles.buttons}>
        <FormattedMessage id={'ACTION.ADD'}>
          {txt => <Button
            view={'main'}
            disabled={!contactsSelected.length}
            onClick={subscribeHandler}
          >{txt}
          </Button>}
        </FormattedMessage>
        <FormattedMessage id={'ACTION.CANCEL'}>
          {txt => <Button
            view={'link'}
            onClick={hide}
          >{txt}</Button>}
        </FormattedMessage>
      </div>
    </div>
  </BaseModalWithTitle>
}

export default AddContact
