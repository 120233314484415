import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'
import { RootState } from "@/store";
import { axiosRequest } from "@/api/axiosInit";
import {
  removeAccessTokenFromApp,
  sendAccessTokenToApp,
  sendTokenToApp
} from '@/utils/app'
import { localStorageFields } from '@/constants/localStorage';

const FIELD_NAME = localStorageFields.currentUser

export interface IInitUser {
  memberId: string,
  $jid?: string,
  accessToken: string,
  token?: string,
  nickname?: string,
  thumbnail?: string,
  url?: string,
  email?: string,
  phoneNumber?: string,
  refInfo?: {
    ref: string,
    link: string
  },
  isAdmin: boolean,
  canBroadcast: boolean,
  chatReferrerLink?: string,
  sso?: boolean
}

export type IUserVCard = {
  nickname: string
  thumbnail?: string | null
  url?: string | null
}

export type IUserVCardKeys = keyof IUserVCard

export const changeToken = (token?: string | null) => {
  if (token) {
    axiosRequest.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    sendTokenToApp(token)
  } else {
    axiosRequest.defaults.headers.common['Authorization'] = undefined;
  }
}

const DELETED_FIELDS: (keyof IInitUser)[] = ['token']

const saveUser = (user: IInitUser | null) => {
  if (!user) {
    localStorage.setItem(FIELD_NAME, JSON.stringify(user))
    return
  }
  const savedUser = { ...user }
  DELETED_FIELDS.forEach(field => {
    delete savedUser[field]
  })
  localStorage.setItem(FIELD_NAME, JSON.stringify(savedUser))
}

const initUser = JSON.parse('' + localStorage.getItem(FIELD_NAME)) as (IInitUser | null)
changeToken(initUser?.token || '')

if (initUser) {
  sendAccessTokenToApp({
    accessToken: initUser.accessToken,
    memberId: initUser.memberId
  })
}

export const user = createSlice({
  name: 'user',
  initialState: {
    user: initUser
  },
  reducers: {
    setUser: (state, { payload }: PayloadAction<IInitUser | null>) => {
      if (payload &&
        (state.user?.token !== payload.token ||
          state.user?.memberId !== payload.memberId)
      ) {
        sendAccessTokenToApp({
          accessToken: payload.accessToken,
          memberId: payload.memberId
        })
      } else if (!payload?.token) {
        removeAccessTokenFromApp()
      }
      state.user = payload
      changeToken(payload?.token)
      if (state.user && !state.user.$jid) {
        state.user.$jid = state.user.memberId + '@' + process.env.REACT_APP_EJ_HOST
      }
      saveUser(payload)
    },
    setToken: (state, action: PayloadAction<string>) => {
      if (!state.user) {
        state.user = {
          memberId: '',
          accessToken: '',
          isAdmin: false,
          canBroadcast: false,
        }
      }
      changeToken(action.payload)
      state.user.token = action.payload
      saveUser(state.user)
    },
    setAdditionalFields(state, { payload }: PayloadAction<Partial<IInitUser>>) {
      if (!state.user) {
        return
      }
      if (payload.token) {
        changeToken(payload.token)
      }
      state.user = {
        ...state.user,
        ...payload
      }
      saveUser(state.user)
    }
  }
})

export const { setUser, setToken, setAdditionalFields } = user.actions
export const getUser = (state: RootState) => state.user.user

export default user.reducer
