import styles from './Forward.module.scss'
import BaseModalWithTitle from '@/components/Modal/BaseModalWithTitle/BaseModalWithTitle';
import {BaseModalProps} from '@/components/Modal/BaseModal/BaseModal';
import {FormattedMessage, useIntl} from 'react-intl';
import Search from '@/components/Primitive/Inputs/Search/Search';
import ContactSelect from '@/components/ContactSelect/ContactSelect';
import {useAppSelector} from '@/hooks/appHook';
import {Chat, ChatList, getAllChats} from '@/store/chats/chats';
import {useCallback, useEffect, useState} from 'react';
import {filterFavoriteChats, getApprovedChats} from '@/utils/chats';
import Button from '@/components/Primitive/Buttons/Button/Button';
import {getAllMessages} from '@/store/messages/messages';
import { findLastChatMessage } from '@/utils/messages';

interface ForwardModalProps extends BaseModalProps{
    onSend?: (chats: Chat[]) => void
}

const chatArrayFromList = (chats: ChatList): Chat[] => {
  return Object.keys(chats).map(key => chats[key])
}

const Forward = ({hide, onSend}: ForwardModalProps) => {
  const {formatMessage} = useIntl()
  const allChats = useAppSelector(getAllChats)
  const messages = useAppSelector(getAllMessages)
  const [approvedChats, setApprovedChats] = useState(getApprovedChats(chatArrayFromList(allChats)))
  const [filter, setFilter] = useState('')
  const [selectedContacts, setSelectedContacts] = useState<Chat[]>([])

  const sortChats = useCallback((chat1: Chat, chat2: Chat) => {
    const favoriteSort = +filterFavoriteChats(chat2) - +filterFavoriteChats(chat1)
    if (favoriteSort !== 0) {
      return favoriteSort
    }
    const jid1 = chat1.$jid
    const jid2 = chat2.$jid
    const time1 = findLastChatMessage(messages[jid1]?.messages || [])?.timestamp || 0
    const time2 = findLastChatMessage(messages[jid2]?.messages || [])?.timestamp || 0
    return time2 - time1
  }, [messages])

  useEffect(() => {
    setApprovedChats(getApprovedChats(chatArrayFromList(allChats)).sort(sortChats))
  }, [allChats, sortChats])

  const handleSend = () => {
    onSend?.(selectedContacts)
    hide?.()
  }

  return <BaseModalWithTitle
    title={formatMessage({id: 'CHAT.FORWARD_MESSAGE'})}
    hide={hide}
  >
    <Search wrapperClassName={styles.search} onChange={setFilter}/>
    <ContactSelect
      contacts={approvedChats}
      filter={filter}
      maxSelected={approvedChats.length}
      onSelected={setSelectedContacts}
    />
    <div className={styles.buttons}>
      <FormattedMessage id={'ACTION.SEND'}>
        {txt => <Button view={'main'} onClick={handleSend}>{txt}</Button>}
      </FormattedMessage>
      <FormattedMessage id={'ACTION.CANCEL'}>
        {txt => <Button view={'link'} onClick={hide}>{txt}</Button>}
      </FormattedMessage>
    </div>
  </BaseModalWithTitle>
}

export default Forward
