import styles from './CallDialog.module.scss'
import {BaseModal} from '@/components/Modal/BaseModal/BaseModal';
import {getChat} from '@/store/chats/chats';
import Avatar from '@/components/Avatar/Avatar';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import classNames from 'classnames';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import audioOutgoing from '@/audio/outgoing.mp3'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import audioIncoming from '@/audio/ring-basic.mp3'
import {useCallback, useEffect, useRef, useState} from 'react';
import {getChatId, getNameOrNick} from '@/utils/chats';
import callSocket from '@/services/CallSocket/CallSocket';
import {
  CallRequest,
  isAnswerCallEvent,
  isCancelCallEvent,
  OutgoingCallAnswer
} from '@/services/CallSocket/ICallEvents';
import {SocketEvents} from '@/services/Socket/ISocket';
import {useAppSelector} from '@/hooks/appHook';
import {getUser} from '@/store/user/user';
import {pushCall} from '@/api/push-app';

export interface RoomProps {
  name: string
  token: string
}

interface CallDialogProps {
  jid?: string | null
  incoming?: boolean
  token?: string
  hide?: () => void
  onCall?: (room: RoomProps) => void
  onRoomReady?: (room: RoomProps) => void
  onCancel?: () => void
}

const MAX_TIMEOUT = 35000

const CallDialog = (
  {
    jid,
    hide,
    incoming = false,
    token,
    onCall,
    onRoomReady,
    onCancel
  }: CallDialogProps) => {
  const user = useAppSelector(getUser)
  const chat = useAppSelector(getChat(jid || ''))
  const [callStarted, setCallStarted] = useState(incoming)
  const audioRef = useRef<HTMLAudioElement>(null)
  const [tokenRoom, setTokenRoom] = useState(token)
  const callImmediately = useRef(!incoming)
  const startCall = useRef<Function>()
  const cancelCallRef = useRef<() => void>()
  const timer = useRef<NodeJS.Timer>()
  const onReady = useRef<() => void>()

  onReady.current = useCallback(() => {
    onRoomReady?.({
      name: getChatId((incoming ? chat?.$jid : user?.$jid) || ''),
      token: tokenRoom || ''
    })

  }, [chat?.$jid, user?.$jid, tokenRoom, incoming, onRoomReady])

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, []);

  useEffect(() => {
    if (tokenRoom) {
      onReady.current?.()
    }
  }, [tokenRoom]);

  const cancelCall = useCallback(() => {
    setCallStarted(false)
    onCancel?.()
    hide?.()
  }, [onCancel, hide])

  const onMessage = useCallback((msg: CallRequest) => {
    if (isCancelCallEvent(msg)) {
      cancelCall()
    } else if (isAnswerCallEvent(msg)) {
      if (msg.result === 'ok') {
        onCall?.({
          name: getChatId((incoming ? chat?.$jid : user?.$jid) || ''),
          token: tokenRoom || ''
        })
      } else {
        onCancel?.()
      }
      setCallStarted(false)
      hide?.()
    }
  }, [chat?.$jid, incoming, user?.$jid, tokenRoom, onCall, cancelCall, hide, onCancel])

  useEffect(() => {
    callSocket.addEventListener(SocketEvents.onMessage, onMessage)
    return () => {
      callSocket.removeEventListener(SocketEvents.onMessage, onMessage)
    }
  }, [onMessage]);

  startCall.current = useCallback(async () => {
    if (callStarted || !chat?.$jid) {
      return
    }
    pushCall(getChatId(chat.$jid))
    const msg = callSocket.Messages.call(chat.$jid)
    try {
      const result = await callSocket.sendMessage(msg) as OutgoingCallAnswer
      setTokenRoom(result.token)
      setCallStarted(true)
      timer.current = setTimeout(() => {
        cancelCallRef.current?.()
      }, MAX_TIMEOUT)
    } catch (e) {
    }

  }, [chat, callStarted])

  useEffect(() => {
    if (callImmediately.current){
      startCall.current?.()
    }
  }, []);

  const accessCall = async () => {
    try {
      await callSocket.sendMessage(callSocket.Messages.access(chat?.$jid || ''))
      onCall?.({
        name: getChatId((incoming ? chat?.$jid : user?.$jid) || ''),
        token: tokenRoom || ''
      })
    } catch (e) {}

    hide?.()
  }

  cancelCallRef.current = useCallback(() => {
    if (!callStarted) {
      return
    }
    const cancelMethod = incoming ?
      callSocket.Messages.cancelIncoming : callSocket.Messages.cancelOutgoing
    const msg = cancelMethod(chat?.$jid || '')
    callSocket.sendMessage(msg)
    cancelCall()
  }, [callStarted, chat?.$jid, incoming, cancelCall])

  useEffect(() => {
    const audio = audioRef.current
    if (!audio) {
      return
    }
    if (callStarted) {
      audio.play()
    } else {
      audio.pause()
      audio.currentTime = 0
    }
  }, [callStarted]);

  if (!chat) {
    return null
  }

  return <BaseModal
    className={styles.wrapper}
  >
    <div className={styles.box}>
      <audio
        ref={audioRef}
        src={incoming ? audioIncoming : audioOutgoing}
        loop={true}
        autoPlay={incoming}
      />
      <div className={styles.card}>
        <Avatar
          src={chat.vcard?.url}
          name={chat.nick}
          size="big"
        />
        <p className={styles.name}>{getNameOrNick(chat)}</p>
      </div>

      <div className={styles.btnBox}>
        <IconButton
          className={classNames(styles.btn, styles.cancelBtn, incoming && styles.incoming)}
          onClick={cancelCallRef.current}
        >
          <i className={incoming ? 'chat-call-reject' : 'chat-cross'}/>
        </IconButton>
        {incoming && <IconButton
          className={classNames(styles.btn, (callStarted || incoming) && styles.accessBtn)}
          onClick={accessCall}
        ><i className="chat-call-answer"/></IconButton>}
      </div>

    </div>
  </BaseModal>
}

export default CallDialog
