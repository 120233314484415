import styles from './MarkMessage.module.scss'
import { ElementType } from 'react';
import {
  IBreakMessage,
  IDateMessage,
  IMarkMessage,
  ShowedMessage
} from '@/store/messages/messages';
import { INews } from '@/store/news/news';

interface MarkMessageProps {
  TagName?: ElementType
  message: IMarkMessage
}

export const isMarkMessage =
  (message: ShowedMessage | IBreakMessage | INews | IDateMessage | IMarkMessage): message is IMarkMessage => {
    return 'type' in message && message.type === 'mark'
  }

const MarkMessage = ({ TagName = 'div', message }: MarkMessageProps) => {
  return <TagName className={styles.markMessage}>{message.text}</TagName>
}

export default MarkMessage
