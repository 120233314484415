import { ElementType } from 'react';
import {
  IDateMessage,
} from '@/store/messages/messages';
import styles from "./DateMessage.module.scss";
import dayjs from "dayjs";

interface DateMessageProps {
  TagName?: ElementType
  message: IDateMessage
}

const DateMessage = ({ message, TagName = 'div' }: DateMessageProps) => {
  const dateFormat = dayjs(message.date);

  return <TagName className={styles.divider}>{dateFormat.format('L')}</TagName>
}

export default DateMessage;


