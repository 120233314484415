import {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useAppSelector } from '@/hooks/appHook';
import { getChat } from '@/store/chats/chats';
import { IMessage } from '@/store/messages/messages';
import useVCard from '@/hooks/useVCard';
import { getName } from '@/utils/chats';
import { getBlockList } from '@/store/blockList/blockList';
import {
  INews,
  isActiveNews,
  isNews
} from '@/store/news/news'
import { getUser } from '@/store/user/user';

type GetNamesProps = {
  message: IMessage | INews,
  incoming: boolean
  jid?: never
} | {
  jid: string
  message?: never
  incoming?: never
}

const vCardRequest: Map<string, Promise<unknown>> = new Map()

const useGetNames = ({ message, incoming, jid }: GetNamesProps) => {
  const from = !message || isNews(message) ? '' : message.from
  const forwardedFrom = !message || isNews(message) ? '' : message.forwardedFrom
  const user = useAppSelector(getUser)
  const chatByJid = useAppSelector(getChat(jid || ''))
  const chat = useAppSelector(getChat(from))
  const isActiveNewsChat = useAppSelector(isActiveNews)
  const blocklist = useAppSelector(getBlockList)
  const forwardedChat = useAppSelector(getChat(forwardedFrom || ''))
  const { getVCardWithQueue } = useVCard()

  const requestVCard = useCallback((jid: string) => {
    const promise = getVCardWithQueue({ jid, immediately: true })
    vCardRequest.set(jid, promise)
    promise.finally(() => {
      vCardRequest.delete(jid)
    })
  }, [getVCardWithQueue])

  useEffect(() => {
    if (chat || isActiveNewsChat) {
      return
    }
    const promise = vCardRequest.get(from)
    if (promise) {
      return;
    }
    requestVCard(from)
  }, [chat, from, requestVCard, isActiveNewsChat]);

  const fromName = useMemo<string>(() => {
    if (!from) {
      return ''
    }
    let name = getName(chat, !!incoming)
    if (name) {
      return name
    }
    const blockedUser = blocklist.find(user => user.jid === from)
    if (blockedUser) {
      name = blockedUser.name
    }
    return name || ''
  }, [from, blocklist, chat, incoming]);

  useEffect(() => {
    if (isActiveNewsChat) {
      return
    }
    if (!forwardedFrom || forwardedChat) {
      return;
    }
    const promise = vCardRequest.get(forwardedFrom)
    if (promise) {
      return;
    }
    requestVCard(forwardedFrom)
  }, [forwardedChat, requestVCard, forwardedFrom, isActiveNewsChat])

  const forwardedName = useMemo(() => {
    return forwardedChat?.nick || forwardedChat?.vcard?.nickname || ''
  }, [forwardedChat]);

  const nameByJid = useMemo<string>(() => {
    if (!jid) {
      return ''
    }
    if (jid === user?.$jid) {
      return user?.nickname || ''
    }
    let name = getName(chatByJid, true)
    if (name) {
      return name
    }
    const blockedUser = blocklist.find(user => user.jid === jid)
    if (blockedUser) {
      name = blockedUser.name
    }
    return name || ''
  }, [user, chatByJid, blocklist, jid]);

  useEffect(() => {
    if (!jid || chatByJid) {
      return
    }
    const promise = vCardRequest.get(jid)
    if (promise) {
      return;
    }
    requestVCard(jid)
  }, [jid, chatByJid, requestVCard]);

  return { forwardedName, fromName, nameByJid }
}

export default useGetNames
