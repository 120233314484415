import Chip from '@/components/Chip/Chip';
import {IClass} from '@/interfaces/general';
import styles from './ChipList.module.scss'
import classNames from 'classnames';

interface IChip {
  id: string
  text: string
}
interface ChipListProps extends IClass{
  chips: IChip[]
  onRemove?: (id: string) => void
}

const ChipList = (
  {
    chips,
    className,
    onRemove
  }: ChipListProps) => {

  if (!chips.length) {
    return null
  }

  return <li
    className={classNames(styles.list, className)}
  >
    {chips.map(chip =>
      <Chip
        tagName="li"
        key={chip.id}
        text={chip.text}
        onClose={() => onRemove?.(chip.id)}
      />
    )}
  </li>
}

export default ChipList
