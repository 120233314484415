import { FormattedMessage } from 'react-intl';
import BaseHint, { BaseHintProps } from '@/components/Modal/Hints/Base/BaseHint';
import Button from '@/components/Primitive/Buttons/Button/Button';
import { addShownHint } from '@/store/hints/hints';
import { saveShownHint } from '@/api/hints';
import { useAppDispatch } from '@/hooks/appHook';
import styles from './HintWithSaveBtn.module.scss'

interface HintWithSaveBtnProps extends BaseHintProps {
  hintName?: string
}

const HintWithSaveBtn = ({ hintName, children, ...props }: HintWithSaveBtnProps) => {
  const dispatch = useAppDispatch()
  const { hide } = props

  const clickHandler = () => {
    hide?.()
    if (!hintName) {
      return
    }
    dispatch(addShownHint(hintName))
    saveShownHint(hintName)
  }

  return <BaseHint {...props}>
    {children}
    <FormattedMessage id="got_it">
      {txt => <Button
        className={styles.btn}
        onClick={clickHandler}
      >
        {txt}
      </Button>}
    </FormattedMessage>
  </BaseHint>
}

export default HintWithSaveBtn
