export interface ILimitProps {
  value: number,
  min?: number,
  max?: number
}

export const limit = (
  {
    value,
    min = -Infinity,
    max = Infinity
  }: ILimitProps) => {
  return Math.min(Math.max(min, value), max)
}
