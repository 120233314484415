export const queries = {
  memberId: 'member_id',
  otp: 'otp',
  ref: 'ref',
  join: 'join',
  jid: 'jid',
  broadcast: 'broadcast',
  broadcastTitle: 'broadcastTitle',
  broadcastFrom: 'broadcastFrom'
} as const

export const broadcastServiceSet = new Set(['alogix'])
export const alogixDomains = (process.env.REACT_APP_BROADCAST_SERVICE_DOMAINS || '').split(',')

export const ID_MODAL_PORTAL = 'modal-container'
