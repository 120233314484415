import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent
} from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { IClass } from '@/interfaces/general';
import { show as showNotify } from '@/components/Modal/UserNotify/UserNotify';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import ConnectInteraction from '@/services/ConnectInteraction/ConnectInteraction';
import { TabsInteractionType } from '@/constants/tabsInteraction';
import useMoving from '@/hooks/useMoving';
import { ReactComponent as MoveIcon } from '@/images/icons/move.svg'
import styles from './Conference.module.scss'
import {
  IPoint,
  ISize
} from '@/interfaces/shapes';
import { limit } from '@/utils/math';
import {
  useAppDispatch,
  useAppSelector
} from '@/hooks/appHook';
import { getScreenSize } from '@/store/screenSize/screenSize';
import useResize from '@/hooks/useResize';
import {
  getGroupCallsMinimizePopupPositions,
  getGroupCallsPopupPositions,
  setGroupCallsMinimizePosition,
  setGroupCallsPosition
} from '@/store/popupPositions/popupPositions';

interface ConferenceProps extends IClass {
  src?: string
  style?: CSSProperties
  hide?: () => void
  onMinimize?: (minimize: boolean) => void
  onFinish?: () => void
}

const CONTAINER_PADDINGS = 20
const MIN_FRAME_SIZE = 300
const MINIMIZE_FRAME_WIDTH = 230
const MINIMIZE_FRAME_HEIGHT = 130
const INIT_FRAME_SIZE_KOEF = .7
const SAVE_POSITION_DELAY = 200

const Conference = (
  {
    className,
    style,
    src,
    hide,
    onMinimize
  }: ConferenceProps) => {
  const dispatch = useAppDispatch()
  const groupCallsPosition = useAppSelector(getGroupCallsPopupPositions)
  const groupCallsMinimizePosition = useAppSelector(getGroupCallsMinimizePopupPositions)
  const screenSize = useAppSelector(getScreenSize)
  const [minimize, setMinimize] = useState(false)
  const [prePopup, setPrePopup] = useState(true)
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const connectInteraction = useRef<ConnectInteraction>();
  if (!connectInteraction.current) {
    connectInteraction.current = new ConnectInteraction()
  }
  const containerRef = useRef<HTMLDivElement>(null)

  const { formatMessage } = useIntl()

  const limitCoords = useCallback((point: IPoint) => {
    const x = limit({
      value: point.x,
      min: CONTAINER_PADDINGS,
      max: screenSize.width - CONTAINER_PADDINGS - (containerRef.current?.clientWidth || 0)
    })
    const y = limit({
      value: point.y,
      min: CONTAINER_PADDINGS,
      max: screenSize.height - CONTAINER_PADDINGS - (containerRef.current?.clientHeight || 0)
    })
    return { x, y }
  }, [screenSize]);

  const initCallFrameSize = useMemo<ISize>(() => {
    return {
      height: groupCallsPosition?.height != null
        ? groupCallsPosition.height
        : Math.max(screenSize.height * INIT_FRAME_SIZE_KOEF, MIN_FRAME_SIZE),
      width: groupCallsPosition?.width != null
        ? groupCallsPosition.width
        : Math.max(screenSize.width * INIT_FRAME_SIZE_KOEF, MIN_FRAME_SIZE),
    }
  }, [screenSize, groupCallsPosition]);

  const initCallFrameCoords = useMemo<IPoint>(() => {
    let x = groupCallsPosition?.x
    if (x && x + initCallFrameSize.width > screenSize.width) {
      x = limit({
        value: screenSize.width - initCallFrameSize.width,
        min: 0
      })
    }
    let y = groupCallsPosition?.y
    if (y && y + initCallFrameSize.height > screenSize.height) {
      y = limit({
        value: screenSize.height - initCallFrameSize.height,
        min: 0
      })
    }
    return {
      x: x != null ? x : screenSize.width / 2 - initCallFrameSize.width / 2,
      y: y != null ? y : screenSize.height / 2 - initCallFrameSize.height / 2
    }
  }, [screenSize, initCallFrameSize, groupCallsPosition]);

  const {
    moving,
    offset,
    onStartMoving
  } = useMoving({
    limitCoords,
    reset: prePopup,
    initCoords: initCallFrameCoords
  })

  const initMinimizeCallFrameCoords = useMemo<IPoint>(() => {
    let x = groupCallsMinimizePosition?.x
    if (x && x + MINIMIZE_FRAME_WIDTH > screenSize.width) {
      x = limit({
        value: screenSize.width - MINIMIZE_FRAME_WIDTH,
        min: 0
      })
    }
    let y = groupCallsMinimizePosition?.y
    if (y && y + MINIMIZE_FRAME_HEIGHT > screenSize.height) {
      y = limit({
        value: screenSize.height - MINIMIZE_FRAME_HEIGHT,
        min: 0
      })
    }
    return {
      x: x != null ? x : screenSize.width - MINIMIZE_FRAME_WIDTH - CONTAINER_PADDINGS,
      y: y != null ? y : CONTAINER_PADDINGS
    }
  }, [screenSize, groupCallsMinimizePosition]);

  const {
    moving: minimizeMoving,
    offset: minimizeOffset,
    onStartMoving: onMinimizeStartMoving
  } = useMoving({
    limitCoords,
    reset: prePopup,
    initCoords: initMinimizeCallFrameCoords
  })

  const {
    resizing,
    size,
    onResizeStart,
  } = useResize({
    minHeight: MIN_FRAME_SIZE,
    minWidth: MIN_FRAME_SIZE,
    maxHeight: screenSize.height - CONTAINER_PADDINGS - offset.y,
    maxWidth: screenSize.width - CONTAINER_PADDINGS - offset.x,
    initHeight: initCallFrameSize.height,
    initWidth: initCallFrameSize.width,
    reset: prePopup
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setGroupCallsPosition({
        x: offset.x,
        y: offset.y,
        width: size.width,
        height: size.height
      }))
    }, SAVE_POSITION_DELAY)
    return () => {
      clearTimeout(timeout)
    }
  }, [dispatch, offset, size]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setGroupCallsMinimizePosition({
        x: minimizeOffset.x,
        y: minimizeOffset.y,
      }))
    }, SAVE_POSITION_DELAY)
    return () => {
      clearTimeout(timeout)
    }
  }, [dispatch, minimizeOffset, size]);

  useEffect(() => {
    if (!moving && !minimizeMoving && !resizing) {
      return
    }
    document.documentElement.classList.add(styles.disableSelect)
    return () => {
      document.documentElement.classList.remove(styles.disableSelect)
    }
  }, [moving, minimizeMoving, resizing]);

  useEffect(() => {
    const handler = connectInteraction.current
    return () => {
      handler?.destroy()
      connectInteraction.current = undefined
    }
  }, []);

  const onConferenceFinished = useCallback(() => {
    hide?.()
  }, [hide]);

  useEffect(() => {
    connectInteraction.current?.addEventListener(TabsInteractionType.RoomClosed, onConferenceFinished)
    return () => {
      connectInteraction.current?.removeEventListener(TabsInteractionType.RoomClosed, onConferenceFinished)
    }
  }, [onConferenceFinished]);

  const onPrePopupClosed = useCallback(() => {
    setPrePopup(false)
  }, []);

  useEffect(() => {
    connectInteraction.current?.addEventListener(TabsInteractionType.PrePopupClosed, onPrePopupClosed)
    return () => {
      connectInteraction.current?.removeEventListener(TabsInteractionType.PrePopupClosed, onPrePopupClosed)
    }
  }, [onPrePopupClosed]);

  const onRoomOverflowed = useCallback(() => {
    showNotify({
      message: formatMessage({ id: 'meeting_is_overloaded' })
    })
    hide?.()
  }, [formatMessage, hide]);

  useEffect(() => {
    connectInteraction.current?.addEventListener(TabsInteractionType.RoomOverflowed, onRoomOverflowed)
    return () => {
      connectInteraction.current?.removeEventListener(TabsInteractionType.RoomOverflowed, onRoomOverflowed)
    }
  }, [onRoomOverflowed]);

  const onError = useCallback((message: string) => {
    showNotify({
      message
    })
    hide?.()
  }, [hide])

  useEffect(() => {
    connectInteraction.current?.addEventListener(TabsInteractionType.Error, onError)
    return () => {
      connectInteraction.current?.addEventListener(TabsInteractionType.Error, onError)
    }
  }, [onError]);

  const internalStyles = useMemo<CSSProperties>(() => {
    if (prePopup) {
      return {}
    }
    const { x, y } = minimize ? minimizeOffset : offset
    const commons = {
      pointerEvents: (resizing || moving || minimizeMoving) ? 'none' : undefined,
      left: x,
      top: y,
    }

    return !minimize
      ? {
        ...commons,
        width: size.width,
        height: size.height,
      }
      : {
        ...commons,
        width: MINIMIZE_FRAME_WIDTH,
        height: MINIMIZE_FRAME_HEIGHT,
      }
  }, [minimize, offset, size, resizing, moving, minimizeMoving, prePopup, minimizeOffset]);

  const onButtonsPanelClick = (e: ReactMouseEvent | ReactTouchEvent) => {
    if (minimize) {
      onMinimizeStartMoving(e)
    } else {
      onStartMoving(e)
    }
  }

  if (!src) {
    return null
  }

  const minimizeHandler = () => {
    const isMinimize = minimize
    setMinimize(!isMinimize)
    onMinimize?.(!isMinimize)
  }

  return <div
    ref={containerRef}
    className={classNames(
      styles.box,
      prePopup && styles.prePopup,
      className,
    )}
    style={{
      ...style,
      ...internalStyles
    }}
  >
    {!prePopup && <>
      <div
        className={classNames(styles.buttons, styles.right)}
        onMouseDown={onButtonsPanelClick}
        onTouchStart={onButtonsPanelClick}
      >
        <MoveIcon />
        <IconButton
          className={styles.button}
          onClick={minimizeHandler}
          onMouseDown={e => e.stopPropagation()}
          onTouchStart={e => e.stopPropagation()}
        >
          <i className={`chat-fullscreen${!minimize ? '2' : ''}`} />
        </IconButton>
      </div>
      {!minimize && <IconButton
        className={classNames(styles.button, styles.resize)}
        onMouseDown={onResizeStart}
        onTouchStart={onResizeStart}
      />}
    </>}
    <iframe
      ref={iframeRef}
      className={classNames(
        styles.frame,
        prePopup && styles.medium
      )}
      title="Conference"
      src={src}
      allow="camera; microphone; screen-wake-lock; autoplay; display-capture"
    />
  </div>
}

export default Conference
