import {useCallback, useEffect, useRef, useState} from 'react';
import {usePrivateChatList} from '@/hooks/usePrivateChatList';
import {useMUChatList} from '@/hooks/useMUChatList';
import {useAppDispatch, useAppSelector} from '@/hooks/appHook';
import {
  ChatList,
  getMUChats,
  getPrivateChats,
  muChatsLoaded,
  privateChatsLoaded,
  setMUChats,
  setPrivateChats,
  setThreadsLoaded as setGlobalThreadsLoaded,
  updateChat,
} from '@/store/chats/chats';
import {getAllMessages, IMessages, set as setMessages} from '@/store/messages/messages'
import {getUser, setAdditionalFields} from '@/store/user/user';
import {IThreads, threads as threadsApi} from '@/api/chats';
import {getAutoDeleteMessages} from '@/api/auto-delete';
import JXON from 'jxon';
import {AnyObject} from '@/index';
import {pinList} from '@/api/pin';
import {updateTeam} from '@/api/team';
import connection from '@/services/Connection/Connection';
import {ConnectionEventNames} from '@/services/Connection/IConnection';
import { useNews } from '@/hooks/useNews'
import { getLang } from '@/store/language/language'
import { setHints } from '@/store/hints/hints';

const DELAY_BETWEEN_API = 100

const useGetChats = () => {
  const dispatch = useAppDispatch()
  const [chatLoaded, setChatLoaded] = useState(false)
  const privateChats = useAppSelector(getPrivateChats)
  const muChats = useAppSelector(getMUChats)
  const user = useAppSelector(getUser)
  const privateChatsLoadedStatus = useAppSelector(privateChatsLoaded)
  const muChatsLoadedStatus = useAppSelector(muChatsLoaded)
  const messages = useAppSelector(getAllMessages)
  const lang = useAppSelector(getLang)
  const [threadsLoaded, setThreadsLoaded] = useState(false)
  const [threads, setThreads] = useState<IThreads>()
  const [connected, setConnected] = useState(connection.isConnected)
  const readFromThread = useRef(true)
  const threadsLoading = useRef(false)

  const { updateNews } = useNews()

  useEffect(() => {
    if (!user?.token) {
      return
    }
    updateNews({
      lang
    })
  }, [updateNews, user?.token, lang]);

  useEffect(() => {
    connection.addEventListener(ConnectionEventNames.ConnectedChanged, setConnected)
    setConnected(connection.isConnected)
    return () => {
      connection.removeEventListener(ConnectionEventNames.ConnectedChanged, setConnected)
    }
  }, []);

  const {update: privateListUpdate} = usePrivateChatList({watch: true})
  const {update: muListUpdate} = useMUChatList()

  const updatePrivateChats = useCallback((threads: IThreads) => {
    const pChats: ChatList = {}
    threads?.contacts.forEach((
      {
        jid,
        ask,
        name,
        nick,
        resources,
        vcard,
      }) => {
      if (privateChats[jid]) {
        pChats[jid] = {
          ...privateChats[jid],
          ask,
          name,
          nick,
          resources,
          vcard,
        }
      }
    })
    const chats: ChatList = {};
    (threads.notifyMute || []).forEach(jid => {
      if (pChats[jid]) {
        pChats[jid].notifyMuted = true
      } else if (privateChats[jid]) {
        chats[jid] = {
          ...privateChats[jid],
          notifyMuted: true,
        }
      }
    })
    dispatch(setPrivateChats({
      ...privateChats,
      ...chats,
      ...pChats,
    }))
  }, [dispatch, privateChats])

  const updateMUChats = useCallback((threads: IThreads) => {
    const mChats: ChatList = {}
    threads.rooms.items.forEach(({room, vcard, mute, groups, groupCallStart}) => {
      const jid = room + '@conference.' + process.env.REACT_APP_EJ_HOST
      if (muChats[jid]) {
        const {vCard: card}: AnyObject = JXON.stringToJs(vcard)
        mChats[jid] = {
          ...muChats[jid],
          name: typeof card.NICKNAME === 'string' ? card.NICKNAME : '',
          groups: groups.filter(item => item.trim() !== ''),
          vcard: {
            nickname: typeof card.NICKNAME === 'string' ? card.NICKNAME : '',
            url: card.URL,
            thumbnail: card.THUMBNAIL,
            jabberId: card.JABBERID,
          },
          muted: mute,
          groupCallStart
        }
      }
    })
    const chats: ChatList = {};
    (threads.notifyMute || []).forEach(jid => {
      if (mChats[jid]) {
        mChats[jid].notifyMuted = true
      } else if (muChats[jid]) {
        chats[jid] = {
          ...muChats[jid],
          notifyMuted: true,
        }
      }
    })
    dispatch(setMUChats({
      ...muChats,
      ...chats,
      ...mChats,
    }))
  }, [dispatch, muChats])

  const updateMessages = useCallback((threads: IThreads) => {
    const mess: IMessages = {
      messages: {},
    }
    const reg = new RegExp(`@conference.${process.env.REACT_APP_EJ_HOST}$`)
    threads.messages.items?.forEach(message => {
      if (!mess.messages[message.peer]) {
        mess.messages[message.peer] = {
          messages: [],
          isFullLoaded: false,
          isLoaded: false,
          replyMessage: null,
        }
      }
      const status = reg.test(message.peer) || message.state === 'seen' ? 'displayed' : 'received'
      mess.messages[message.peer].messages
        .push({
          ...message,
          id: message.uid,
          status,
        })
    })
    dispatch(setMessages(mess))
    dispatch(setGlobalThreadsLoaded('loaded'))
  }, [dispatch])

  const updateUser = useCallback((threads: IThreads) => {
    dispatch(setAdditionalFields(threads.vcard))
  }, [dispatch])

  const updateHints = useCallback((threads: IThreads) => {
    dispatch(setHints({
      initialHints: threads.hints.initialHints || [],
      shownHints: threads.hints.shownHints || [],
    }))
  }, [dispatch])

  const autoDeleteMessages = useCallback(() => {
    getAutoDeleteMessages().then((res) => {
      if (res.list.length > 0) {
        res.list.map((val: any) => {
          dispatch(updateChat({
            chatJid: val.chat,
            options: {
              autoDeleteMessages: {
                period: val.period,
                typePeriod: val.typePeriod,
                mine: val.mine === 'true',
                visible: val.visible === 'true',
              },
            },
          }))

          return val
        })
      }
    })
  }, [dispatch])

  const getPinMessages = useCallback(() => {
    pinList().then((res) => {
      if (res.list.length > 0) {
        res.list.map((val: any) => {
          dispatch(updateChat({
            chatJid: val.chat,
            options: {
              pin: {
                uid: val.uid,
                text: val.pinText,
                type: val.pinType,
                timestamp: val.timestamp,
                visible: val.visible === 'true',
              },
            },
          }))

          return val
        });
      }
    })
  }, [dispatch])

  const updateChats = useCallback(() => {
    if (threads && readFromThread.current) {
      updateUser(threads)
      updatePrivateChats(threads)
      updateMUChats(threads)
      updateMessages(threads)
      updateHints(threads)
      autoDeleteMessages()
      getPinMessages()
      readFromThread.current = false
    }
  }, [
    threads,
    updatePrivateChats,
    updateMUChats,
    updateMessages,
    updateUser,
    autoDeleteMessages,
    getPinMessages,
    updateHints
  ])

  useEffect(() => {
    if (chatLoaded || !user?.token || threadsLoading.current || threadsLoaded) {
      return
    }
    threadsLoading.current = true
    threadsApi()
      .then(data => {
        setThreads(data)
        setThreadsLoaded(true)
      })
      .finally(() => {
        threadsLoading.current = false
      })
    setTimeout(updateTeam, DELAY_BETWEEN_API)
  }, [chatLoaded, user?.token, threadsLoaded])

  useEffect(() => {
    if (threadsLoaded
      && privateChatsLoadedStatus === 'loaded'
      && muChatsLoadedStatus === 'loaded'
    ) {
      updateChats()
    }
  }, [threadsLoaded, privateChatsLoadedStatus, muChatsLoadedStatus])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!connected || !user?.memberId) {
      return
    }
    const jid = `${user.memberId}@${process.env.REACT_APP_EJ_HOST}`
    if (privateChatsLoadedStatus === 'unload') {
      privateListUpdate(jid)
    }
    if (muChatsLoadedStatus === 'unload') {
      muListUpdate(jid)
    }
  }, [
    connected,
    privateListUpdate,
    muListUpdate,
    privateChatsLoadedStatus,
    muChatsLoadedStatus,
    user?.memberId,
  ])

  useEffect(() => {
    setChatLoaded(
      privateChatsLoadedStatus === 'loaded'
      && muChatsLoadedStatus === 'loaded'
      && Object.keys(messages).length > 0,
    )
  }, [privateChatsLoadedStatus, muChatsLoadedStatus, messages])

  return [chatLoaded]
}

export default useGetChats
