import {
  CSSProperties,
  LegacyRef,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import { IClass } from '@/interfaces/general';
import styles from './BaseHint.module.scss'
import { useAppSelector } from '@/hooks/appHook';
import { getScreenHeight } from '@/store/screenSize/screenSize';
import { ID_MODAL_PORTAL } from '@/constants/general';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import { Property } from 'csstype';
import {
  offsetLeft,
  offsetTop
} from '@/utils/layout';

const DEFAULT_MARGIN = 10
const TRIANGLE_WIDTH = 20
const BORDER_RADIUS = 24

export const HintPosition = {
  top: 'top',
  left: 'left',
  right: 'right',
  bottom: 'bottom'
} as const

export type TPosition = typeof HintPosition[keyof typeof HintPosition]

interface ICoord {
  top: Property.Top<number>,
  left: Property.Left<number>
}

interface CheckLimitCoordsProps {
  container: Element,
  element?: Element,
  coords: {
    top: number,
    left: number
  },
  margin?: number
}

const checkLimitCoords = ({
  container,
  element,
  coords,
  margin = DEFAULT_MARGIN
}: CheckLimitCoordsProps): ICoord => {
  const newCoords = coords
  if (!element) {
    return newCoords
  }
  if (container.clientHeight - margin < coords.top + element.clientHeight) {
    newCoords.top = container.clientHeight - margin - element.clientHeight
  }
  if (container.clientWidth - margin < coords.left + element.clientWidth) {
    newCoords.left = container.clientWidth - margin - element.clientWidth
  }
  if (newCoords.left < margin) {
    newCoords.left = margin
  }
  if (newCoords.top < margin) {
    newCoords.top = margin
  }
  return newCoords
}

interface ISize {
  width: number
  height: number
}

export interface BaseHintProps extends PropsWithChildren, IClass {
  hide?: () => void,
  style?: CSSProperties
  target?: Element | null,
  container?: Element,
  position?: TPosition
  margin?: number
}

const BaseHint = (
  {
    target,
    className,
    style,
    children,
    container = document.body,
    margin = DEFAULT_MARGIN,
    position = HintPosition.right,
    hide,
  }: BaseHintProps) => {
  const screenHeight = useAppSelector(getScreenHeight)
  const [visible, setVisible] = useState(false)
  const [coords, setCoords] = useState<ICoord>({ top: 0, left: 0 })
  const containerElement = useMemo(
    () => document.getElementById(ID_MODAL_PORTAL),
    [],
  );
  const hintRef = useRef<HTMLDivElement>();
  const hintSize = useRef<ISize>({ width: 0, height: 0 })
  const changeCoordsRef = useRef<() => void>()
  const resizeObserver = useRef<ResizeObserver>()

  if (!containerElement) {
    throw new Error(`Portal "${ID_MODAL_PORTAL}" not found`)
  }

  if (!resizeObserver.current) {
    resizeObserver.current = new ResizeObserver((entries) => {
      const target = entries[0].target
      hintSize.current = {
        height: target.clientHeight,
        width: target.clientWidth
      }
      if (hintSize.current.width === 0 || hintSize.current.height === 0) {
        return
      }
      changeCoordsRef.current?.()
    })
  }

  const changeCoords = useCallback(() => {
    if (!target) {
      setCoords({
        left: `calc(50% - ${hintSize.current.width / 2}px)`,
        top: `calc(50% - ${hintSize.current.height / 2}px)`,
      })
      return
    }
    let left: number
    let top: number
    switch (position) {
      case HintPosition.top:
        left = offsetLeft(target) + target.clientWidth / 2 - hintSize.current.width / 2
        top = offsetTop(target) - hintSize.current.height - margin
        break
      case HintPosition.bottom:
        left = offsetLeft(target) + target.clientWidth / 2 - hintSize.current.width / 2
        top = offsetTop(target) + target.clientHeight + margin
        break
      case HintPosition.left:
        left = offsetLeft(target) - hintSize.current.width - margin
        top = offsetTop(target) + target.clientHeight / 2 - hintSize.current.width / 2
        break
      case HintPosition.right:
      default:
        left = offsetLeft(target) + target.clientWidth + margin
        top = offsetTop(target) + target.clientHeight / 2 - hintSize.current.width / 2
    }

    setCoords(checkLimitCoords({
      container: container,
      element: hintRef.current,
      coords: { left, top }
    }))
  }, [target, margin, position, container]);

  changeCoordsRef.current = changeCoords

  const pointerCoords = useMemo<ICoord | undefined>(
    () => {
      if (!target ||
        typeof coords.top !== 'number' ||
        typeof coords.left !== 'number'
      ) {
        return
      }
      let left: Property.Left<number>
      let top: Property.Top<number>
      switch (position) {
        case HintPosition.top:
          left = offsetLeft(target) - coords.left + target.clientWidth / 2 - TRIANGLE_WIDTH / 2
          top = '100%'
          break
        case HintPosition.bottom:
          left = offsetLeft(target) - coords.left + target.clientWidth / 2 - TRIANGLE_WIDTH / 2
          top = 0
          break
        case HintPosition.left:
          left = '100%'
          top = offsetTop(target) - coords.top + target.clientHeight / 2 - TRIANGLE_WIDTH / 2
          break
        case HintPosition.right:
        default:
          left = 0
          top = offsetTop(target) - coords.top + target.clientHeight / 2 - TRIANGLE_WIDTH / 2
      }
      const height = hintRef.current?.clientHeight || Infinity
      const width = hintRef.current?.clientWidth || Infinity
      if (typeof left === 'number' && (position === HintPosition.top || position === HintPosition.bottom)) {
        if (left + TRIANGLE_WIDTH > width - BORDER_RADIUS) {
          left = width - TRIANGLE_WIDTH - BORDER_RADIUS
        }
        if (left < BORDER_RADIUS) {
          left = BORDER_RADIUS
        }
      }
      if (typeof top === 'number' && (position === HintPosition.left || position === HintPosition.right)) {
        if (top + TRIANGLE_WIDTH > height - BORDER_RADIUS) {
          top = height - TRIANGLE_WIDTH - BORDER_RADIUS
        }
        if (top < BORDER_RADIUS) {
          top = BORDER_RADIUS
        }
      }
      return {left, top}
    },
    [position, target, coords]
  )

  const ref: LegacyRef<HTMLDivElement> = (el) => {
    if (!el || visible) {
      return
    }
    hintRef.current = el
    resizeObserver.current?.observe(el)
    hintSize.current = {
      width: el.clientWidth,
      height: el.clientHeight
    }
    changeCoords()
    setVisible(true)
  }

  return ReactDOM.createPortal((
    <div
      className={styles.wrapper}
      style={{
        opacity: !visible ? 0 : undefined
      }}
    >
      <div
        ref={ref}
        style={{
          maxHeight: screenHeight - 180,
          minHeight: 2 * BORDER_RADIUS + TRIANGLE_WIDTH,
          minWidth: 2 * BORDER_RADIUS + TRIANGLE_WIDTH,
          ...style,
          borderRadius: BORDER_RADIUS,
          left: visible ? coords.left : 0,
          top: visible ? coords.top : 0,
          zIndex: style?.zIndex || 100
        }}
        className={classNames(styles.body, className)}
      >
        <IconButton
          className={styles.closeBtn}
          onClick={hide}
        ><i className="chat-cross" /></IconButton>
        <div
          className={classNames(styles.triangle, styles[position])}
          style={{
            ...pointerCoords,
            borderWidth: TRIANGLE_WIDTH / 2
          }}
        />
        {children}
      </div>
    </div>
  ), containerElement)
}

export default BaseHint
