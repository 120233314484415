export const TabsInteractionType = {
  RoomClosed: 'RoomClosed',
  AliasRequest: 'AliasRequest',
  AliasResponse: 'AliasResponse',
  GroupDataRequest: 'GroupDataRequest',
  GroupDataResponse: 'GroupDataResponse',
  PrePopupClosed: 'PrePopupClosed',
  RoomOverflowed: 'RoomOverflowed',
  LanguageRequest: 'LanguageRequest',
  LanguageResponse: 'LanguageResponse',
  Error: 'Error',
  AlogixMessage: 'AlogixMessage'
} as const
