import {
  useCallback,
  useEffect,
  useRef
} from 'react';
import callSocket from '@/services/CallSocket/CallSocket';
import {
  useAppDispatch,
  useAppSelector
} from '@/hooks/appHook';
import {
  getUser,
} from '@/store/user/user';
import { SocketEvents } from '@/services/Socket/ISocket';
import useUtils from '@/hooks/useUtils';
import {
  CallRequest,
  isGroupCallEvent
} from '@/services/CallSocket/ICallEvents';
import { updateChat } from '@/store/chats/chats';

const DELAY_RECONNECT = [3000, 3000, 3000, 10000, 30000, 60000]

const useCallSocketHandler = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const closedNormally = useRef(false)
  const openSocket = useRef<() => void>()
  const delayTimer = useRef<NodeJS.Timer>()
  const connectionAttempts = useRef(0)
  const socketOpening = useRef(false)
  const { authenticate } = useUtils()

  openSocket.current = useCallback(() => {
    if (!user?.token || socketOpening.current) {
      return
    }
    socketOpening.current = true
    closedNormally.current = false;
    callSocket.open(user.token)
      .then(() => {
        connectionAttempts.current = 0
      })
      .catch(() => {
        clearTimeout(delayTimer.current)
        delayTimer.current = setTimeout(async() => {
          try {
            await authenticate({
              memberId: user.memberId,
              accessToken: user.accessToken,
            })
          } catch (e) {
            openSocket.current?.()
          }
        }, DELAY_RECONNECT[Math.min(connectionAttempts.current, DELAY_RECONNECT.length - 1)])
        connectionAttempts.current++
      })
      .finally(() => {
        socketOpening.current = false
      })
  }, [user, authenticate])

  const onClose = useCallback(() => {
    if (closedNormally.current) {
      return
    }
    openSocket.current?.()
  }, [])

  useEffect(() => {
    callSocket.addEventListener(SocketEvents.onClose, onClose)
    return () => {
      callSocket.removeEventListener(SocketEvents.onClose, onClose)
    }
  }, [onClose]);

  const onMessage = useCallback((msg: CallRequest) => {
    if (isGroupCallEvent(msg)) {
      dispatch(updateChat({
        chatJid: msg.jid,
        options: {
          groupCallStart: msg.result === 'started'
        }
      }))
    }
  }, [dispatch]);

  useEffect(() => {
    callSocket.addEventListener(SocketEvents.onMessage, onMessage)
    return () => {
      callSocket.removeEventListener(SocketEvents.onMessage, onMessage)
    }
  }, [onMessage]);

  useEffect(() => {
    let tokenChanged = false
    callSocket.close()
      .finally(() => {
        if (tokenChanged) {
          return
        }
        openSocket.current?.()
      })
    return () => {
      tokenChanged = true
      closedNormally.current = true
      clearTimeout(delayTimer.current)
      callSocket.close()
    }
  }, [user?.token]);
}

export default useCallSocketHandler
