import {
  createAsyncThunk,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { localStorageFields } from '@/constants/localStorage';

interface CommonPosition {
  x: number | undefined
  y: number | undefined
  width: number | undefined
  height: number | undefined
}

const groupCallsInitPosition: typeof initialState.groupCalls = JSON.parse(
  '' + localStorage.getItem(localStorageFields.groupCallsPopupPosition)
) || undefined

const groupCallsMinimizeInitPosition: typeof initialState.minimizeGroupCalls = JSON.parse(
  '' + localStorage.getItem(localStorageFields.groupCallsMinimizePopupPosition)
) || undefined

interface PopupPositionsState {
  groupCalls: CommonPosition | undefined
  minimizeGroupCalls: CommonPosition | undefined
}

const savePosition = (key: string, position: PopupPositionsState[keyof PopupPositionsState]) => {
  localStorage.setItem(key, JSON.stringify(position))
}

const fieldNameMap: Record<keyof PopupPositionsState, string> = {
  groupCalls: localStorageFields.groupCallsPopupPosition,
  minimizeGroupCalls: localStorageFields.groupCallsMinimizePopupPosition
}

const initialState: PopupPositionsState = {
  groupCalls: groupCallsInitPosition,
  minimizeGroupCalls: groupCallsMinimizeInitPosition
}

const initCommonPosition = {
  x: undefined,
  y: undefined,
  width: undefined,
  height: undefined
}

interface SetPositionAction<T extends keyof PopupPositionsState = keyof PopupPositionsState> {
  key: T,
  position: Partial<PopupPositionsState[T]>
}

const popupPositions = createSlice({
  name: 'popupPositions',
  initialState,
  reducers: {
    setPosition(state, { payload }: PayloadAction<SetPositionAction>) {
      const { key, position } = payload
      state[key] = {
        ...initCommonPosition,
        ...state[key],
        ...position
      }
      savePosition(fieldNameMap[key], state[key])
    },
    setGroupCallsPosition(state, { payload }: PayloadAction<Partial<typeof initialState.groupCalls>>) {
      if (!state.groupCalls) {
        state.groupCalls = initCommonPosition
      }
      state.groupCalls = {
        ...state.groupCalls,
        ...payload
      }
      savePosition(localStorageFields.groupCallsPopupPosition, state.groupCalls)
    },
    clearPosition(state, { payload }: PayloadAction<keyof PopupPositionsState>) {
      state[payload] = undefined
      savePosition(fieldNameMap[payload], undefined)
    },
    clearGroupCallsPosition(state) {
      state.groupCalls = undefined
      savePosition(localStorageFields.groupCallsPopupPosition, undefined)
    }
  }
})

interface IThunkPositionStore {
  state: { popupPositions: PopupPositionsState }
}

// private actions
const {
  setPosition,
  clearPosition
} = popupPositions.actions

export const setGroupCallsPosition = createAsyncThunk<
  void,
  Partial<typeof initialState.groupCalls>,
  IThunkPositionStore
>(
  'popupPositions/setGroupCallsPosition',
  (position, { dispatch }) => {
    dispatch(setPosition({
      key: 'groupCalls',
      position
    }))
  }
)

export const clearGroupCallsPosition = createAsyncThunk<
  void,
  void,
  IThunkPositionStore
>(
  'popupPositions/clearGroupCallsPosition',
  (_, { dispatch }) => {
    dispatch(clearPosition('groupCalls'))
  }
)

export const setGroupCallsMinimizePosition = createAsyncThunk<
  void,
  Partial<typeof initialState.minimizeGroupCalls>,
  IThunkPositionStore
>(
  'popupPositions/setGroupCallsPosition',
  (position, { dispatch }) => {
    dispatch(setPosition({
      key: 'minimizeGroupCalls',
      position
    }))
  }
)

export const clearGroupCallsMinimizePosition = createAsyncThunk<
  void,
  void,
  IThunkPositionStore
>(
  'popupPositions/clearGroupCallsPosition',
  (_, { dispatch }) => {
    dispatch(clearPosition('minimizeGroupCalls'))
  }
)

export const getGroupCallsPopupPositions = (state: RootState) => state.popupPositions.groupCalls
export const getGroupCallsMinimizePopupPositions = (state: RootState) => state.popupPositions.minimizeGroupCalls
export default popupPositions.reducer
