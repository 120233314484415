import request from "./request";
import { IInitUser } from '@/store/user/user';
import { StatusError } from '@/api/interfaces';
import { AxiosResponse } from 'axios';

interface ILoginData {
  captcha: string,
  memberId: string,
  password: string,
  token: string
}

export const login = (userData: ILoginData): Promise<IInitUser | StatusError> => {
  return request('post', '/api/users/login', userData)
    .then(({ data }) => data)
}

interface ISsoData {
  memberId: string,
  otp: string
}

export const loginBySso = (data: ISsoData): Promise<IInitUser | StatusError> => {
  return request('post', '/api/users/login-by-otp', {
    ...data
  })
    .then(({ data }) => data)
}

export interface IAuthData {
  accessToken: string,
  memberId: string,
}

export const AUTHENTICATE_PATH = '/api/users/authenticate'

interface IAuthResponse extends Omit<IInitUser, 'accessToken'>{
  token: string
}

export const authenticate = async (authData: IAuthData): Promise<AxiosResponse<IAuthResponse>> => {
  return  request('post', AUTHENTICATE_PATH, authData)
}

interface CaptchaResponse {
  data: string,
  token: string
}

export const getCaptcha = (): Promise<CaptchaResponse> => {
  return request('post', '/api/users/captcha')
    .then(data => {
      return data.data
    })
}

interface ILoginAsMember {
  memberId: string
}

export const loginAsMember = ({ memberId }: ILoginAsMember): Promise<IInitUser> => {
  return request('post', '/api/users/login-as-member', { memberId })
    .then(data => {
      return data.data
    })
}
