import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '@/store';
import { localStorageFields } from '@/constants/localStorage';

const FIELD_NAME = localStorageFields.deviceSettings

interface ISettings {
  fontScale: number
}

export const MIN_FONT_SCALE = 1
export const MAX_FONT_SCALE = 4
export const STEP_FONT_SCALE = 1

const scaleMap: Record<number, number> = {
  1: 1,
  2: 1.1,
  3: 1.2,
  4: 1.3
}

const changeCssFontScale = (value: number) => {
  const mapValue = scaleMap[value] || 1
  document.documentElement.style.setProperty('--font-scale', '' + mapValue)
}

const defaultSettings = JSON.parse('' + localStorage.getItem(FIELD_NAME)) as ISettings | null

const initialState: ISettings = defaultSettings ? {
  ...defaultSettings,
} : {
  fontScale: 1,
}

changeCssFontScale(initialState.fontScale)

const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setFontScale(state, {payload}: { payload: number }) {
      if (payload > MAX_FONT_SCALE || payload < MIN_FONT_SCALE) {
        return
      }
      state.fontScale = payload
      localStorage.setItem(FIELD_NAME, JSON.stringify(state))
      changeCssFontScale(payload)
    },
  },
})


export const {setFontScale} = settings.actions
export const getFontScale = (state: RootState) => state.settings.fontScale
export default settings.reducer
