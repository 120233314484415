import {
  useCallback,
  useEffect,
  useRef,
  useState,
  ComponentProps,
  createContext,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { v4 as uuid } from 'uuid';
import { useIntl } from 'react-intl';
import * as linkify from 'linkifyjs';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { createPubSub } from '@inficen/react-events';
import {
  getMessages,
  IMessage,
  ITextMessage,
  messagesWasFullLoaded,
  setReply,
  update,
  remove,
  IDateMessage,
  ShowedMessage,
  updateWithBreak,
  isBreakMessage,
  isMessage,
  isDateMessage,
  isMessageOrNews,
  IMarkMessage,
} from '@/store/messages/messages'
import { getMessages as getHistoryMessages } from '@/api/chats'
import {
  useAppDispatch,
  useAppSelector
} from '@/hooks/appHook';
import {
  Chat,
  getActiveChatId,
  getChat,
  IGroupChatMember,
  updateChat
} from '@/store/chats/chats';
import { getUser } from '@/store/user/user';
import {
  checkAdmin,
  checkOwner,
  getChatId
} from '@/utils/chats';
import { fromHistoryToMessage } from '@/utils/messages';
import useContextMenuList from '@/components/ContextMenu/ContextMenuList/useContextMenuList';
import ContextMenuList, {
  ContextMenuProps,
  IContextMenuList
} from '@/components/ContextMenu/ContextMenuList/ContextMenuList';
import Forward from '@/components/Modal/ForwardModal/Forward';
import useMessage from '@/hooks/useMessage';
import Confirm from '@/components/Modal/Confirm/Confirm';
import { translation } from '@/api/translation';
import {
  removeFromChat,
  removeFromGroup
} from '@/api/message';
import { getLang } from '@/store/language/language';
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton';
import {
  isFileMessage,
  loadFileNotify
} from '../ChatMessage/FileMessage/FileMessage';
import useModalSimple from '@/hooks/useModalSimple';
import { getOrCreateArray } from '@/utils/array';
import { ErrorFactory } from '@/services/errorFactory';
import { ReactComponent as TranslateImage } from '@/images/icons/translate.svg';
import {
  pin,
  unPin
} from '@/api/pin';
import { copyTextToClipboard } from '@/utils/app';
import {
  getActiveNewsChat,
  INews,
  isNews
} from '@/store/news/news'
import ChatMessage, { ModifiedTouchEvent } from '../ChatMessage/ChatMessage';
import messageStyles from '../ChatMessage/ChatMessage.module.scss'
import { isTextMessage } from '../ChatMessage/TextMessage/TextMessage';
import DateMessage from '../ChatMessage/DateMessage/DateMessage';
import BreakMessage from '../ChatMessage/BreakMessage/BreakMessage.';
import {
  isAudioMessage,
  loadAudioNotify
} from '../ChatMessage/AudioMessage/AudioMessage';
import {
  isVideoMessage,
  loadVideoNotify
} from '../ChatMessage/VideoMessage/VideoMessage';
import { isCallMessage } from '../ChatMessage/CallMessage/CallMessage'
import MarkMessage, { isMarkMessage } from '../ChatMessage/MarkMessage/MarkMessage';
import { isSystemMessage } from '../ChatMessage/SystemMessage/SystemMessage';
import useMessagesScroll, { IOnScrollProps } from './hooks/useMessagesScroll';
import styles from './ChatMessages.module.scss'

const FIND_MESSAGE_TIMER = 5000
const LOAD_AFTER_FINDING_LIMIT = 15
const HIGHLIGHT_MESSAGE_PADDING = 2

type Event = {
  name: string,
  payload: {
    message: IMessage
  }
}

const { publish, useSubscribe } = createPubSub<Event>()

const FORWARD_EVENT = 'forwardMessage'
const NAME_EVENT = 'CHAT_MESSAGES'

interface ForwardMessageProps {
  message: IMessage
}

export const forwardMessage = ({ message }: ForwardMessageProps) => {
  const nameEvent: string = `${NAME_EVENT}/${FORWARD_EVENT}`
  publish(nameEvent, { message })
}

const LOAD_MORE_COUNT = 10

const ACTIONS = {
  DELETE: 'delete',
  FORWARD: 'forward',
  REPLY: 'reply',
  TRANSLATION: 'translation',
  LOAD: 'load',
  PIN: 'pin',
  UNPIN: 'unpin',
  COPY_TEXT: 'copyText',
  COPY_LINK: 'copyLink',
}

const contextMenuList: {
  list: IContextMenuList[]
} = {
  list: [
    { text: 'message_reply', action: ACTIONS.REPLY, icon: 'chat-reply' },
    { text: 'CHAT.FORWARD_MESSAGE', action: ACTIONS.FORWARD, icon: 'chat-forward' },
  ],
}

interface ChatMessagesProps {
  className?: string
}

interface FindMessage {
  messageId: string
  timerId: NodeJS.Timer
  resolve: () => void
}

export const ChatMessagesContext = createContext({
  disableLoading: false,
  onReplyClick: (msg: IMessage) => Promise.resolve(),
})

const highlightMessage = (message?: HTMLElement) => {
  if (!message) {
    return
  }
  const box = document.documentElement.querySelector('.' + styles.messages)
  if (!box) {
    return;
  }
  const oldAnimationElement = message.querySelector('.' + styles.highlight)
  oldAnimationElement?.remove()
  const div = document.createElement('div')
  div.classList.add(styles.highlight)
  div.style.height = message.offsetHeight + HIGHLIGHT_MESSAGE_PADDING * 2 + 'px'
  div.style.width = box.clientWidth - 20 + 'px'
  div.style.top = -HIGHLIGHT_MESSAGE_PADDING + 'px'
  if (message.classList.contains(messageStyles.incoming)) {
    div.style.left = -10 + 'px'
  } else {
    div.style.right = -20 - HIGHLIGHT_MESSAGE_PADDING + 'px'
  }
  message.classList.add(styles.highlightMessage)
  message.insertAdjacentElement('afterbegin', div)

  const disableAnimation = () => {
    div.remove()
    message.classList.remove(styles.highlightMessage)
  }
  div.addEventListener('animationend', disableAnimation)
}

export interface IChatMessages {
  replyClick: (msg: IMessage) => void
}

const ChatMessages = forwardRef<IChatMessages, ChatMessagesProps>(({ className }: ChatMessagesProps, ref) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const activeChatJid = useAppSelector(getActiveChatId) || ''
  const activeChat = useAppSelector(getChat(activeChatJid))
  const newsChat = useAppSelector(getActiveNewsChat)
  const messages = useAppSelector(getMessages(activeChatJid))
  const [chatMessages, setChatMessages] = useState<(ShowedMessage | INews)[]>([])
  const [safeUpdatedMessages, setSafeUpdatedMessages] =
    useState<(ShowedMessage | IDateMessage | INews | IMarkMessage)[]>([])
  const [firstNewMessageJid, setFirstNewMessageJid] = useState<string>()
  const [forwardedMessage, setForwardedMessage] = useState<IMessage | null>(null)
  const [confirmProps, setConfirmProps] = useState<ComponentProps<typeof Confirm>>({})
  const [disableLoading, setDisableLoading] = useState(false)
  const mainBox = useRef<HTMLDivElement>(null);
  const scrollBarRef = useRef<Scrollbars | null>(null)
  const messagesRef = useRef<(HTMLElement | null)[]>([])
  const messageListRef = useRef<HTMLUListElement | null>(null)
  const messagesLoading = useRef<Set<string>>(new Set())
  const findMessage = useRef<FindMessage>()
  const { formatMessage } = useIntl()
  const { forwardMessage } = useMessage()
  const lang = useAppSelector(getLang)
  const [members, setMembers] = useState(getOrCreateArray<IGroupChatMember>(activeChat?.members))
  const [isOwner, setIsOwner] = useState(checkOwner(user?.$jid || '', members))
  const [isAdmin, setIsAdmin] = useState(checkAdmin(user?.$jid || '', members))
  const lastChatForNewMessagesCheck = useRef<{ jid: string | undefined, updated: boolean }>({
    jid: undefined,
    updated: false
  });
  const updateSafeMessages = useRef<() => void>()
  const loadMore = useRef<() => void>()

  loadMore.current = useCallback(() => {
    if (messagesLoading.current.has(activeChatJid)
      || messages?.isFullLoaded
    ) {
      return
    }
    messagesLoading.current.add(activeChatJid)

    const firstMessage = messages?.messages.find(isMessage)

    getHistoryMessages({
      id: getChatId(activeChatJid),
      isRoom: activeChat?.type === 'groupchat',
      end: firstMessage?.timestamp,
      limit: LOAD_MORE_COUNT,
    })
      .then(data => {
        dispatch(update({
          jid: activeChatJid,
          messages: data.map(item => fromHistoryToMessage(item)),
        }))
        if (data.length < LOAD_MORE_COUNT) {
          dispatch(messagesWasFullLoaded({
              jid: activeChatJid,
              value: true,
            },
          ))
        }

      })
      .finally(() => {
        messagesLoading.current.delete(activeChatJid)
      })
  }, [dispatch, activeChatJid, messages, activeChat?.type])

  const onScrollEvent = useCallback(({ up }: IOnScrollProps) => {
    if (newsChat || !messages?.isLoaded) {
      return
    }

    if (up && activeChat?.$subscription !== 'none') {
      loadMore.current?.()
    }
  }, [activeChat?.$subscription, messages?.isLoaded, newsChat])

  useEffect(() => {
    if (newsChat || !messages?.isLoaded) {
      return
    }
    if ((mainBox.current?.clientHeight || 0) >= (messageListRef.current?.scrollHeight || 0)) {
      loadMore.current?.()
    }
  }, [newsChat, messages, safeUpdatedMessages]);

  const {
    onScroll,
    onScrollStart,
    onScrollStop,
    scrollButtonVisible,
    scrollToBottomAndReadMessages,
    isScrollingNow
  } = useMessagesScroll({
    messages: safeUpdatedMessages,
    messageListRef: messageListRef.current,
    messagesRef: messagesRef.current,
    mainBox: mainBox.current,
    scrollBarRef: scrollBarRef.current,
    onScrollEvent
  })

  updateSafeMessages.current = () => {
    if (isScrollingNow) {
      return
    }
    if (firstNewMessageJid) {
      const index = chatMessages
        .findIndex(message => isMessageOrNews(message) && message.id === firstNewMessageJid)
      if (~index) {
        const messages: (ShowedMessage | IDateMessage | INews | IMarkMessage)[] = [...chatMessages]
        messages.splice(index, 0, {
          id: uuid(),
          type: 'mark',
          text: formatMessage({ id: 'unread_messages' })
        })
        setSafeUpdatedMessages(messages)
        lastChatForNewMessagesCheck.current.jid = activeChatJid
        return;
      }
    }
    if (safeUpdatedMessages === chatMessages) {
      return;
    }
    lastChatForNewMessagesCheck.current.jid = activeChatJid
    setSafeUpdatedMessages(chatMessages)
  }

  useEffect(() => {
    updateSafeMessages.current?.()
  }, [chatMessages, firstNewMessageJid, isScrollingNow, formatMessage])

  const handleForwardMessage = useCallback((chats: Chat[]) => {
    if (forwardedMessage) {
      forwardMessage(forwardedMessage, chats)
    }
  }, [forwardedMessage, forwardMessage])

  const onCloseForwardModal = useCallback(() => {
    setForwardedMessage(null)
  }, [])

  const { show: showForwardModal, hide: hideForwardModal, visible: visibleForwardModal } = useModalSimple({
    onHide: onCloseForwardModal,
  })
  const { show: showConfirmModal, hide: hideConfirmModal, visible: visibleConfirmModal } = useModalSimple()

  const isMineMessage = (msg: IMessage) => {
    return msg.from === user?.$jid
  }

  const isGroupChat = () => {
    return activeChat?.type === 'groupchat'
  }

  const isPrivateChat = () => {
    return activeChat?.type === 'chat'
  }

  const getConfirmTitle = () => {
    if (isGroupChat()) {
      return 'OK'
    }
    return formatMessage({ id: 'CHAT.DELETE_MESSAGE_FOR_ME' })
  }

  const getCancelTitle = (msg: IMessage) => {
    if (isMineMessage(msg) && !isGroupChat()) {
      return formatMessage({ id: 'CHAT.DELETE_MESSAGE_FOR_EVERYONE' })
    }
    return 'Cancel'
  }

  const handleDeleteMessageFromGroup = (msg: IMessage) => {
    removeFromGroup(msg.id, activeChatJid).then(() => {
    })
  }

  const handleDeleteMessageFromChat = (msg: IMessage, kind: string) => {
    removeFromChat(msg.id, msg.to, kind).then((result) => {
      if (result?.status === 'ok' && !!user?.$jid) {
        dispatch(remove({ jid: activeChatJid, message: msg }))
      }
    })
  }

  const handleDeleteMessageConfirmProcess = (msg: IMessage) => {
    if (isGroupChat()) {
      handleDeleteMessageFromGroup(msg)
    } else {
      handleDeleteMessageFromChat(msg, 'for_me')
    }
    hideConfirmModal()
  }

  const handleDeleteMessageCancelProcess = (msg: IMessage) => {
    if (isMineMessage(msg) && !isGroupChat()) {
      handleDeleteMessageFromChat(msg, 'everyone')
    }
    hideConfirmModal()
  }

  const keyValLang = {
    en: 'en',
    jp: 'ja',
    kr: 'ko',
    cn: 'zh-Hans',
    tc: 'zh-Hant',
  };

  const handleTranslationMessage = (msg: ITextMessage) => {
    const newMsg = { ...msg, isTranslating: true }
    dispatch(update({
      jid: activeChatJid,
      messages: [newMsg],
    }))
    translation(keyValLang[lang], msg.text).then((result) => {
      const newMsgResp = { ...msg, textTranslate: result.text, isTranslating: false, isTranslated: true }
      dispatch(update({
        jid: activeChatJid,
        messages: [newMsgResp],
      }))
    })
  }

  const handlePin = (msg: IMessage) => {
    if (activeChat) {
      let text = ''
      if (isTextMessage(msg)) {
        text = msg.text
      } else if (isFileMessage(msg)) {
        text = msg.file.name
      }
      pin(msg.id, activeChat?.$jid, isGroupChat() ? 'groupchat' : 'chat').then(() => {
        dispatch(updateChat({
          chatJid: activeChat?.$jid, options: {
            pin: {
              uid: msg.id,
              text: text,
              type: msg.type,
              timestamp: msg.timestamp,
              visible: true,
            },
          },
        }))
      })
    }
  }

  const handleUnPin = (msg: IMessage) => {
    if (activeChat) {
      unPin(msg.id, activeChat?.$jid, isGroupChat() ? 'groupchat' : 'chat').then(() => {
        dispatch(updateChat({
          chatJid: activeChat?.$jid, options: {
            pin: null,
          },
        }))
      })
    }
  }

  const onActionForwardMessage = useCallback((msg: IMessage) => {
    if (msg) {
      setForwardedMessage(msg)
      showForwardModal()
    }
  }, [showForwardModal])

  useSubscribe(`${NAME_EVENT}/${FORWARD_EVENT}`, ({ message }) => {
    onActionForwardMessage(message)
  })

  const onClickContextMenu = (action: string, msg: IMessage) => {
    switch (action) {
      case ACTIONS.REPLY:
        dispatch(setReply({ jid: activeChatJid || '', replyMessage: msg }))
        break;
      case ACTIONS.FORWARD:
        onActionForwardMessage(msg)
        break
      case ACTIONS.TRANSLATION:
        if (isTextMessage(msg)) {
          handleTranslationMessage(msg)
        }
        break;
      case ACTIONS.DELETE:
        setConfirmProps({
          title: formatMessage({
            id: activeChat?.type === 'groupchat'
              ? 'GROUP_CHAT.MESSAGE_DELETE_ASK'
              : 'CHAT.MESSAGE_DELETE_ASK',
          }),
          onConfirm: () => {
            handleDeleteMessageConfirmProcess(msg)
          },
          onCancel: () => {
            handleDeleteMessageCancelProcess(msg)
          },
          confirmTitle: getConfirmTitle(),
          cancelTitle: getCancelTitle(msg),
        })
        showConfirmModal()
        break;
      case ACTIONS.LOAD:
        if (isFileMessage(msg)) {
          loadFileNotify({
            messageId: msg.id,
            force: true,
          })
        } else if (isAudioMessage(msg)) {
          loadAudioNotify({ messageId: msg.id })
        } else if (isVideoMessage(msg)) {
          loadVideoNotify({ messageId: msg.id })
        }
        break;
      case ACTIONS.PIN:
        handlePin(msg)
        break;
      case ACTIONS.UNPIN:
        handleUnPin(msg)
        break;
      case ACTIONS.COPY_TEXT:
        if (!isTextMessage(msg)) {
          break
        }
        copyTextToClipboard(msg.text)
        break;
      case ACTIONS.COPY_LINK:
        if (!isTextMessage(msg)) {
          break
        }
        const links = linkify.find(msg.text)
        if (!links.length) {
          return
        }
        copyTextToClipboard(links[0].href)
        break
      default:
        throw ErrorFactory.createActionNotImplemented(action)
    }
  }

  const { show: showContextMenu, ContextMenu } = useContextMenuList<ContextMenuProps>({
    Component: ContextMenuList,
    componentProps: {
      list: [],
    },
  })

  useEffect(() => {
    if (lastChatForNewMessagesCheck.current.jid !== activeChat?.$jid) {
      setFirstNewMessageJid(undefined)
      lastChatForNewMessagesCheck.current.updated = false
      return;
    }
    if (activeChat?.type === 'groupchat') {
      return;
    }
    if (safeUpdatedMessages.length < 2 ||
      lastChatForNewMessagesCheck.current.updated
    ) {
      return
    }
    lastChatForNewMessagesCheck.current.updated = true
    let lastMessage
    for (let i = safeUpdatedMessages.length; i >= 1; i--) {
      const message = safeUpdatedMessages[i - 1]
      if (!isMessage(message)) {
        continue
      }
      if (message.status !== 'displayed' && message.from !== user?.$jid) {
        lastMessage = message
        continue
      }
      break
    }
    if (lastMessage) {
      setFirstNewMessageJid(lastMessage.id)
    }
  }, [safeUpdatedMessages, activeChat, user?.$jid]);

  useEffect(() => {
    if (!newsChat) {
      setChatMessages(messages?.messages || [])
      messagesRef.current.length = messages?.messages.length || 0
    }

  }, [messages, newsChat])

  useEffect(() => {
    if (newsChat) {
      setChatMessages(newsChat.news)
      messagesRef.current.length = newsChat.news.length
    }
  }, [messages, newsChat]);

  const onContextMenu = (e: ModifiedTouchEvent, msg: IMessage) => {
    const position = {
      x: e.pageX,
      y: e.pageY,
    }
    let list = contextMenuList.list.map(item => ({
      ...item,
      text: formatMessage({ id: item.text }),
    }))
    if (isCallMessage(msg)) {
      list = list.filter(item => item.action !== ACTIONS.FORWARD)
    }
    if (isSystemMessage(msg)) {
      list = list.filter(item => item.action === ACTIONS.DELETE)
    }
    if (isTextMessage(msg)) {
      list.push({ text: formatMessage({ id: 'CHAT.COPY_MESSAGE_TEXT' }), action: ACTIONS.COPY_TEXT, icon: 'chat-copy' })
      const links = linkify.find(msg.text)
      if (links.length) {
        list.push({
          text: formatMessage({ id: 'CHAT.COPY_MESSAGE_LINK' }),
          action: ACTIONS.COPY_LINK,
          icon: 'chat-link'
        })
      }
    }
    if (['file', 'audio', 'video'].includes(msg.type)) {
      list.push({ text: formatMessage({ id: 'FILE_LOAD' }), action: ACTIONS.LOAD, icon: 'chat-download' })
    }
    if (isOwner || isAdmin || isMineMessage(msg) || (!isMineMessage(msg) && isPrivateChat())) {
      list.push({ text: formatMessage({ id: 'CHAT.DELETE_MESSAGE' }), action: ACTIONS.DELETE, icon: 'chat-remove' })
    }
    if (isTextMessage(msg) && !msg.isTranslated && !msg.isTranslating) {
      list.push(
        {
          text: formatMessage({ id: 'CHAT.TRANSLATION_MESSAGE' }),
          action: ACTIONS.TRANSLATION,
          SvgElement: TranslateImage,
        })
    }
    if (!isSystemMessage(msg) && !isCallMessage(msg) && (isPrivateChat() || (isGroupChat() && (isOwner || isAdmin)))) {
      if (activeChat?.pin?.uid === msg.id) {
        list.push({ text: formatMessage({ id: 'CHAT.UNPIN' }), action: ACTIONS.UNPIN, icon: 'chat-pin' })
      } else {
        list.push({ text: formatMessage({ id: 'CHAT.PIN' }), action: ACTIONS.PIN, icon: 'chat-pin' })
      }
    }
    const actionsDisabled = []
    if (msg.status === 'sending') {
      actionsDisabled.push(...list.map(item => item.action))
    }
    showContextMenu({
      position,
      componentProps: {
        list,
        message: msg,
        actionsDisabled,
        onClick: onClickContextMenu,
      },
    })
  }

  const onReplyClick = useCallback(async(msg: IMessage): Promise<void> => {
    if (!activeChat) {
      return
    }
    const messageIndex = safeUpdatedMessages.findIndex(message => 'id' in message && message.id === msg.id)
    const htmlMessage = ~messageIndex ? messagesRef.current[messageIndex] : null
    if (htmlMessage) {
      setDisableLoading(true)
      htmlMessage.scrollIntoView({ block: 'center', behavior: 'smooth' })
      highlightMessage(htmlMessage)
      return Promise.resolve()
    }
    try {
      const historyMessages = await getHistoryMessages({
        id: getChatId(activeChat?.$jid),
        isRoom: activeChat?.type === 'groupchat',
        limit: LOAD_AFTER_FINDING_LIMIT,
        start: msg.timestamp,
      })
      dispatch(updateWithBreak({
        messages: historyMessages.map(message => fromHistoryToMessage(message)),
        chatJid: activeChat?.$jid,
      }))
      const emptyFunc = () => {
      }
      let resolve: () => void = emptyFunc
      let reject: (reason?: string) => void = emptyFunc
      const promise: Promise<void> = new Promise((res, rej) => {
        resolve = res
        reject = rej
      })
      clearTimeout(findMessage.current?.timerId)
      const timeoutFunc = () => {
        reject('timeout')
        findMessage.current = undefined
      }
      const timerId = setTimeout(timeoutFunc, FIND_MESSAGE_TIMER)
      findMessage.current = {
        resolve,
        timerId,
        messageId: msg.id,
      }
      return promise
    } catch (e) {
      return Promise.reject(e)
    }
  }, [safeUpdatedMessages, activeChat, dispatch])

  useImperativeHandle(ref, () => {
    return { replyClick: onReplyClick }
  })

  const contextValue = useMemo(() => ({
    onReplyClick,
    disableLoading: disableLoading || isScrollingNow,
  }), [onReplyClick, disableLoading, isScrollingNow])

  const goToFindMessage = (messageElement: HTMLElement) => {
    setTimeout(() => {
      setDisableLoading(true)
      messageElement?.scrollIntoView({ block: 'center' })
      highlightMessage(messageElement)
    }, 50)
    setDisableLoading(true)
    const findingMessage = findMessage.current
    if (!findingMessage) {
      return
    }
    findingMessage?.resolve()
    clearTimeout(findingMessage.timerId)
    findMessage.current = undefined
  }

  const handleChatMessageRef = ({ element, index, id }: {
    element: HTMLElement | null,
    index: number
    id: string
  }) => {
    messagesRef.current[index] = element
    if (id === findMessage.current?.messageId && element) {
      goToFindMessage(element)
    }
  }

  useEffect(() => {
    setMembers(getOrCreateArray<IGroupChatMember>(activeChat?.members))
  }, [activeChat?.members])

  useEffect(() => {
    setIsOwner(checkOwner(user?.$jid || '', members))
    setIsAdmin(checkAdmin(user?.$jid || '', members))
  }, [members, user?.$jid])

  return <div
    ref={mainBox}
    className={classNames(styles.box, className)}
    style={{ flex: 1 }}
  >
    <ChatMessagesContext.Provider value={contextValue}>
      <Scrollbars
        ref={scrollBarRef}
        onScroll={onScroll}
        onScrollStop={onScrollStop}
        onScrollStart={onScrollStart}
      >
        <ul
          ref={messageListRef}
          className={styles.messages}>
          {safeUpdatedMessages.map((message, i) => {
            return isDateMessage(message)
              ? <DateMessage
                key={message.date.getTime()}
                TagName={'li'}
                message={message}
              />
              : isMarkMessage(message)
                ? <MarkMessage
                  key={message.id}
                  TagName="li"
                  message={message}
                />
                : isBreakMessage(message) ?
                  <BreakMessage
                    key={message.id}
                    TagName={'li'}
                    message={message} />
                  : <ChatMessage
                    ref={el => {
                      handleChatMessageRef({
                        element: el,
                        index: i,
                        id: message.id,
                      })
                    }}
                    TagName="li"
                    message={message}
                    key={message.id}
                    onContextMenu={!isNews(message) ? (e) => {
                      onContextMenu(e, message)
                    } : undefined}
                  />

          })}
        </ul>
      </Scrollbars>
    </ChatMessagesContext.Provider>
    {scrollButtonVisible && <IconButton
      className={styles.scrollBtn}
      onClick={scrollToBottomAndReadMessages}
    >
      <i className={'chat-arrow-d'} />
    </IconButton>}
    <ContextMenu />
    {visibleForwardModal && <Forward
      hide={hideForwardModal}
      onSend={handleForwardMessage}
    />}
    {visibleConfirmModal && <Confirm hide={hideConfirmModal} {...confirmProps} />}
  </div>
})

ChatMessages.displayName = 'ChatMessages'
export default ChatMessages;
