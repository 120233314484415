import request from '@/api/request';
import { IHints } from '@/store/hints/hints';
import { AxiosResponse } from 'axios';

export const saveShownHint = async(hintName: string): Promise<IHints> => {
  const { data }: AxiosResponse<IHints> = await request(
    'post',
    '/api/set-shown-hints',
    {
      name: hintName
    }
  )
  return data
}
