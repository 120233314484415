import styles from './TeamList.module.scss'
import { IClass } from '@/interfaces/general';
import classNames from 'classnames';
import VirtualList from '@/components/VirtualList/VirtualList';
import {
  ChatsDataWithDivider,
  contactListToArray,
  createChatsData,
  Divider,
  filterChats,
  filterConsultantsContacts,
  filterCustomersContacts,
  filterSponsorContacts,
  sortChats,
  sortChatsWithFavorites,
} from '@/utils/chats';
import ChatItem, {
  CHAT_ITEM_HEIGHT,
  isChat
} from '@/components/Chat/ChatItem/ChatItem';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  ListOnScrollProps,
  VariableSizeList
} from 'react-window';
import {
  useAppDispatch,
  useAppSelector
} from '@/hooks/appHook';
import {
  getTeamScroll,
  updateTeamScroll
} from '@/store/scroll/scroll';
import {
  useEffect,
  useRef,
  useState
} from 'react';
import useChatItemRendered from '@/hooks/useChatItemRendered';
import { getAllMessages } from '@/store/messages/messages';
import { getUser } from '@/store/user/user';
import {
  Chat,
  getActiveChatId,
  getAllChats
} from '@/store/chats/chats';
import { useIntl } from 'react-intl';
import Confirm from '@/components/Modal/Confirm/Confirm';
import useChatActions from '@/hooks/useChatActions';
import { ModifiedTouchEvent } from '@/components/Chat/ChatMessage/ChatMessage';
import useContextMenuList from '@/components/ContextMenu/ContextMenuList/useContextMenuList';
import ContextMenuList, { ContextMenuProps } from '@/components/ContextMenu/ContextMenuList/ContextMenuList';
import AutoDeleteMessages from '@/components/Modal/AutoDeleteMessages/AutoDeleteMessages';
import { getChatsCache } from '@/store/cache/chats';
import useActiveService from '@/hooks/useActiveService'

interface TeamListProps extends IClass {
  filter?: string
}

const TeamList = ({ className, filter }: TeamListProps) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const activeChatId = useAppSelector(getActiveChatId)
  const chats = useAppSelector(getAllChats)
  const chatsCache = useAppSelector(getChatsCache)
  const messages = useAppSelector(getAllMessages)
  const lastSavedScroll = useAppSelector(getTeamScroll)
  const [myChats, setMyChats] = useState(contactListToArray(chats).sort(sortChats(messages)))
  const [chatWithDivider, setChatWithDivider] = useState<(Chat | Divider)[]>([])
  const [consultantContacts, setConsultantContacts] = useState<Chat[]>([])
  const [customerContacts, setCustomerContacts] = useState<Chat[]>([])
  const [sponsorContacts, setSponsorContacts] = useState<Chat[]>([])
  const lastScroll = useRef(0)
  const firstOpen = useRef(true)
  const listRef = useRef<VariableSizeList>()
  const { formatMessage } = useIntl()
  const {
    confirmProps,
    visibleConfirmModal,
    hideConfirmModal,
    getList,
    autoDeleteMessagesProps,
    visibleAutoDeleteMessagesModal,
    hideAutoDeleteMessagesModal,
    onClickContextMenu,
  } = useChatActions()
  const { setActiveChatId } = useActiveService()

  const { show: showContextMenu, ContextMenu } = useContextMenuList<ContextMenuProps>({
    Component: ContextMenuList,
    componentProps: {
      list: [],
    },
  })

  useEffect(() => {
    return () => {
      dispatch(updateTeamScroll(lastScroll.current))
    }
  }, [dispatch]);

  useEffect(() => {
    const myChats = filterChats(contactListToArray(chats), filter)
      .map(chat => ({
        ...chatsCache[chat.$jid],
        ...chat
      }))
      .sort(sortChatsWithFavorites(messages))
    setMyChats(myChats)
  }, [chats, messages, filter, chatsCache]);

  useEffect(() => {
    setSponsorContacts(myChats.filter(filterSponsorContacts))
    setConsultantContacts(myChats.filter(filterConsultantsContacts))
    setCustomerContacts(myChats.filter(filterCustomersContacts))
  }, [myChats]);

  useEffect(() => {
    const chats: (Chat | Divider)[] = []
    if (sponsorContacts.length > 0) {
      chats.push(
        { name: formatMessage({ id: 'my_sponsor' }) },
        ...sponsorContacts,
      )
    }
    if (consultantContacts.length) {
      chats.push(
        { name: formatMessage({ id: 'my_consultants' }) },
        ...consultantContacts
      )
    }
    if (customerContacts.length) {
      chats.push(
        { name: formatMessage({ id: 'my_customers' }) },
        ...customerContacts
      )
    }
    listRef.current?.resetAfterIndex(0)
    setChatWithDivider([
      ...chats
    ])
  }, [sponsorContacts, formatMessage, consultantContacts, customerContacts ]);

  const { handleChatRenderWithDelay } = useChatItemRendered({ chats: chatWithDivider })

  const onScroll = (scrollProps: ListOnScrollProps) => {
    if (firstOpen.current) {
      return
    }
    lastScroll.current = scrollProps.scrollOffset
  }

  const onContextMenu = (e: ModifiedTouchEvent, chat: Chat) => {
    const position = {
      x: e.pageX,
      y: e.pageY,
    }

    const list = getList(chat)

    showContextMenu({
      position,
      componentProps: {
        list,
        message: chat,
        onClick: onClickContextMenu,
      },
    })
  }

  const onClick = (chat: string) => {
    setActiveChatId(chat)
  }

  const getItemSize = (index: number) => isChat(chatWithDivider[index]) ? CHAT_ITEM_HEIGHT : 36

  const refSetter = (list: VariableSizeList | null) => {
    if (list) {
      listRef.current = list
    }
    if (list && firstOpen.current) {
      firstOpen.current = false
      list.scrollTo(lastSavedScroll)
    }
  }

  return <div className={classNames(styles.box, className)}>
    <AutoSizer>
      {({ height, width }) => (
        <VirtualList<ChatsDataWithDivider>
          listRef={refSetter}
          height={height || 100}
          width={width || 380}
          itemSize={getItemSize}
          onScroll={onScroll}
          data={{
            chats: chatWithDivider,
            showFullInfo: true,
            activeChatId: activeChatId || '',
            activeNews: null,
            // draft: drafts,
            onClick,
            onContextMenu,
            messages,
            user: user || undefined,
          }}
          render={ChatItem}
          overscanCount={5}
          createItemData={createChatsData}
          onItemsRendered={handleChatRenderWithDelay}
        />
      )}
    </AutoSizer>
    <ContextMenu />
    {visibleConfirmModal && <Confirm hide={hideConfirmModal} {...confirmProps} />}
    {visibleAutoDeleteMessagesModal &&
      <AutoDeleteMessages hide={hideAutoDeleteMessagesModal} {...autoDeleteMessagesProps} />}
  </div>
}

export default TeamList
