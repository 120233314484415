import { IMessageStatus } from '@/store/messages/messages';
import { IChatType } from '@/store/chats/chats';

export const MessageTypes = [
  'text', 'image', 'file', 'audio', 'video', 'media', 'sticker', 'call', 'news', 'system'
] as const

export const MessageTypeSet = new Set<string>(MessageTypes)

export type MessageType = typeof MessageTypes[number]

export enum MediaType {
  audio = 5,
  video = 4,
  image = 1
}

export type ICallMessageType = 'accessed' | 'canceled' | 'missed'

export type TSystemMessageType =
  'group-created'
  | 'invited'
  | 'user-removed'
  | 'group-call-started'
  | 'group-call-ended'

type IYcSentMessage = {
  yc: {
    $xmlns: 'urn:yc:message:data',
    info: {
      $uid: string
    }
    file?: {
      $mimetype: string,
      $name: string,
      $size: number,
      $token: string,
      $url: string
    }
    media?: {
      item: {
        // $type: '1' | '2' | '3' | '4' | '5'
        $type: MediaType
        $uid: string
        $thumbnail?: string
        $url?: string
        $name?: string
      }
    }
    sticker?: {
      info: {
        $xmlns: "urn:yc:msg:s:0",
        $stickerId: string,
        $url?: string
        $ff?: string
      }
    }
    call?: {
      info: {
        $type: ICallMessageType,
        $duration: string
      }
    }
    systemInfo?: {
      $type: TSystemMessageType
      $from: string
      $to: string
      $duration?: string
    }
  }
}

type ISentMessage = {
  $from: string,
  $id: string,
  $to: string,
  $type: IChatType,
  thread?: string,
  body?: string,
  forwardedFrom?: {
    $jid: string
  }
}

export type DeleteNotifyMessage = ISentMessage & {
  delete: {
    $xmlns: string
    $uid: string
  }
}

export type RoomUserAction = 'add' | 'delete'

export type RoomUsersUpdatedNotify = ISentMessage & {
  roomUsers: {
    $action: RoomUserAction,
    user: string | string[]
  }
}

export interface ISentMessageWithReply extends ISentMessage, IYcSentMessage {
  archived?: {
    $id: string
  }
  replyMessage?: IYcSentMessage & ISentMessage & {
    $status: IMessageStatus,
    $timestamp?: number,
  }
}
