import {
  useAppDispatch,
  useAppSelector
} from '@/hooks/appHook';
import {
  getActiveChatId,
  getAllChats,
  Chat,
  isThreadsLoaded,
  isPrivateChatsLoaded,
  isMuChatsLoaded,
  getActiveChat,
} from '@/store/chats/chats';
import {
  getAllMessages,
  IMessagesItem
} from '@/store/messages/messages';
import {
  useEffect,
  useRef,
  useState
} from 'react';
import VirtualList from '@/components/VirtualList/VirtualList';
import ChatItem, {
  CHAT_ITEM_HEIGHT,
  isChat,
  isNewsChat
} from '@/components/Chat/ChatItem/ChatItem';
import AutoSizer from 'react-virtualized-auto-sizer';
import classNames from 'classnames';
import {
  createChatsData,
  ChatsDataWithDivider,
  Divider,
  filterFavoriteChats,
  filterAcceptedWithoutFavorites,
  filterAcceptedChats,
  sortChats,
  isTeamChannel,
  sortChatsCache,
  INewsChat,
  filterChatsWithNews,
  filterChatsWithoutFavorites,
} from '@/utils/chats';
import useChatItemRendered from '@/hooks/useChatItemRendered';
import { getBlockList } from '@/store/blockList/blockList';
import {
  ListOnScrollProps,
  VariableSizeList
} from 'react-window';
import { getUser } from '@/store/user/user';
import { getAllDraft } from '@/store/draft/draft';
import { ModifiedTouchEvent } from '@/components/Chat/ChatMessage/ChatMessage';
import Confirm from '@/components/Modal/Confirm/Confirm';
import useContextMenuList from '@/components/ContextMenu/ContextMenuList/useContextMenuList';
import ContextMenuList, { ContextMenuProps } from '@/components/ContextMenu/ContextMenuList/ContextMenuList';
import { getChatsCache } from '@/store/cache/chats';
import useChatActions from '@/hooks/useChatActions';
import AutoDeleteMessages from '@/components/Modal/AutoDeleteMessages/AutoDeleteMessages';
import {
  getChatScroll,
  updateChatScroll
} from '@/store/scroll/scroll';
import { getLastMessagesCache } from '@/store/cache/lastMessage';
import {
  CHAT_NAME,
  getActiveNews,
  getNews,
  getNewsChat
} from '@/store/news/news'
import { useIntl } from 'react-intl'
import useActiveService from '@/hooks/useActiveService'

interface ChatListProps {
  className?: string,
  filter?: string
}

const ChatList = ({ className, filter }: ChatListProps) => {
  const user = useAppSelector(getUser)
  const threadsLoaded = useAppSelector(isThreadsLoaded)
  const privateChatsLoaded = useAppSelector(isPrivateChatsLoaded)
  const muChatsLoaded = useAppSelector(isMuChatsLoaded)
  const chats = useAppSelector(getAllChats)
  const messages = useAppSelector(getAllMessages)
  const messagesCache = useAppSelector(getLastMessagesCache)
  const activeJid = useAppSelector(getActiveChatId)
  const activeNews = useAppSelector(getActiveNews)
  const newsChat = useAppSelector(getNewsChat)
  const activeChat = useAppSelector(getActiveChat)
  const blockedUsers = useAppSelector(getBlockList)
  const drafts = useAppSelector(getAllDraft)
  const chatsCache = useAppSelector(getChatsCache)
  const lastSavedScroll = useAppSelector(getChatScroll)
  const news = useAppSelector(getNews)
  const dispatch = useAppDispatch()
  const [chatsSorted, setChatSorted] = useState<(Chat | INewsChat)[]>([])
  const [favoriteChats, setFavoriteChats] = useState<Chat[]>([])
  const [acceptedChats, setAcceptedChats] = useState<(Chat | INewsChat)[]>([])
  const [chatWithDivider, setChatWithDivider] = useState<(Chat | INewsChat | Divider)[]>([])
  const [myChats, setMyChats] = useState<(Chat | INewsChat)[]>([])
  const lastScroll = useRef(0)
  const firstOpen = useRef(true)
  const chatListRef = useRef<VariableSizeList>()
  const isElementVisibleCb = useRef<(chat: Chat | Divider) => boolean>();
  const lastActiveChatIdForScroll = useRef<string | undefined>(activeChat?.$jid)
  const { formatMessage } = useIntl()
  const {
    confirmProps,
    visibleConfirmModal,
    hideConfirmModal,
    getList,
    onClickContextMenu,
    autoDeleteMessagesProps,
    visibleAutoDeleteMessagesModal,
    hideAutoDeleteMessagesModal,
  } = useChatActions()
  const { setActiveNews, setActiveChatId } = useActiveService()

  const { show: showContextMenu, ContextMenu } = useContextMenuList<ContextMenuProps>({
    Component: ContextMenuList,
    componentProps: {
      list: [],
    },
  })

  useEffect(() => {
    return () => {
      dispatch(updateChatScroll(lastScroll.current))
    }
  }, [dispatch]);

  useEffect(() => {
    setFavoriteChats(myChats
      .filter(isChat)
      .filter(filterFavoriteChats))
    if (filter) {
      setAcceptedChats(myChats.filter(filterChatsWithoutFavorites))
    } else {
      setAcceptedChats(myChats.filter(filterAcceptedWithoutFavorites))
    }
  }, [myChats, filter])

  useEffect(() => {
    setChatWithDivider([...favoriteChats, ...acceptedChats])
  }, [favoriteChats, acceptedChats])

  useEffect(() => {
    if (!(privateChatsLoaded && muChatsLoaded)) {
      return
    }
    const chatsSorted: (Chat | INewsChat)[] = filter
      ? Object.values(chats)
      : (Object.values(chats)
        .filter(chat => {
          return !!chat
            && (filterAcceptedChats(chat) || filterFavoriteChats(chat))
            && !isTeamChannel(chat)
        })
        .filter(chat => messages[chat.$jid]
          || messagesCache[chat.$jid]
          || filterFavoriteChats(chat)
          || chat.type === 'groupchat'))
    const sort = threadsLoaded ? sortChats(messages) : sortChatsCache(messagesCache)
    if (news.length) {
      chatsSorted.push(newsChat)
    }
    chatsSorted.sort(sort)

    setChatSorted(chatsSorted)
  }, [
    chats,
    messages,
    blockedUsers,
    threadsLoaded,
    messagesCache,
    muChatsLoaded,
    privateChatsLoaded,
    formatMessage,
    news,
    newsChat,
    filter
  ])

  useEffect(() => {
    const myChats: (Chat | INewsChat)[] = chatsSorted
      .filter(chat => chat != null && chat.name)
      .map(chat => isChat(chat)
        ? {
          ...chatsCache[chat.$jid],
          ...chat,
        }
        : chat)
    setMyChats(filterChatsWithNews(myChats, filter))
  }, [chatsSorted, chats, filter, chatsCache, news, formatMessage])

  const onClick = (chat: string) => {
    if (chat === CHAT_NAME) {
      setActiveNews(chat)
      return
    }
    setActiveChatId(chat)
  }

  const {
    handleChatRenderWithDelay,
    isElementVisible
  } = useChatItemRendered({ chats: chatWithDivider })

  isElementVisibleCb.current = isElementVisible
  useEffect(() => {
    if (!activeChat || activeChat.$jid === lastActiveChatIdForScroll.current) {
      lastActiveChatIdForScroll.current = activeChat?.$jid
      return
    }
    const index = chatWithDivider.findIndex(c => isChat(c) && c.$jid === activeChat.$jid)
    if (~index) {
      lastActiveChatIdForScroll.current = activeChat.$jid
    }
    const chatVisible = isElementVisibleCb.current?.(activeChat)
    if (!chatVisible && ~index) {
      chatListRef.current?.scrollToItem(index)
    }
  }, [chatWithDivider, activeChat]);

  const onContextMenu = (e: ModifiedTouchEvent, chat: Chat) => {
    const position = {
      x: e.pageX,
      y: e.pageY,
    }

    showContextMenu({
      position,
      componentProps: {
        list: getList(chat),
        message: chat,
        onClick: onClickContextMenu,
      },
    })
  }

  const onScroll = (scrollProps: ListOnScrollProps) => {
    if (firstOpen.current) {
      return
    }
    lastScroll.current = scrollProps.scrollOffset
  }

  const getItemSize = (index: number) => isChat(chatWithDivider[index]) || isNewsChat(chatWithDivider[index])
    ? CHAT_ITEM_HEIGHT : 36

  const refSetter = (list: VariableSizeList | null) => {
    if (!list) {
      return
    }
    chatListRef.current = list
    if (firstOpen.current) {
      firstOpen.current = false
      list.scrollTo(lastSavedScroll)
    }
  }

  return (
    <div className={classNames(className)}>
      <AutoSizer>
        {({ height, width }) => (
          <VirtualList<ChatsDataWithDivider>
            listRef={refSetter}
            height={height || 100}
            width={width || 380}
            itemSize={getItemSize}
            onScroll={onScroll}
            data={{
              chats: chatWithDivider,
              showFullInfo: true,
              activeChatId: '' + activeJid,
              activeNews,
              draft: drafts,
              onClick,
              onContextMenu,
              messages: threadsLoaded ? messages : Object.keys(messagesCache).reduce((acc, item) => {
                acc[item] = {
                  messages: [messagesCache[item]],
                  isLoaded: false,
                  isFullLoaded: false,
                  replyMessage: null
                }
                return acc
              }, {} as IMessagesItem),
              user: user || undefined,
            }}
            render={ChatItem}
            overscanCount={5}
            createItemData={createChatsData}
            onItemsRendered={handleChatRenderWithDelay}
          />
        )}
      </AutoSizer>
      <ContextMenu />
      {visibleConfirmModal && <Confirm hide={hideConfirmModal} {...confirmProps} />}
      {visibleAutoDeleteMessagesModal &&
        <AutoDeleteMessages hide={hideAutoDeleteMessagesModal} {...autoDeleteMessagesProps} />}
    </div>
  )
}

export default ChatList
