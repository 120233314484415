import {
  CSSProperties,
  memo
} from 'react';
import {
  IUsersFound,
  ISearchGroup
} from '@/api/users';
import { areEqual } from 'react-window';
import styles from './SearchedItem.module.scss'
import Avatar from '@/components/Avatar/Avatar';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

export interface SearchedData {
  users: IUsersFound[],
  groups: ISearchGroup[]
  selected: Set<string>,
  onClick?: (jid: string) => void
  onOpen?: (jid: string) => void
  contacts?: Set<string>
  teamContacts?: Set<string>
}

export interface UserItemProps {
  index: number,
  style: CSSProperties,
  data: SearchedData
}

const SearchedItem = memo(({ index, style, data }: UserItemProps) => {
  const { users, groups, selected, contacts, teamContacts, onClick, onOpen } = data
  const user = index < users.length ? users[index] : undefined
  const group = index < users.length ? undefined : groups[index - users.length]

  const isAlreadyInContacts = contacts?.has(user?.jid || group?.uid || '')
  const isInTeams = teamContacts?.has(user?.jid || group?.uid || '')

  const handleClick = () => {
    if (isAlreadyInContacts || isInTeams) {
      onOpen?.(user?.jid || group?.uid || '')
    } else {
      onClick?.(user?.jid || group?.uid || '')
    }
  }

  return <div className={styles.user} style={style} onClick={handleClick}>
    <Avatar src={user?.avatar || group?.vcard.thumbnail} name={user?.nick || group?.vcard.nickname} />
    <div className={styles.context}>
      <p className={styles.name}>
        {!!group && <i className={classNames('chat-group', styles.groupIcon)} />}
        {user?.nick || group?.vcard.nickname}
      </p>
      {isAlreadyInContacts
        ? <FormattedMessage id={
            !!user ? 'CONTACTS.IN_YOUR_CONTACT_LIST' : 'GROUP_CHAT.YOU_ARE_IN_GROUP'
          }>
            {txt => <p className={styles.description}>{txt}</p>}
          </FormattedMessage>
        : isInTeams
          ? <>
            <p className={styles.description}>
              <FormattedMessage id="CONTACTS.IN_YOUR_TEAM_LIST">
                {txt => <span>{txt}</span>}
              </FormattedMessage>
              <br />
              <FormattedMessage id="CONTACTS.YOU_CAN_FIND_IN_YOUR_TEAM_LIST">
                {txt => <span>{txt}</span>}
              </FormattedMessage>
            </p>
          </>
          : null
      }
    </div>
    {(isAlreadyInContacts || isInTeams) && <span className={classNames(styles.icon, styles.sendIcon)}>
      <i className={'chat-send'} />
    </span>}
    {selected.has(user?.jid || group?.uid || '') && <i className={classNames(styles.tick, 'chat-check')} />}
  </div>
}, areEqual)

export default SearchedItem
