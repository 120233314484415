import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  IDateMessage,
  IMarkMessage,
  isMessageOrNews,
  readMessagesInChat,
  ShowedMessage
} from '@/store/messages/messages';
import {
  getActiveNewsChat,
  INews,
  isNews
} from '@/store/news/news';
import {
  UIEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  getMessageScroll,
  updateMessageScroll
} from '@/store/scroll/scroll';
import {
  useAppDispatch,
  useAppSelector
} from '@/hooks/appHook';
import { getActiveChatId } from '@/store/chats/chats';
import styles from '@/components/Chat/ChatMessages/ChatMessages.module.scss';
import { readAllMessageInChat } from '@/api/message';
import { CustomEvents } from '@/interfaces/general';
import { getUser } from '@/store/user/user';
import { getScreenHeight } from '@/store/screenSize/screenSize';

export interface IOnScrollProps {
  up: boolean
}

interface UseMessagesScrollProps {
  messages: (ShowedMessage | IDateMessage | INews | IMarkMessage)[]
  scrollBarRef: Scrollbars | null
  mainBox: HTMLElement | null
  messageListRef: HTMLElement | null
  messagesRef: (HTMLElement | null)[]
  onScrollEvent?: (props: IOnScrollProps) => void
}

const PROTECTION_SCROLL_TIME = 1000
const SCROLL_BTN_VISIBLE_OFFSET = 40

const useMessagesScroll = (
  {
    messages,
    scrollBarRef,
    mainBox,
    messageListRef,
    messagesRef,
    onScrollEvent
  }: UseMessagesScrollProps) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const screenHeight = useAppSelector(getScreenHeight)
  const activeChatJid = useAppSelector(getActiveChatId) || ''
  const newsChat = useAppSelector(getActiveNewsChat)
  const chatMessagesScroll = useAppSelector(getMessageScroll(newsChat ? newsChat.id : activeChatJid))
  const [scrollHeight, setScrollHeight] = useState(0)
  const [scrollButtonVisible, setScrollButtonVisible] = useState(false)
  const [isScrollingNow, setIsScrollingNow] = useState(false)
  const savedScrollChat = useRef<string>();
  const scrollBottom = useRef(0)
  const chatOpenedNotFirst = useRef<{ [key: string]: boolean }>({})
  const isFirstScrolling = useRef(false);
  const cbMessageListResize = useRef<() => void>();
  const scrollBottomAfterAddRef = useRef(false)
  const scrollTimerProtection = useRef<NodeJS.Timer>()
  const onScrollStopRef = useRef<() => void>()
  const messageListSizeObserver = useRef<ResizeObserver>()

  if (!messageListSizeObserver.current) {
    messageListSizeObserver.current = new ResizeObserver(() => {
      cbMessageListResize.current?.()
    })
  }

  // useEffect не успевает за изменением scrollBottom,
  // поэтому вынес в такое условие
  const currentChatJid = newsChat ? newsChat.id : activeChatJid
  if (savedScrollChat.current !== currentChatJid) {
    if (savedScrollChat.current != null) {
      dispatch(updateMessageScroll({
        jid: savedScrollChat.current,
        value: scrollBottom.current
      }))
      chatOpenedNotFirst.current[savedScrollChat.current] = true
    }
    savedScrollChat.current = currentChatJid
    if (chatOpenedNotFirst.current[currentChatJid] == null && chatMessagesScroll != null) {
      chatOpenedNotFirst.current[currentChatJid] = true
    }
    if (chatMessagesScroll != null) {
      scrollBottom.current = chatMessagesScroll
      if (chatOpenedNotFirst.current[currentChatJid] == null) {
        chatOpenedNotFirst.current[currentChatJid] = true
      }
    }
    if (!chatOpenedNotFirst.current[currentChatJid]) {
      isFirstScrolling.current = true
    }
  }

  const getScrollBottom = useCallback(() => {
    const container = scrollBarRef
    if (container) {
      return container.getScrollHeight() - container.getClientHeight() - container.getScrollTop()
    }
    return 0
  }, [scrollBarRef])

  const scrollToBottom = useCallback(() => {
    const container = scrollBarRef?.container.querySelector('.' + styles.messages)
    const parent = container?.parentElement
    if (parent) {
      const offset = parent.scrollTop + parent.clientHeight
      if (offset === container.scrollHeight) {
        return
      }
      parent.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth',
      })
      setIsScrollingNow(true)
      clearTimeout(scrollTimerProtection.current)
      scrollTimerProtection.current = setTimeout(() => {
        onScrollStopRef.current?.()
      }, PROTECTION_SCROLL_TIME)
    }
  }, [scrollBarRef])

  cbMessageListResize.current = useCallback(() => {
    const container = scrollBarRef
    setScrollHeight(container?.getScrollHeight() || 0)
    if (isFirstScrolling.current) {
      return
    }
    const bottom = getScrollBottom()
    if (scrollBottomAfterAddRef.current) {
      scrollBottomAfterAddRef.current = false
      scrollToBottom()
    } else if (bottom !== scrollBottom.current
      && container
    ) {
      const offset = container.getScrollHeight() - container.getClientHeight() - scrollBottom.current
      container.scrollTop(offset)
    }
    if (!container || container.getScrollHeight() <= container.getClientHeight()) {
      setScrollButtonVisible(false)
    }
  }, [scrollToBottom, getScrollBottom, scrollBarRef])

  const scrollToFirstUnreadMessage =
    useRef<(messages: (ShowedMessage | INews | IDateMessage | IMarkMessage)[]) => void>()
  scrollToFirstUnreadMessage.current =
    useCallback((messages: (ShowedMessage | INews | IDateMessage | IMarkMessage)[]) => {
      if (!isFirstScrolling.current || !scrollHeight || !mainBox?.clientHeight) {
        return;
      }
      if (messages.length < 2 || (scrollHeight <= mainBox.clientHeight)) {
        return;
      }
      const jid = newsChat ? newsChat.id : activeChatJid
      if (chatOpenedNotFirst.current[jid]) {
        return
      }
      const firstUnreadMessageIndex = messages
        .findIndex(message => isMessageOrNews(message) && (isNews(message)
          ? !message.viewed
          : message.from !== user?.$jid && message.status !== 'displayed'))
      const htmlMessage = ~firstUnreadMessageIndex ?
        messagesRef[firstUnreadMessageIndex] : null
      if (~firstUnreadMessageIndex && !htmlMessage) {
        return;
      }
      if (htmlMessage) {
        htmlMessage?.scrollIntoView({ block: 'center' })
      } else {
        if (!messageListRef) {
          return;
        }
        messageListRef?.scrollIntoView({ block: 'end' })
        scrollBottom.current = 0
      }
    }, [user?.$jid, newsChat, activeChatJid, scrollHeight, mainBox, messagesRef, messageListRef])

  const checkScrollBtnVisible = useCallback(() => {
    if (scrollBottom.current > SCROLL_BTN_VISIBLE_OFFSET) {
      setScrollButtonVisible(true)
    } else {
      setScrollButtonVisible(false)
    }
  }, []);

  useEffect(() => {
    const container = messageListRef
    const observer = messageListSizeObserver.current
    if (!container || !observer) {
      return
    }
    observer.observe(container)

    return () => {
      observer.disconnect()
    }
  }, [messageListRef])

  useEffect(() => {
    const jid = newsChat ? newsChat.id : activeChatJid
    if (chatOpenedNotFirst.current[jid] || messages.length < 2) {
      return
    }
    scrollToFirstUnreadMessage.current?.(messages)
  }, [messages, activeChatJid, newsChat, scrollHeight])

  useEffect(() => {
    if (chatMessagesScroll == null) {
      return
    }
    scrollBottom.current = chatMessagesScroll
    const container = scrollBarRef
    if (container) {
      container.scrollTop(container.getScrollHeight() - container.getClientHeight() - chatMessagesScroll)
    }
  }, [chatMessagesScroll, scrollBarRef])

  useEffect(() => {
    const container = scrollBarRef
    container?.scrollTop(container.getScrollHeight() - container.getClientHeight() - scrollBottom.current)
  }, [screenHeight, scrollBarRef])

  useEffect(() => {
    return () => {
      if (savedScrollChat.current != null) {
        dispatch(updateMessageScroll({
          jid: savedScrollChat.current,
          value: scrollBottom.current
        }))
      }
    }
  }, [dispatch]);

  useEffect(() => {
    cbMessageListResize.current?.()
  }, [messages]);

  const scrollBottomAfterAdd = useCallback(() => {
    scrollBottomAfterAddRef.current = true
  }, []);

  useEffect(() => {
    window.addEventListener(CustomEvents.MESSAGE_SENT_TO_CURRENT_CHAT, scrollBottomAfterAdd)

    return () => {
      window.removeEventListener(CustomEvents.MESSAGE_SENT_TO_CURRENT_CHAT, scrollBottomAfterAdd)
    }
  }, [scrollBottomAfterAdd])

  const onScrollStart = () => {
    if (currentChatJid) {
      chatOpenedNotFirst.current[currentChatJid] = true
    }
    clearTimeout(scrollTimerProtection.current)
    setIsScrollingNow(true)
  }

  onScrollStopRef.current = useCallback(() => {
    if (isFirstScrolling.current) {
      scrollBottom.current = getScrollBottom()
      isFirstScrolling.current = false
      checkScrollBtnVisible()
      if (chatMessagesScroll == null && savedScrollChat.current) {
        dispatch(updateMessageScroll({
          jid: savedScrollChat.current,
          value: scrollBottom.current
        }))
      }
    }
    setIsScrollingNow(false)
  }, [dispatch, chatMessagesScroll, getScrollBottom, checkScrollBtnVisible])

  const onScroll = useCallback((e: UIEvent) => {
    const oldScrollBottom = scrollBottom.current
    if (scrollBarRef
      && Math.abs(scrollHeight - scrollBarRef.getScrollHeight()) < 1
    ) {
      scrollBottom.current = getScrollBottom()
    }

    checkScrollBtnVisible()

    onScrollEvent?.({
      up: scrollBottom.current > oldScrollBottom
    })
  }, [checkScrollBtnVisible, getScrollBottom, scrollBarRef, onScrollEvent, scrollHeight])

  const scrollToBottomAndReadMessages = useCallback(() => {
    dispatch(readMessagesInChat(activeChatJid))
    readAllMessageInChat(activeChatJid)
    scrollToBottom()
  }, [dispatch, activeChatJid, scrollToBottom])

  return {
    onScroll,
    onScrollStart,
    onScrollStop: onScrollStopRef.current,
    scrollButtonVisible,
    scrollToBottomAndReadMessages,
    isScrollingNow
  }
}

export default useMessagesScroll
